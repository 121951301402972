import SearchFilter from "@/components/orders/IdFilter/SearchFilter";
import { PurchaseList } from "@/components/purchases/PurchaseList/PurchaseList";
import { PurchaseListActions } from "@/components/purchases/PurchaseList/PurchaseListActions/PurchaseListActions";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { FEAT_SELL_IN } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const AcquisitionsListPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");

  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("ACQUISITIONS.LIST_TITLE"), "", "", <SearchFilter />);
  }, [t]);

  if (!hasFeature(FEAT_SELL_IN)) {
    return <LockedFeaturePlaceholder />;
  }

  return (
    <>
      <PurchaseListActions type={"SELL_IN"} />
      <PurchaseList type={"SELL_IN"} />
    </>
  );
};
