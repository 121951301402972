import { getInstance } from "../../core/axios-gateway";

const BASE_PATH = "/api/suppliers/splits";

export const getSupplierSplits = async () => {
  return getInstance()
    .get<any[]>(BASE_PATH)
    .then(res => res.data);
};

export const createSupplierSplit = async (data: any) => {
  return getInstance()
    .post<any>(BASE_PATH, data)
    .then(res => res.data);
};

export const updateSupplierSplit = async (id: string, data: any) => {
  return getInstance()
    .put<any>(`${BASE_PATH}/${id}`, data)
    .then(res => res.data);
};

export const deleteSupplierSplit = async (id: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${id}`)
    .then(res => res.data);
};
