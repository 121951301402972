import { useAuth } from "@/store/auth";
import { IntegrationCard } from "./Card";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { createOauthCredentials } from "@/http";
import { useEffect } from "react";

export default function IntegrationsCallbackPage() {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const { id: integrationId } = useParams();

  const queryParams: any = {};

  searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  useEffect(() => {
    if (integrationId) {
      createOauthCredentials({
        integrationId,
        supplierId: user.id,
        credentials: queryParams,
      }).finally(() => window.close());
    }
  }, [integrationId]);

  return (
    <IntegrationCard>
      <Spinner />
    </IntegrationCard>
  );
}
