import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteSellerBillingAddress, TSellerBillingAddress } from "@/http";
import clsx from "clsx";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import DeleteActionButton from "./ActionButtons/DeleteActionButton";

type TDeleteBillingAddress = {
  onDone?: () => void;
  clientId: string;
  address: TSellerBillingAddress;
};
export const DeleteBillingAddress: FC<TDeleteBillingAddress> = ({
  onDone,
  address,
  clientId,
}) => {
  const [t] = useTranslation("clients-page");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = async () => {
    setIsLoading(true);
    try {
      if (address?.id) {
        await deleteSellerBillingAddress(clientId, address.id);
      }
      setIsLoading(false);
      showToastSuccess(t("BILLING_ADDRESS_DELETED"));
      onDone?.();
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  return (
    <>
      <DeleteActionButton iconButton onAction={() => setOpen(true)} />
      <Modal isOpen={open} onClose={() => setOpen(false)} size="sm">
        <div className="leftAlign">
          <h6 className="bold">{t("DELETE_BILLING_ADDRESS")}</h6>
          <div className="divider" />
          <div className="row">
            <p>{t("ARE_YOU_SURE_DELETE_BILLING_ADDRESS")}</p>
            {`${address?.addressLine1 ?? ""} ${address?.addressLine2 ?? ""} - ${
              address?.city ?? ""
            }, ${address?.state ?? ""}, ${address?.country ?? ""}`}
          </div>
          <div className="divider" />
          <div className="row rigthAlign">
            <button
              className={clsx("primary", styles.modalTriggerButton)}
              disabled={isLoading}
              onClick={onDelete}
            >
              {t("DELETE")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
