import { TSeller } from "@/http";
import { PurchaseListTable } from "../purchases/PurchaseList/PurchaseListTable/PurchaseListTable";
import { useRecordContext } from "./ui/RecordContext";

export const ClientPurchasesTable = () => {
  const client = useRecordContext<TSeller>();

  return (
    <>
      <PurchaseListTable type="SELL_OUT" sellerId={client.id} />
    </>
  );
};
