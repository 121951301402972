import { getCurrentCountry, isCO } from "@/constants/core";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createPurchase, getSupplierProductOptionById, TProduct } from "@/http";
import { useAuth } from "@/store/auth";
import { useProducts } from "@/store/products";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeliveryInfoSale } from "./DeliveryInfo/DeliveryInfoSale";
import { SaleResume } from "./SaleResume/SaleResume";
import styles from "./styles.module.scss";
import { DisabledZone } from "@/components/shared/DisabledZone/DisabledZone";
import { WarehouseInfo } from "./WarehouseInfo/WarehouseInfo";
import {
  mapSaleCreationError,
  mapSaleProducts,
  validateStockAvailability,
} from "@/utils/sale";
import { get } from "lodash-es";
import { ProductSearch } from "@/components/shared/ProductSearch/ProductSearch";
import { SaleProductsExcel } from "./SaleProductsExcel/SaleProductsExcel";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { ProductsMethod } from "./types";
import ProductsDetailsV2 from "@/components/order-details/ProductsDetailsV2/ProductsDetailsV2";

export const SaleCreation = ({
  type = "SELL_OUT",
  disabled = false,
}: {
  type?: string;
  disabled?: boolean;
}) => {
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const { resetSelectedProducts } = useProducts();
  const [prepurchase, setPrepurchase] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const { user, commercial } = useAuth();
  const navigate = useNavigate();

  const defaultValues: any = {
    hasStock: "true",
    flagReload: false,
    sellerSearchType: "firstName",
    supplierId: user.id,
    useSameDirection: true,
    purchaseType: type,
    warehouseId: commercial?.warehouseId ?? "",
    productsMethod: "classic",
  };

  const methods = useForm({ defaultValues, mode: "onChange" });

  const deletePurchaseProduct = (product: TProduct) => {
    methods.setValue(
      "products",
      methods.watch("products").filter((p: TProduct) => p.idx !== product.idx)
    );
  };

  const rebuildProducts = (
    products: TProduct[],
    editProduct: TProduct
  ): TProduct[] => {
    return products.map((p: TProduct) => {
      if (p.idx === editProduct.idx) {
        return { ...p, ...editProduct };
      }
      return p;
    });
  };

  const editPurchaseProduct = (product: TProduct) => {
    const currentProduct = methods.watch("editProduct");
    methods.setValue("editProduct", product);
    if (currentProduct) {
      methods.setValue(
        "products",
        rebuildProducts(methods.watch("products"), currentProduct)
      );
    }
  };

  const isQuotation = type === "QUOTATION";
  const productsMethod: ProductsMethod = methods.watch("productsMethod");

  const handleProductSelection = async (product: TProduct) => {
    if (
      product?.type === "GROUP" &&
      type === "QUOTATION" &&
      product?.optionId
    ) {
      const options = await getSupplierProductOptionById(product.optionId);
      product.curves = (options?.curves || []).map((c: any) => ({
        ...c,
        quantity: 0,
        selected: false,
      }));
      product.curveOptions = options?.options ?? [];
    }

    product.unitRate =
      (get(product, "unitTypes", []) as any[])?.find(
        (unit: any) => unit?.isDefault
      )?.rate ?? 1;

    product.wholesalePrice = Number(
      (product?.unitRate || 1) * product.wholesalePrice
    );
    product.quantity = 1;
    product.discountPercentage = 0;
    product.idx = methods.watch("products")?.length || 0;

    product.warehouseId = product.availableWarehouses.filter(
      w => !commercial?.warehouseId || w.warehouseId == commercial?.warehouseId
    )[0].warehouseId;

    methods.setValue("products", [
      ...(methods.watch("products") || []),
      product,
    ]);
    editPurchaseProduct(product);
  };

  const handleProductMethodChange = (e: any) => {
    const isExcelMethod = e.target.checked;
    methods.setValue("productsMethod", isExcelMethod ? "excel" : "classic", {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        setLoading(true);
        const isSale = values.purchaseType === "SELL_OUT";

        const baseProducts = values?.editProduct
          ? rebuildProducts(values?.products, values?.editProduct)
          : values?.products;

        const productsMapped = mapSaleProducts(baseProducts ?? [], {
          seller: values?.seller,
          productsMethod,
        });
        const productStockAvailable: Record<string, number> = {};
        baseProducts.forEach((p: TProduct) => {
          if (p.warehouseId) {
            productStockAvailable[p.id] =
              p.availableWarehouses.find(w => w.warehouseId === p.warehouseId)
                ?.availableStock || 0;
          } else {
            productStockAvailable[p.id] =
              p.availableWarehouses[0]?.availableStock || 0;
          }
        });
        const products = isSale
          ? validateStockAvailability(productsMapped, productStockAvailable)
          : productsMapped;

        if (products?.length !== baseProducts.length) {
          showToastError({ message: t("ADD_PRODUCTS") });
          return;
        }

        const payload = {
          ...values,
          products,
          sellerId: values?.seller?.id,
          supplierId: user.id,
          isForSeller: true,
          clientTarget: "own",
          userType: "SELLER",
          countryCode: getCurrentCountry(),
          shipping: values?.shippingPrice,
          voucherUrl: values?.voucherUrl,
          couponCode: values?.coupon,
          type: "SELL_OUT",
        };

        let totalPaid = 0;
        for (let i = 0; i < (values?.payments ?? []).length; i++) {
          totalPaid += Number(get(values.payments[i], "amount", 0));
        }

        payload.paymentStatus =
          totalPaid ===
          Number(prepurchase.total || 0) - Number(values?.coins || 0)
            ? "PAID"
            : "PENDING";
        payload.deliveryStatus =
          values.isDelivered === true ? "DELIVERED" : null;

        if (isCO) {
          payload.useSameDirection = true;
        }

        if (!isSale) {
          payload.isQuotation = true;
        }

        const purchase = await createPurchase(payload);
        resetSelectedProducts();
        showToastSuccess(t(isSale ? "PURCHASE_CREATED" : "QUOTE_CREATED"));
        navigate(`/${isSale ? "purchases" : "quotes"}/${purchase.id}/show`);
      } catch (error: any) {
        mapSaleCreationError(error, navigate, type, tGlobal);
      } finally {
        setLoading(false);
      }
    },
    [
      t,
      tGlobal,
      navigate,
      resetSelectedProducts,
      user.id,
      type,
      prepurchase,
      productsMethod,
    ]
  );

  useEffect(() => {
    methods.setValue("products", [], { shouldDirty: false });
  }, [productsMethod, methods]);

  return (
    <DisabledZone when={disabled || isLoading}>
      <FormProvider {...methods}>
        <DeliveryInfoSale isQuote={isQuotation} />
        <div className="spacer" />

        {user?.showCurves && (
          <div className={styles.productsMethodContainer}>
            <h3>{t("PRODUCT_INPUT_METHOD")}</h3>
            <Checkbox
              name="useExcelMethod"
              label={t("UPLOAD_EXCEL_FILE")}
              onChange={handleProductMethodChange}
              defaultChecked={productsMethod === "excel"}
            />
            <div className={styles.methodDescription}>
              {t("EXCEL_METHOD_DESCRIPTION")}
            </div>
          </div>
        )}

        {productsMethod === "classic" && (
          <>
            <ProductsDetailsV2
              allowEdit={true}
              orderType={type}
              currentProduct={methods.watch("editProduct")}
              isCreation
              products={methods.watch("products") || []}
              onEditProduct={(product: TProduct) =>
                editPurchaseProduct(product)
              }
              onDeleteProduct={(product: TProduct) =>
                deletePurchaseProduct(product)
              }
            />
            <div className="spacer" />
            <ProductSearch
              filterConditions={{
                segment: get(methods.watch("seller"), "segments[0].name", ""),
                supplierId: user.id,
                hasStock: !isQuotation ? true : undefined,
                status: !isQuotation ? true : undefined,
                warehouseId: commercial?.warehouseId || undefined,
              }}
              onSelect={handleProductSelection}
              infoLabel={
                methods.watch("products")?.length >= 50 && (
                  <p className="purpleText bold mdFont pw2">
                    * {tGlobal("INFO_MAX_PRODUCTS_ADDED")}
                  </p>
                )
              }
            />
          </>
        )}

        {productsMethod !== "classic" && <SaleProductsExcel />}

        <div className="spacer" />
        <div className="flexGap flexWrap flex1 flexSmColumn">
          <WarehouseInfo type={type} prepurchase={prepurchase} />
          {productsMethod !== "excel" && (
            <SaleResume
              setPrepurchase={setPrepurchase}
              prepurchase={prepurchase}
              setLoading={setLoading}
            />
          )}
        </div>
        {createPortal(
          <div className={styles.footer}>
            <div className="spacerVertical" />
            <button
              className="outlined md"
              onClick={() => navigate(-1)}
              disabled={isLoading}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary md"
              data-testid="createOrderButton"
              onClick={async () => {
                const isValid = await methods.trigger();
                if (isValid) {
                  onSubmit(methods.watch());
                }
              }}
              disabled={isLoading || disabled}
            >
              {isQuotation ? t("CREATE_QUOTE") : t("CREATE")}
            </button>
          </div>,
          document.body
        )}
      </FormProvider>
    </DisabledZone>
  );
};
