import { getProductById } from "@/http";
import { useHeaderTitle } from "@/store/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useProducts } from "@/store/products";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { ProductCreationForm } from "@/components/products/CreationV2/ProductCreationForm";
import { FormProvider, useForm } from "react-hook-form";
import { get } from "lodash-es";
import { formatDateToInput } from "@/utils/date";

export const EditProductPage = () => {
  const { setTitle } = useHeaderTitle();
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const { id } = useParams();
  const { setSelectedVariant, selectedVariantEdit } = useProducts();
  const [isLoading, setLoading] = useState(false);
  const methods = useForm({
    defaultValues: {
      images: [],
    } as any,
  });

  const getProduct = async (id: string) => {
    setLoading(true);
    setTitle("-", "/products");
    const product = await getProductById(id);
    setSelectedVariant(product);
    setTitle(`${t("PRODUCTS.EDIT_TITLE")} » ${product?.name}`, "/products");
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getProduct(id);
    }
  }, [id]);

  useEffect(() => {
    if (selectedVariantEdit) {
      methods.reset({
        ...selectedVariantEdit,
        images: selectedVariantEdit?.media,
        brandName: selectedVariantEdit?.brand?.name,
        useSecurityStock: !!selectedVariantEdit?.securityStock,
        chargeTaxes: !!get(selectedVariantEdit, "taxesType[0]"),
        taxesType: get(selectedVariantEdit, "taxesType[0]", ""),
        departureStock: selectedVariantEdit?.departureStock || 0,
        weight: selectedVariantEdit?.weight || 0,
        overStock: selectedVariantEdit?.availableWarehouses?.reduce(
          (acc: any, item: any) => {
            acc[item.id] = {
              overStock: !!item?.overStock,
              overStockAmount: item?.overStockAmount ?? 1000000,
            };
            return acc;
          },
          {}
        ),
        imagesPriority: selectedVariantEdit?.media?.map((i: any) => i.priority),
        categoryId: selectedVariantEdit?.category?.id,
        categoryName: selectedVariantEdit?.category?.name,
        retailIntroductionDate: selectedVariantEdit?.retailIntroductionDate
          ? formatDateToInput(selectedVariantEdit?.retailIntroductionDate)
          : "",
      });
    } else {
      methods.reset();
    }
  }, [selectedVariantEdit]);

  if (isLoading || Object.keys(selectedVariantEdit ?? {}).length <= 0) {
    return <Spinner />;
  }

  if (selectedVariantEdit.type === "VARIANT") {
    navigate(`/products/${selectedVariantEdit.id}`);
  }

  return (
    <FormProvider {...methods}>
      <ProductCreationForm productId={selectedVariantEdit.id} />
    </FormProvider>
  );
};
