import ProductImage from "@/components/layout/ProductImage";
import { Card } from "@/components/shared/Card/Card";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { TUnitType } from "@/http";
import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { getProductSupplierFromProduct } from "@/utils/products";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineWarningAmber } from "react-icons/md";
import { ProductSearch } from "../ProductSearch/ProductSearch";
import { PurchaseOrderUnitRates } from "./PurchaseOrderUnitRates";
import styles from "./styles.module.scss";

export const PurchaseOrderProducts = () => {
  const { watch } = useFormContext();
  const products = watch("products");
  const supplierIdWatch = watch("supplierId");
  const [t] = useTranslation("global");
  const [showUnitType, setShowUnitType] = useState(false);

  useEffect(() => {
    if (products?.length > 0) {
      setShowUnitType(
        (products ?? [])?.some((p: any) => p?.unitTypes?.length > 1)
      );
    } else {
      setShowUnitType(false);
    }
  }, [products]);

  return (
    <Card className="noPadding">
      <div className="tableScroll">
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.row} bold`}>
              <td></td>
              <td></td>
              <td>{t("PURCHASES.ORDERS.PRODUCT")}</td>
              <td>{t("PURCHASES.ORDERS.PRICE")}</td>
              {showUnitType && <td>{t("PURCHASES.ORDERS.UNIT_TYPE")}</td>}
              <td>{t("PURCHASES.ORDERS.QUANTITY")}</td>
              <td>{t("PURCHASES.ORDERS.ACTIONS")}</td>
            </tr>
          </thead>
          <tbody>
            {(products ?? []).map((p: string, idx: number) => (
              <PurchaseOrderRow
                product={p}
                idx={idx}
                key={idx}
                showUnitType={showUnitType}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="rigthAlign">
        <PurchaseOrderUnitRates products={products ?? []} />
      </div>
    </Card>
  );
};

const PurchaseOrderRow = ({
  product,
  idx,
  showUnitType,
}: {
  product: any;
  idx: number;
  showUnitType: boolean;
}) => {
  const [productSupplier, setProductSupplier] = useState<any>();
  const { watch, setValue, register } = useFormContext();
  const products = watch("products");
  const supplierIdWatch = watch("supplierId");
  const unitRateWatch = useWatch({ name: `products[${idx}].unitRate` });
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const useParentSupplier =
    (watch("parentSupplier") ?? "") === "parentSupplier";

  const onDelete = (idx: number) => {
    const productsFiltered = (products ?? []).filter(
      (p: string, i: number) => idx !== i
    );
    setValue("products", productsFiltered);
  };

  useEffect(() => {
    if (product && supplierIdWatch) {
      const ps = getProductSupplierFromProduct(
        product,
        useParentSupplier ? user.id : supplierIdWatch
      );
      setProductSupplier(ps);
      setValue(`products[${idx}].quantity`, product?.quantity);
      setValue(`products[${idx}].wholesalePrice`, product?.wholesalePrice);
      setValue(`products[${idx}].productSupplier`, ps);
    } else {
      setProductSupplier({});
      setValue(`products[${idx}].productSupplier`, {});
      setValue(`products[${idx}].quantity`, product?.quantity);
      setValue(`products[${idx}].wholesalePrice`, product?.wholesalePrice);
    }
  }, [product, supplierIdWatch, useParentSupplier]);

  useEffect(() => {
    if (unitRateWatch) {
      const unit = (product.unitTypes ?? []).find(
        (u: TUnitType) => Number(u?.rate) === Number(unitRateWatch)
      );
      setValue(`products[${idx}].unitType`, unit?.name);
    }
  }, [unitRateWatch]);

  return (
    <tr
      className={clsx(styles.row, {
        [styles.warningProduct]:
          supplierIdWatch &&
          (!productSupplier?.id ||
            !productSupplier?.status ||
            productSupplier?.availableStock <= 0),
      })}
    >
      <td className="orangeText">
        {supplierIdWatch &&
          (!productSupplier?.id ||
            !productSupplier?.status ||
            productSupplier?.availableStock <= 0) &&
          !useParentSupplier && (
            <ToolTip
              title={t("PURCHASES.ORDERS.WARNING_PRODUCT")}
              position="Right"
            >
              <MdOutlineWarningAmber />
            </ToolTip>
          )}
      </td>
      <td>
        <ProductImage images={product?.images ?? []} />
      </td>
      <td>{product?.name}</td>
      {!supplierIdWatch ? (
        <>
          <td>--</td>
          <td>--</td>
          <td>--</td>
        </>
      ) : (productSupplier?.status && productSupplier?.availableStock > 0) ||
        useParentSupplier ? (
        <>
          <td className={useParentSupplier ? styles.inputContainer : ""}>
            {useParentSupplier ? (
              <>
                <NumericInput
                  name={`products[${idx}].wholesalePrice`}
                  label=""
                  required
                  step={0.1}
                  decimals
                  min={0.1}
                />
              </>
            ) : (
              formatCurrency(productSupplier?.wholesalePrice ?? 0)
            )}
          </td>
          {showUnitType ? (
            <td className={styles.inputContainer}>
              {product?.unitTypes?.length > 0 ? (
                <SelectV2
                  name={`products[${idx}].unitRate`}
                  label=""
                  required
                  choices={(product?.unitTypes ?? [])
                    .sort((a: any, b: any) => {
                      if (a?.isDefault === b?.isDefault) {
                        return 0;
                      }
                      return a?.isDefault ? -1 : 1;
                    })
                    .map((u: TUnitType) => ({
                      value: u?.rate,
                      label: u?.name,
                    }))}
                />
              ) : (
                <>
                  N/A
                  <input
                    type="hidden"
                    value={1}
                    {...register(`products[${idx}].unitRate`)}
                  />
                </>
              )}
            </td>
          ) : (
            <input
              type="hidden"
              value={1}
              {...register(`products[${idx}].unitRate`)}
            />
          )}
          <td className={styles.inputContainer}>
            <NumericInput
              name={`products[${idx}].quantity`}
              label=""
              required
              min={product?.partialUnitsAllowed ? 0.1 : 1}
              max={useParentSupplier ? null : productSupplier.availableStock}
              infoLabel={
                useParentSupplier
                  ? undefined
                  : `${t("PURCHASES.ORDERS.STOCK_AVAILABLE")}: ${
                      productSupplier.availableStock
                    }`
              }
            />
          </td>
        </>
      ) : (
        <td colSpan={2} className="orangeText">
          {t("PURCHASES.ORDERS.NO_SUPPLIER_PRODUCT")}
        </td>
      )}
      <td>
        <AiOutlineDelete
          className="pointer mt1"
          onClick={() => onDelete(idx)}
        />
      </td>
    </tr>
  );
};
