import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { useEffect, useRef, useState, useCallback, ReactNode } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import {
  getSegments,
  getSellers,
  getSupplierCommercialById,
  getSupplierCommercials,
  TSegment,
} from "@/http";
import { useAuth } from "@/store/auth";
import useSWR from "swr";
import { FormProvider, useForm } from "react-hook-form";
import { ClientModal } from "@/components/clients/ClientModal";
import { onboardingSelectors } from "@/components/onboarding/selectors";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import styles from "./styles.module.scss";
import { isUSA } from "@/constants/core";
import { ClientDelete } from "@/components/clients/ClientDelete/ClientDelete";
import Select from "react-select";
import debounce from "lodash/debounce";
import { singleSelectStyles } from "@/constants/input";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { AiOutlineCalendar } from "react-icons/ai";
import { hasPermission, isAdmin } from "@/utils/validations/permissions";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import SearchFilter from "@/components/orders/IdFilter/SearchFilter";

export const buildSearch = (
  search: string,
  param: string,
  paramSearch: string | undefined | null
) => {
  let searchString = search;
  if (paramSearch) {
    searchString = search
      ? `${search},${param}${paramSearch}`
      : `${param}${paramSearch}`;
  }
  return searchString;
};

export const CalendarLink = () => {
  const [tGlobal] = useTranslation("global");
  return (
    <>
      <Link to={"/clients/events"} className="flexGap center bold mr1 mdFont">
        <AiOutlineCalendar />
        <span className="textUnderline">{tGlobal("GO_TO_EVENTS")}</span>
      </Link>
    </>
  );
};

const ClientList = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("clients-page");
  const [tGlobal] = useTranslation("global");
  const navigate = useNavigate();

  useEffect(() => {
    setTitle(
      t("CLIENTS.TITLE"),
      undefined,
      undefined,
      <>
        <SearchFilter />
        <CalendarLink />
      </>
    );
  }, [t]);

  const { isCommercial, commercial, role } = useAuth();
  const methods = useForm();
  const [search, setSearch] = useSearchParams("page=0");
  const debounceRef = useRef<Record<string, any>>({});
  const page = +search.get("page")!;
  const stage = search.get("stage");
  const text = search.get("filter");
  const commercialId = search.get("commercialId");
  const segment = search.get("segment");
  const { data: segments = [] } = useSWR("segments", getSegments);
  const { data: commercialFilter, isValidating } = useSWR(
    commercialId ? [commercialId, "commercialId"] : null,
    getSupplierCommercialById
  );

  const swrData = useSWR(
    {
      key: `clients${page}${text}${stage}${segment}`,
      page,
      text,
      commercialId,
      stage,
      segment,
    },
    ({ page }) => {
      let search = buildSearch(
        "",
        `commercialId:`,
        isCommercial &&
          !hasPermission(isCommercial, role, ["action.clients.show-all"])
          ? commercial?.id
          : commercialId
      );
      search = buildSearch(search, "text:", text);
      search = buildSearch(search, "stage:", stage);
      search = buildSearch(search, "segments.id:", segment);

      return getSellers({ size: 8, page, search });
    }
  );

  const { data: list, error, mutate } = swrData;

  const onFilterChange = (property: string, value: string) => {
    if (property === "commercialId" && value === commercialId) {
      return;
    }
    const newSearch = new URLSearchParams(search);
    newSearch.set(property, value);
    if (debounceRef.current.sellerName) {
      clearTimeout(debounceRef.current.sellerName);
    }

    debounceRef.current.sellerName = setTimeout(() => {
      newSearch.set("page", "0");
      setSearch(newSearch);
    }, 250);
  };

  useEffect(() => {
    mutate();
  }, [isCommercial]);

  useEffect(() => {
    if (methods.watch("text") !== text) {
      methods.setValue("text", text);
    }
  }, [text]);

  useEffect(() => {
    if (methods.watch("stage") !== stage) {
      methods.setValue("stage", stage);
    }
  }, [stage]);

  useEffect(() => {
    if (methods.watch("segment") !== segment) {
      methods.setValue("segment", segment);
    }
  }, [segment]);

  return (
    <>
      <div>
        <div
          data-onboarding={onboardingSelectors.clientsActions}
          className="flexGap flexWrap rigthAlignFlex mt1"
        >
          <div>
            <ClientModal onDone={() => mutate()} />
          </div>
        </div>
      </div>
      <div className="spacer" />
      <div className="contentInline smContentColumn gapSm">
        <div className="flexGap gapSm smContentColumn">
          <FormProvider {...methods}>
            <div className="flexGap noPadding smContentColumn gapSm">
              <div>
                <SelectV2
                  name="stage"
                  label={t("STAGE")}
                  allowEmpty
                  choices={[
                    "CLIENT",
                    "CONTACTED",
                    "DISQUALIFIED_PROSPECT",
                    "IN_NEGOTIATION",
                    "LOST",
                    "PENDING_DECISION",
                    "PROSPECT",
                    "QUALIFIED_PROSPECT",
                  ].map(s => ({ value: s, label: t("STAGE_STATUS." + s) }))}
                  onChange={e => onFilterChange("stage", e.target.value)}
                />
              </div>

              <div>
                <SelectV2
                  name="segment"
                  label={t("SEGMENT")}
                  allowEmpty
                  choices={(segments || []).map((s: TSegment) => ({
                    value: s.id,
                    label: s.name,
                  }))}
                  onChange={e => onFilterChange("segment", e.target.value)}
                />
              </div>

              {!isValidating && isAdmin(isCommercial, role) && (
                <div>
                  <CommercialSelection
                    values={
                      commercialFilter
                        ? {
                            value: commercialFilter.id,
                            label:
                              commercialFilter.firstName +
                              " " +
                              commercialFilter.lastName,
                          }
                        : null
                    }
                    onValueChange={(item: any) =>
                      onFilterChange("commercialId", item?.value ?? "")
                    }
                  />
                </div>
              )}
            </div>
          </FormProvider>
        </div>
      </div>

      {error ? (
        <>
          <div className="spacer" />
          <div className="spacer" />
          <p className="bold textCenter">{t("ERROR")}</p>
        </>
      ) : !list ? (
        <Spinner />
      ) : (
        <div>
          <PaginatedTable swrData={swrData}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t("CLIENTS.LEGAL_NAME")}</th>
                  <th>{t(isUSA ? "CLIENTS.CONTACT_NAME" : "CLIENTS.NAME")}</th>
                  <th>{tGlobal("PHONE_NUMBER")}</th>
                  <th>{t("CLIENTS.EMAIL")}</th>
                  <th>{t("STAGE")}</th>
                  <th>{tGlobal("ACTIONS")}</th>
                </tr>
              </thead>
              <tbody>
                {list.content.map(client => (
                  <tr
                    key={client.id}
                    className={`${styles.row} ${styles.clientRow}`}
                    onClick={() => navigate(`/clients/${client.id}/show`)}
                  >
                    <td>{client.legalName ?? "-"}</td>

                    <td>
                      {client.firstName}
                      {!isUSA && <> {client.lastName ?? ""}</>}
                    </td>
                    <td>{client.phone}</td>

                    <td>
                      {client?.email?.endsWith("@noemail.com")
                        ? "-"
                        : client.email}
                    </td>
                    <td>
                      {client.stage ? (
                        <div>
                          <span className="statusChip lh1 textLine">
                            <span
                              className={clsx("dot mrmd", {
                                ["purple"]: [
                                  "CLIENT",
                                  "PROSPECT",
                                  "QUALIFIED_PROSPECT",
                                  "LOST",
                                ].includes(client.stage),
                                ["orange"]: [
                                  "CONTACTED",
                                  "DISQUALIFIED_PROSPECT",
                                  "PENDING_DECISION",
                                ].includes(client.stage),
                                ["green"]: ["IN_NEGOTIATION"].includes(
                                  client.stage
                                ),
                              })}
                            ></span>
                            {client.stage
                              ? t("STAGE_STATUS." + client.stage)
                              : "-"}
                          </span>
                        </div>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      onClick={event => {
                        event.stopPropagation();
                      }}
                    >
                      <ClientDelete client={client} onDone={() => mutate()} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PaginatedTable>
        </div>
      )}
    </>
  );
};

export default ClientList;

type TCommercialSelection = {
  values: any;
  disabled?: boolean;
  onValueChange: (values: any[]) => void;
  label?: ReactNode;
};

export const CommercialSelection = ({
  values,
  disabled,
  onValueChange,
  label,
}: TCommercialSelection) => {
  const [t] = useTranslation("global");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState(values);

  const fetchOptions = async (input: string) => {
    try {
      const payload: any = {
        sort: "firstName,asc",
        page: 0,
        size: 10,
      };
      if (input) {
        payload.search = `text:${input}`;
      }
      const response = await getSupplierCommercials(payload);
      const optionsData = response?.content?.map(item => ({
        value: item.id,
        label: item.firstName + " " + item.lastName,
      }));
      setOptions(optionsData);
    } catch {
      setOptions([]);
    }
  };

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);

  const handleInputChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const handleChange = (selected: any) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    onValueChange?.(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    debouncedFetchOptions(inputValue);
  }, [inputValue, debouncedFetchOptions]);

  return (
    <>
      <p>{label || t("COMMERCIAL_NAME")}</p>
      <Select
        options={options}
        value={selectedOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={<span className="textLine">{t("SELECT_OPTION")}</span>}
        styles={singleSelectStyles}
        isClearable
      />
    </>
  );
};
