import { useEffect, useState } from "react";
import { getDocumentTypes } from "@/http";
import { useTranslation } from "react-i18next";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { useFormContext } from "react-hook-form";

export const DocumentTypeInput = ({
  required,
  documentType,
}: {
  required?: boolean;
  documentType?: string;
}) => {
  const [idTypes, setIdTypes] = useState([]);
  const [typesLoading, seTypeLoading] = useState(true);
  const [t] = useTranslation("purchases");
  const { setValue } = useFormContext();

  useEffect(() => {
    seTypeLoading(true);
    getDocumentTypes().then((documentTypes: any) => {
      const options = documentTypes?.map(({ name, code }: any) => ({
        value: code,
        label: name,
      }));
      setIdTypes(options || []);

      seTypeLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!typesLoading) {
      setValue("documentType", documentType ? documentType ?? "" : "");
    }
  }, [typesLoading]);

  return (
    idTypes && (
      <SelectV2
        defaultValue={documentType}
        name="documentType"
        label={t("DOCUMENT_TYPE")}
        required={required}
        choices={idTypes}
      />
    )
  );
};
