import { LinkItemProps } from "@/models/side-bar";
import { FiCreditCard, FiUsers } from "react-icons/fi";
import { IoReceiptOutline } from "react-icons/io5";
import { BsBoxSeam } from "react-icons/bs";
import { HiOutlineGift, HiOutlineTicket } from "react-icons/hi";
import { HiOutlineSquaresPlus } from "react-icons/hi2";
import { AiOutlinePercentage } from "react-icons/ai";
import { PiUsersThree } from "react-icons/pi";
import { LiaFileUploadSolid } from "react-icons/lia";
import { MdOutlineReceiptLong } from "react-icons/md";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { LuLayoutDashboard } from "react-icons/lu";
import {
  FEAT_ADVANCED_DASHBOARD,
  FEAT_BULK,
  FEAT_CLIENT_SEGMENTATION,
  FEAT_CREDIT,
  FEAT_DISCOUNTS,
  FEAT_OFFERS,
  FEAT_PURCHASES,
  FEAT_SELL_IN,
  FEAT_TICKETS,
} from "./features";

export const menuItems: Array<LinkItemProps> = [
  {
    name: "metrics",
    icon: LuLayoutDashboard,
    link: "/metrics",
    isActive: true,
    activeCountry: ["co", "mx", "us"],
    permission: ["route.dashboard.show"],
    feature: FEAT_ADVANCED_DASHBOARD,
  },
  {
    name: "orders",
    icon: BsBoxSeam,
    link: "/orders",
    isActive: true,
    activeCountry: ["co", "mx", "us"],
    permission: ["route.orders.list"],
  },
  {
    name: "purchases",
    icon: IoReceiptOutline,
    link: "/purchases",
    isActive: true,
    activeCountry: ["co", "mx", "us"],
    permission: ["route.purchases.list"],
    feature: FEAT_PURCHASES,
    subItems: [
      {
        name: "quotes",
        icon: MdOutlineReceiptLong,
        link: "/quotes",
        isActive: true,
        activeCountry: ["co", "mx", "us"],
        permission: ["route.purchases.list"],
      },
      {
        name: "acquisitions",
        icon: BiPurchaseTagAlt,
        link: "/acquisitions",
        isActive: true,
        activeCountry: ["co", "mx", "us"],
        permission: ["route.purchases.my-purchases"],
        feature: FEAT_SELL_IN,
      },
    ],
  },
  {
    name: "products",
    icon: HiOutlineSquaresPlus,
    link: "/products",
    isActive: true,
    activeCountry: ["co", "mx", "us"],
    permission: ["route.products.list"],
    subItems: [
      {
        name: "curves",
        icon: MdOutlineReceiptLong,
        link: "/curves",
        isActive: true,
        activeCountry: ["co", "mx", "us"],
        permission: ["route.products.list"],
      },
      {
        name: "splits",
        icon: BiPurchaseTagAlt,
        link: "/splits",
        isActive: true,
        activeCountry: ["co", "mx", "us"],
        permission: ["route.products.list"],
      },
    ],
  },
  {
    name: "bulk_loads",
    icon: LiaFileUploadSolid,
    link: "/bulk-loads",
    isActive: true,
    activeCountry: ["co", "mx", "us"],
    permission: ["route.bulk-loads.list"],
    feature: FEAT_BULK,
  },
  {
    name: "clients",
    icon: FiUsers,
    link: "/clients",
    isActive: true,
    activeCountry: ["co", "mx", "us"],
    permission: ["route.clients.list"],
    subItems: [
      {
        name: "segments",
        icon: FiUsers,
        link: "/segments",
        isActive: true,
        activeCountry: ["co", "mx", "us"],
        permission: ["route.clients.list"],
        feature: FEAT_CLIENT_SEGMENTATION,
      },
      {
        name: "credits",
        icon: FiCreditCard,
        link: "/credits",
        isActive: true,
        activeCountry: ["co", "mx", "us"],
        permission: ["route.clients.list"],
        feature: FEAT_CREDIT,
      },
    ],
  },
  {
    name: "commercials",
    icon: PiUsersThree,
    link: "/commercials",
    isActive: true,
    activeCountry: ["co", "mx", "us"],
    permission: ["route.commercials.list"],
    subItems: [
      {
        name: "commercial_tracking",
        icon: LuLayoutDashboard,
        link: "/commercial-tracking",
        isActive: true,
        activeCountry: ["co", "mx", "us"],
        permission: ["route.commercial-tracking.show"],
      },
    ],
  },
  {
    name: "tiered-discounts",
    icon: AiOutlinePercentage,
    link: "/tiered-discounts",
    isActive: true,
    activeCountry: [],
    permission: ["route.tiereddiscounts.list"],
    feature: FEAT_DISCOUNTS,
  },
  {
    name: "offers",
    icon: HiOutlineGift,
    link: "/offers",
    activeCountry: ["co", "mx", "us"],
    permission: ["route.offers.list"],
    feature: FEAT_OFFERS,
  },
  {
    name: "tickets",
    icon: HiOutlineTicket,
    link: "/tickets",
    activeCountry: ["co", "mx", "us"],
    permission: ["route.tickets.list"],
    feature: FEAT_TICKETS,
  },
];
