import { QUOTED, TPurchase } from "@/http";
import { FC } from "react";
import { CancelPurchase } from "../CancelPurchase/CancelPurchase";
import { ShippingPurchase } from "../ShippingPurchase/ShippingPurchase";
import { CreatePurchaseFromQuote } from "../Quotes/CreatePurchase";
import { PurchaseRetryButton } from "../RetryPurchase/RetryPurchase";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { CancelQuote } from "../Quotes/CancelQuote";
import { useTranslation } from "react-i18next";
import { UpdateProducts } from "./UpdateProducts";
import { useAuth } from "@/store/auth";
import { ConfirmPurchaseDelivery } from "./ConfirmPurchaseDelivery";
import { getAccountingIntegration } from "@/hooks/useIntegrations";
import { InvoiceManual } from "@/components/purchases/PurchaseShow/Actions/InvoiceManual";
import { DownloadInvoicePDF } from "./DownloadInvoicePDF";
import { DeletePurchase } from "./DeletePurchase";
import { StockInPDF } from "./StockInPDF";
import { isCO } from "@/constants/core";
import { InvoiceButton } from "@/components/order-details/ProductsDetailsV2/InvoiceButton";

export type Props = {
  purchase: TPurchase;
};
export const PurchaseActions: FC<Props> = ({ purchase }) => {
  const [t] = useTranslation("global");
  const { user } = useAuth();

  const isStockIn = purchase?.sellerId === user.id;
  const accountingIntegration = getAccountingIntegration();

  return (
    <>
      <div className={`flexGap flexSmColumn ${styles.actions}`}>
        {!isStockIn && purchase.status !== QUOTED && (
          <>
            {accountingIntegration ? (
              <InvoiceButton
                purchase={purchase}
                id={purchase.id}
                type={"PURCHASE"}
              />
            ) : (
              <>{!purchase?.hasInvoice && <InvoiceManual id={purchase.id} />}</>
            )}
          </>
        )}

        {purchase.status === QUOTED ? (
          <>
            <div>
              <CancelQuote purchaseId={purchase.id} />
            </div>
            <div>
              <CreatePurchaseFromQuote
                purchaseId={purchase.id}
                purchaseProducts={purchase.products}
              />
            </div>
          </>
        ) : (
          <>
            <ShippingPurchase purchase={purchase} />
            <CancelPurchase purchase={purchase} />
            <PurchaseRetryButton purchase={purchase} />

            {isStockIn && (
              <>
                <ConfirmPurchaseDelivery purchase={purchase} />
                <DeletePurchase purchase={purchase} />
              </>
            )}
          </>
        )}
        {!isStockIn && isCO && (
          <>
            <div>
              <Link to={`/purchases/${purchase.id}/invoice`} target="_blank">
                <button className="outlined">{t("GENERATE_POS")}</button>
              </Link>
            </div>
          </>
        )}

        {!isStockIn && (
          <>
            <DownloadInvoicePDF purchase={purchase} />
          </>
        )}
        {isStockIn && (
          <div>
            <StockInPDF purchase={purchase} />
          </div>
        )}
      </div>
    </>
  );
};
