import { Card } from "@/components/shared/Card/Card";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Fragment, useState, useMemo, useEffect } from "react";
import { AiOutlineUpload, AiOutlineDownload } from "react-icons/ai";
import { saveFile } from "@/utils/save-file";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import styles from "./styles.module.scss";
import ProductImage from "@/components/layout/ProductImage";
import { formatCurrency } from "@/utils/currency";
import { BiExpand, BiCollapse } from "react-icons/bi";
import {
  generateOrderProductsGroupsExcelTemplate,
  OrderPayloadProduct,
  parseOrderProductsGroupsExcel,
  TSeller,
} from "@/http";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";

export const SaleProductsExcel = () => {
  const [t] = useTranslation("purchases");
  const { watch, setValue } = useFormContext();
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const seller = watch("seller") as TSeller | undefined;
  const segmentId = seller?.segments?.[0]?.id ?? undefined;

  const products = watch("products") || [];

  const handleDownloadTemplate = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (!seller?.id) {
      showToastError({ message: t("SELECT_SELLER_FIRST") });
      return;
    }

    try {
      setIsDownloading(true);

      const blob = await generateOrderProductsGroupsExcelTemplate({
        sellerId: seller.id,
        segmentId,
      });

      await saveFile(
        blob,
        `quote-template-${seller.legalName || "client"}.xlsx`
      );

      showToastSuccess(t("TEMPLATE_DOWNLOADED"));
    } catch (error) {
      showToastError({ message: t("ERROR_DOWNLOADING_TEMPLATE") });
      console.error("Error downloading template:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);

      const result = await parseOrderProductsGroupsExcel({ file });

      if (result.meta.sellerId !== seller?.id) {
        showToastError({ message: t("WRONG_SELLER_SELECTED") });
        return;
      }

      if (!result || result.products.length === 0) {
        showToastError({ message: t("NO_PRODUCTS_FOUND") });
        return;
      }

      setValue("products", result.products);

      showToastSuccess(t("PRODUCTS_LOADED_SUCCESSFULLY"));
    } catch (error) {
      showToastError(error);
      console.error("Error uploading file:", error);
    } finally {
      setIsUploading(false);
      event.target.value = "";
    }
  };

  return (
    <Card>
      <div className={styles.excelContainer}>
        <div className={styles.actionsContainer}>
          <button
            className="primary md"
            onClick={handleDownloadTemplate}
            disabled={isDownloading || !seller?.id}
            title={
              !seller?.id
                ? t("SELECT_SELLER_FIRST")
                : t("DOWNLOAD_EXCEL_TEMPLATE")
            }
          >
            {isDownloading ? (
              <span className={styles.loadingState}>
                <div className={styles.spinner}></div>
                {t("DOWNLOADING")}...
              </span>
            ) : (
              <>
                <AiOutlineDownload /> {t("DOWNLOAD_EXCEL_TEMPLATE")}
              </>
            )}
          </button>

          <label
            className={`primary md ${styles.uploadButton} ${
              !seller?.id || isUploading ? "disabled" : ""
            }`}
            title={
              !seller?.id ? t("SELECT_SELLER_FIRST") : t("UPLOAD_EXCEL_FILE")
            }
          >
            {isUploading ? (
              <span className={styles.loadingState}>
                <div className={styles.spinner}></div>
                {t("UPLOADING")}...
              </span>
            ) : (
              <>
                <AiOutlineUpload /> {t("UPLOAD_EXCEL_FILE")}
              </>
            )}
            <input
              type="file"
              accept=".xlsx,.xls"
              onChange={handleFileUpload}
              disabled={isUploading || !seller?.id}
              className={styles.fileInput}
            />
          </label>
        </div>

        {!seller?.id && (
          <div className={styles.sellerWarning}>
            <p>{t("EXCEL_SELECT_CLIENT_FIRST")}</p>
          </div>
        )}

        <ProductsTable products={products} />
      </div>
    </Card>
  );
};

interface ProductsTableProps {
  products: OrderPayloadProduct[];
}

const ProductsTable = ({ products }: ProductsTableProps) => {
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const [expandedProducts, setExpandedProducts] = useState<
    Record<string, boolean>
  >({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredProducts = useMemo(() => {
    if (!searchTerm.trim()) return products;

    const searchTerms = searchTerm
      .toLowerCase()
      .split(/\s+/)
      .filter(term => term.length > 0);

    return products.filter((product: OrderPayloadProduct) => {
      return searchTerms.every(term => {
        const productName = product.meta?.name?.toLowerCase() || "";
        const articleNumber = product.meta?.articleNumber?.toLowerCase() || "";

        return productName.includes(term) || articleNumber.includes(term);
      });
    });
  }, [products, searchTerm]);

  const paginatedProducts = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredProducts.slice(startIndex, endIndex);
  }, [filteredProducts, currentPage, itemsPerPage]);

  const totalPages = useMemo(
    () => Math.max(1, Math.ceil(filteredProducts.length / itemsPerPage)),
    [filteredProducts, itemsPerPage]
  );

  useEffect(() => {
    setExpandedProducts({});
  }, [paginatedProducts]);

  const toggleProductExpansion = (productId: string) => {
    setExpandedProducts(prev => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  const getTotalPrice = (product: OrderPayloadProduct) => {
    return (product.quantity || 0) * (product.meta?.wholesalePrice || 0);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  if (products.length === 0) {
    return (
      <div className={styles.noProducts}>
        <p>{t("NO_PRODUCTS_YET")}</p>
        <p>{t("DOWNLOAD_AND_UPLOAD_EXCEL")}</p>
      </div>
    );
  }

  return (
    <div className={styles.productsTable}>
      <div className={styles.tableHeader}>
        <h3>{t("UPLOADED_PRODUCTS")}</h3>
        <div className={styles.searchContainer}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder={t("SEARCH_PRODUCTS")}
            className={styles.searchInput}
          />
        </div>
      </div>

      <div className="tableScroll">
        <PaginatedTable
          swrData={{ data: { content: paginatedProducts, totalPages } }}
          setCurrentPage={n => setCurrentPage(n + 1)}
        >
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{t("PRODUCT_NAME")}</th>
                <th>{tGlobal("PURCHASES.ORDERS.PRICE")}</th>
                <th>{t("TOTAL_QUANTITY")}</th>
                <th>{tGlobal("PURCHASES.ORDERS.TOTAL_PRICE")}</th>
                <th>{tGlobal("ACTIONS")}</th>
              </tr>
            </thead>
            <tbody>
              {paginatedProducts.map((product: OrderPayloadProduct) => (
                <Fragment key={product.id}>
                  <tr key={product.id} className={styles.productRow}>
                    <td>
                      {product.meta?.imageUrl && (
                        <ProductImage
                          images={[
                            {
                              src: product.meta?.imageUrl,
                              mimeType: "image/png",
                            },
                          ]}
                        />
                      )}
                    </td>
                    <td className={styles.productInfo}>
                      <div className="flexColumn leftAlign">
                        <span className="bold">{product.meta?.name}</span>
                        <span className="mdFont">
                          {product.meta?.articleNumber}
                        </span>
                      </div>
                    </td>
                    <td>{formatCurrency(product.meta?.wholesalePrice || 0)}</td>
                    <td>{product.quantity}</td>
                    <td>{formatCurrency(getTotalPrice(product))}</td>
                    <td>
                      <div>
                        <span
                          className="circleButton pointer"
                          onClick={() => toggleProductExpansion(product.id)}
                          title={
                            expandedProducts[product.id]
                              ? t("COLLAPSE")
                              : t("EXPAND")
                          }
                        >
                          {expandedProducts[product.id] ? (
                            <BiCollapse />
                          ) : (
                            <BiExpand />
                          )}
                        </span>
                      </div>
                    </td>
                  </tr>

                  {expandedProducts[product.id] && (
                    <tr>
                      <td colSpan={6}>
                        <div className={styles.expandedContent}>
                          {product.curves && product.curves.length > 0 && (
                            <div className={styles.curvesSection}>
                              <h4>{t("CURVES")}</h4>
                              <table className={styles.curvesTable}>
                                <thead>
                                  <tr>
                                    <th>{t("CURVE_NAME")}</th>
                                    <th>{t("QUANTITY")}</th>
                                    <th>{t("OPTIONS")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {product.curves.map((curve, curveIndex) => (
                                    <tr
                                      key={`${product.id}-curve-${curveIndex}`}
                                    >
                                      <td>{curve.name}</td>
                                      <td>{curve.quantity}</td>
                                      <td>
                                        <div className={styles.optionsGrid}>
                                          {curve.products.map(
                                            (option, optionIndex) => (
                                              <div
                                                key={`${option.optionType}-${optionIndex}`}
                                                className={styles.optionItem}
                                              >
                                                <span className="bold">
                                                  {option.optionValue}:
                                                </span>{" "}
                                                {option.quantity}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}

                          {product.splits && product.splits.length > 0 && (
                            <div className={styles.splitsSection}>
                              <h4>{t("DELIVERY_SCHEDULE")}</h4>
                              <table className={styles.splitsTable}>
                                <thead>
                                  <tr>
                                    <th>{t("DELIVERY_DATE")}</th>
                                    <th>{t("PERCENTAGE")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {product.splits.map((split, splitIndex) => (
                                    <tr
                                      key={`${product.id}-split-${splitIndex}`}
                                    >
                                      <td>{split.alias}</td>
                                      <td>{split.percentage}%</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </PaginatedTable>
      </div>
    </div>
  );
};
