import { Card } from "@/components/shared/Card/Card";
import { getSupplierProductOptionById, TSegment } from "@/http";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { CurveProductModal } from "./CurveProductModal";
import styles from "./styles.module.scss";

export const CurveProduct = ({
  id,
  segments = [],
}: {
  id: string;
  segments: TSegment[];
}) => {
  const [t] = useTranslation("global");

  const { data, mutate, isValidating } = useSWR(
    [id, `product-option-${id}`],
    getSupplierProductOptionById
  );

  if (!data) {
    return null;
  }

  return (
    <Card>
      <p className="bold contentInline">
        <span>{data?.name}</span>
        {!isValidating && (
          <CurveProductModal productOption={data} onDone={() => mutate()} />
        )}
      </p>
      <div className="spacer" />
      {(data.curves || []).length > 0 ? (
        <div className="tableScroll">
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("NAME")}</th>
                {(data?.options || []).map((option: string) => (
                  <th key={option}>{option}</th>
                ))}
                <th>{t("TOTAL")}</th>
                <th>{t("SEGMENT")}</th>
              </tr>
            </thead>
            <tbody>
              {(data?.curves || []).map((curve: any) => {
                const totalQuantity = (curve?.products || []).reduce(
                  (sum: number, p: any) => sum + Number(p?.quantity || 0),
                  0
                );
                return (
                  <tr key={curve?.id} className={styles.row}>
                    <td className="grayBackground textLine">{curve?.name}</td>
                    {(data?.options || []).map((option: string) => {
                      const product = curve?.products?.find(
                        (p: any) => p?.optionValue === option
                      );
                      return (
                        <td key={option}>
                          <div className={styles.boxContent}>
                            <span>
                              {product.quantity ? product.quantity : "-"}
                            </span>
                          </div>
                        </td>
                      );
                    })}
                    <td>{totalQuantity}</td>
                    <td>
                      <div className={styles.boxContent}>
                        <span className="textLine">
                          {segments?.find(s => s?.id === curve?.segmentId)
                            ?.name ?? "-"}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>{t("NO_DATA")}</p>
      )}
    </Card>
  );
};
