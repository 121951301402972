import { TSellerAddress } from "@/http";
import clsx from "clsx";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { Modal } from "../shared/Modal/Modal";
import { AddressForm } from "./AddressForm";
import styles from "./styles.module.scss";
import { AiOutlineEdit } from "react-icons/ai";
import UpsertActionButton from "./ActionButtons/UpsertActionButton";

type AddressModalProps = {
  clientId: string;
  address?: TSellerAddress;
  onDone?: (address: TSellerAddress) => void;
  iconButton?: boolean;
};

export const AddressModal: FC<AddressModalProps> = ({
  clientId,
  address,
  onDone,
  iconButton,
}) => {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation("global");

  return (
    <>
      <UpsertActionButton
        iconButton={iconButton}
        isEdit={!!address}
        onAction={() => setOpen(true)}
      />
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        {open && (
          <div className="leftAlign marginTop1">
            <h6 className="bolder">
              {t(address ? "CLIENTS.EDIT_ADDRESS" : "CLIENTS.NEW_ADDRESS")}
            </h6>
            <div className="divider" />
            <div className="spacer" />
            <AddressForm
              clientId={clientId}
              address={address}
              onDone={address => {
                onDone?.(address);
                setOpen(false);
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
};
