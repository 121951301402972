import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useAuth } from "@/store/auth";
import { IntegrationModal } from "./IntegrationModal";
import { integrationTypes } from "./constants";
import { IntegrationCard } from "./Card";
import { useIntegrations } from "@/hooks/useIntegrations";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import styles from "./styles.module.scss";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { useSubscription } from "@/hooks/useSubscription";
import { FEAT_INTEGRATIONS } from "@/constants/features";

export default function IntegrationsPage() {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { refreshUserData } = useAuth();
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("SIDEBAR.INTEGRATIONS"));
  }, [t]);

  useEffect(() => {
    refreshUserData();
  }, []);

  if (!hasFeature(FEAT_INTEGRATIONS)) {
    return <LockedFeaturePlaceholder />;
  }

  return <IntegrationsPageContent />;
}

const IntegrationsPageContent = () => {
  const [t] = useTranslation("global");
  const { data, error } = useIntegrations();

  if (error)
    return <IntegrationCard>{t("INTEGRATIONS.ERROR_LOAD")}</IntegrationCard>;

  if (!data)
    return (
      <IntegrationCard>
        <Spinner />
      </IntegrationCard>
    );

  if (data.content.length === 0)
    return (
      <IntegrationCard>{t("INTEGRATIONS.NO_INTEGRATIONS")}</IntegrationCard>
    );

  return (
    <div className={styles.integrationsGrid}>
      {data.content.map(supplierIntegration => (
        <IntegrationCard
          key={supplierIntegration.id}
          className={clsx(supplierIntegration.credentialId && styles.enabled)}
        >
          <div
            className={clsx(
              styles.integrationContent,
              !supplierIntegration.enabled && styles.disabled
            )}
          >
            <div className={styles.first}>
              {supplierIntegration.imageUrl && (
                <img
                  src={supplierIntegration.imageUrl}
                  alt={supplierIntegration.name}
                  className={styles.logo}
                />
              )}
              <h3 className={styles.name}>{supplierIntegration.name}</h3>
              <div className={styles.type}>
                {integrationTypes[supplierIntegration.type].icon}{" "}
                {t(integrationTypes[supplierIntegration.type].text)}
              </div>
            </div>
            <div className={styles.second}>
              <div className={styles.status}>
                <h4>{t("INTEGRATIONS.STATUS")}:</h4>
                {supplierIntegration.credentialId ? (
                  <div className={styles.connected}>
                    {t("INTEGRATIONS.CONNECTED")}
                  </div>
                ) : (
                  <div className={styles.disconnected}>
                    {t("INTEGRATIONS.NO_CONNECTED")}
                  </div>
                )}
              </div>
              {supplierIntegration.enabled ? (
                <IntegrationModal supplierIntegration={supplierIntegration} />
              ) : (
                <p className={styles.disabled}>
                  {t("INTEGRATIONS.DISABLED_INTEGRATION")}
                </p>
              )}
            </div>
          </div>
        </IntegrationCard>
      ))}
    </div>
  );
};
