import { TSupplierCommercial } from "@/http/types/supplier-commercials";
import { AxiosRequestConfig } from "axios";
import { getInstance } from "../../core/axios-gateway";
import { TBalance } from "../../types/balance";
import { TRestListData, TRestListParams } from "../../types/rest";
import {
  TSeller,
  TSellerDebt,
  TSellerFile,
  TSellerAddress,
  TSellerFilePayload,
  TSellerFileValidation,
  TSellerValidationResp,
  TSellerValidationPayload,
} from "../../types/seller";
import { TDocumentType } from "@/http/types/metadata";

export * from "./debt";
export * from "./billing-addresses";
export * from "./segments";
export * from "./credits";

const BASE_PATH = "/api/clients";

export const getDocumentTypes = async () => {
  return getInstance()
    .get<TDocumentType[]>(`${BASE_PATH}/document-types`)
    .then(res => res.data);
};

export const getSellerById = async (
  sellerId: string,
  params?: AxiosRequestConfig
) => {
  return getInstance()
    .get<TSeller>(`${BASE_PATH}/${sellerId}`, { ...params, timeout: 10000 })
    .then(res => res.data);
};

export const createSellerById = async (seller: Partial<TSeller>) => {
  return getInstance()
    .post<TSeller>(`${BASE_PATH}/${seller.id}`, seller)
    .then(res => res.data);
};

export const createSeller = async (
  seller: Partial<TSeller>,
  params?: AxiosRequestConfig
) => {
  return getInstance()
    .post<TSeller>(BASE_PATH, seller, params)
    .then(res => res.data);
};

export const relateSupplierSeller = async (
  sellerId: string,
  supplierId: string,
  commercialId?: string
) => {
  return getInstance()
    .post<TSeller>(`${BASE_PATH}/${sellerId}/supplier/${supplierId}`, {
      commercialId,
    })
    .then(res => res.data);
};

export const updateSellerCommercial = async (
  sellerId: string,
  supplierId: string,
  data: Partial<TSupplierCommercial>
) => {
  return getInstance()
    .put<TSupplierCommercial>(
      `${BASE_PATH}/${sellerId}/supplier/${supplierId}`,
      data
    )
    .then(res => res.data);
};

export const unlinkSupplierSeller = async (
  sellerId: string,
  supplierId: string
) => {
  return getInstance()
    .delete<TSeller>(`${BASE_PATH}/${sellerId}/supplier/${supplierId}`)
    .then(res => res.data);
};

export const updateSeller = async (
  sellerId: string,
  data: Partial<TSeller>
) => {
  return getInstance()
    .put<TSeller>(`${BASE_PATH}/${sellerId}`, data)
    .then(res => res.data);
};

export const deleteSeller = async (sellerId: string, data: any) => {
  return getInstance()
    .delete(`${BASE_PATH}/${sellerId}`, { data })
    .then(res => res.data);
};

export const updateSellerBank = async (seller: Partial<TSeller>) => {
  return getInstance()
    .put<TSeller>(`${BASE_PATH}/bank/${seller.id}`, seller)
    .then(res => res.data);
};

export const updateSellerLocation = async (seller: Partial<TSeller>) => {
  return getInstance()
    .put<TSeller>(`${BASE_PATH}/location/${seller.id}`, {
      ...seller.location,
    })
    .then(res => res.data);
};

export const getBalanceBySellerId = async (sellerId: string) => {
  return getInstance()
    .get<TBalance>(`${BASE_PATH}/balance/${sellerId}`)
    .then(res => res.data);
};

export const getSellers = async (params: TRestListParams) => {
  params.sort = params.sort || "createdAt,desc";
  return getInstance()
    .get<TRestListData<TSeller>>(`${BASE_PATH}/`, { params })
    .then(res => res.data);
};

export const getTransactionsBySellerId = async (sellerId: string) => {
  const range = "[0,1000]";
  return getInstance()
    .get(`${BASE_PATH}/transactions/${sellerId}`, {
      headers: { range },
    })
    .then(res => res.data);
};

export const uploadSellerFile = async (file: TSellerFilePayload) => {
  return getInstance()
    .post<TSellerFile>(`${BASE_PATH}/files`, file)
    .then(res => res.data);
};

export const getSellersByFiles = async (params: TRestListParams = {}) => {
  return getInstance()
    .get<TRestListData<TSeller>>(`${BASE_PATH}/files`, {
      params,
    })
    .then(res => res.data);
};

export const validateSellerFile = async (validation: TSellerFileValidation) => {
  return getInstance()
    .put<TSellerFileValidation>(`${BASE_PATH}/files/validate`, validation)
    .then(res => res.data);
};

export const getFileBySellerId = async (sellerId: string) => {
  return getInstance()
    .get<TSellerFile>(`${BASE_PATH}/${sellerId}/files`)
    .then(res => res.data);
};

export const getSellersbyIds = async (ids: string[]) => {
  return getInstance()
    .post<TSeller[]>(
      `${BASE_PATH}/by-ids`,
      { ids },
      { headers: { "x-log": "omit" } }
    )
    .then(res => res.data);
};

export const getAddressListBySellerId = async (
  sellerId: string,
  params: TRestListParams = {}
) => {
  return getInstance()
    .get<TRestListData<TSellerAddress>>(
      `${BASE_PATH}/${sellerId}/address/list`,
      {
        params,
      }
    )
    .then(res => res.data);
};

export const createAddress = async (sellerId: string, data: TSellerAddress) => {
  return getInstance()
    .post<TSellerAddress>(`${BASE_PATH}/${sellerId}/address`, data)
    .then(res => res.data);
};

export const deleteAddress = async (sellerId: string, addressId: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${sellerId}/address/${addressId}`)
    .then(res => res.data);
};

export const getAddress = async (sellerId: string, addressId: string) => {
  return getInstance()
    .get<TSellerAddress>(`${BASE_PATH}/${sellerId}/address/${addressId}`)
    .then(res => res.data);
};

export const updateAddress = async (
  sellerId: string,
  addressId: string,
  data: Partial<TSellerAddress>
) => {
  return getInstance()
    .put<TSellerAddress>(`${BASE_PATH}/${sellerId}/address/${addressId}`, data)
    .then(res => res.data);
};

export const getDebt = async (sellerId: string) => {
  return getInstance()
    .get<TSellerDebt>("/api/debt/" + sellerId)
    .then(res => res.data);
};

export const validateSellerData = async (
  validation: TSellerValidationPayload
) => {
  return getInstance()
    .post<TSellerValidationResp>(
      `${BASE_PATH}/validate-availability`,
      validation
    )
    .then(res => res.data);
};

export const getSellerAvailableCredit = async (sellerId: string) => {
  return getInstance()
    .get<{ availableCreditAmount: number }>(
      `/api/clients/available-credit-amount/${sellerId}`
    )
    .then(res => res.data);
};

export const getSellerByPhone = async (
  phone: string,
  params?: AxiosRequestConfig
) => {
  return getInstance()
    .get<TSeller>(`${BASE_PATH}/by-phone/${phone}`, params)
    .then(res => res.data);
};
