import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { showToastError } from "@/hooks/useToast";
import { getCreditMemoPDF, getSellerById } from "@/http";
import { formatCurrency } from "@/utils/currency";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoEyeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { Modal } from "../shared/Modal/Modal";
import { SellerResume } from "../shared/Sellers/SellerResume/SellerResume";
import styles from "./CreditMemoDetail.module.scss";

export const CreditMemoDetail = ({ creditMemo }: { creditMemo: any }) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data: seller } = useSWR(
    creditMemo?.sellerId ? [creditMemo?.sellerId, "getSellerById"] : null,
    getSellerById
  );

  const downloadPDF = async () => {
    try {
      setIsLoading(true);
      const file = await getCreditMemoPDF(creditMemo?.id);
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download =
        creditMemo?.number + "_" + seller?.legalName ||
        seller?.firstName + "_credit";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <span onClick={() => setIsOpen(true)} className="circleButton pointer">
        <IoEyeOutline />
      </span>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t("CREDIT_MEMO_DETAIL")}
        footer={
          <>
            <button disabled={isLoading} onClick={() => setIsOpen(false)}>
              {t("CLOSE")}
            </button>
            <button
              disabled={isLoading}
              onClick={downloadPDF}
              className="primary lg"
            >
              {t("DOWNLOAD")} PDF
            </button>
          </>
        }
      >
        <div className="bold">
          {t("CREDIT")} #{creditMemo?.number} | {t("DATE")}{" "}
          {creditMemo?.createdAt
            ? format(
                new Date(creditMemo.createdAt),
                isUSA ? USA_DATE_HOUR : DATE_HOUR
              )
            : "-"}
        </div>
        <div className="bold">
          {t("AMOUNT")}: {formatCurrency(creditMemo?.amount || 0)}
        </div>

        {creditMemo?.purchaseId && (
          <span className="mdFont purpleText">
            {t("IN_USE_ON_PURCHASE")}{" "}
            <Link
              to={`/purchases/${creditMemo?.purchaseId}/show`}
              className="textUnderline"
            >
              {creditMemo?.purchaseNumber}
            </Link>
          </span>
        )}
        <div className="spacer" />
        <div className="flexGap">
          <div className="col-6 noPadding">
            <p className="bold">{t("CREDIT_TO")}</p>
            <SellerResume seller={seller} hideEdit />
          </div>
          {creditMemo?.createdByName && (
            <div className="col-6 noPadding">
              <p className="bold">{t("CREATED_BY")}</p>
              <p>{creditMemo?.createdByName}</p>
            </div>
          )}
        </div>

        <div className="spacer" />
        <p className="bold">{t("PRODUCTS.TITLE")}</p>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t("QUANTITY")}</th>
              <th>{t("PRODUCT")}</th>
              <th>{t("REASON")}</th>
              <th>{t("TOTAL")}</th>
            </tr>
          </thead>
          <tbody>
            {(creditMemo?.products || []).map((p: any, index: number) => (
              <tr key={index}>
                <td>{p?.quantity ?? "-"}</td>
                <td>{p?.name ?? "-"}</td>
                <td>{p?.reason ?? "-"}</td>
                <td>
                  {formatCurrency(
                    (p?.quantity || 0) * (p?.wholesalePrice || 0)
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </>
  );
};
