import { getSeeriApi } from "@/http";

export const BULK_LOADS_BASE_PATH = "/api/bulk-loads/orders";

export const generateOrderProductsGroupsExcelTemplate = async ({
  sellerId,
  segmentId,
}: {
  sellerId: string;
  segmentId: string;
}) => {
  return getSeeriApi()
    .get(`${BULK_LOADS_BASE_PATH}/template`, {
      params: { sellerId, segmentId },
      responseType: "blob",
    })
    .then(res => res.data);
};

export const parseOrderProductsGroupsExcel = async ({
  file,
}: {
  file: File;
}) => {
  const formData = new FormData();
  formData.append("file", file);

  return getSeeriApi()
    .post<OrderPayload>(`${BULK_LOADS_BASE_PATH}/process-file`, formData)
    .then(res => res.data);
};

export interface OrderPayload {
  meta: OrderPayloadMetadata;
  products: OrderPayloadProduct[];
}

// Metadata

export interface OrderPayloadMetadata {
  sellerId: string;
  segmentId?: string;
  productOptions: SupplierProductOption[];
  maxCurves: number;
  splits: SupplierSplit[];
}

export interface SupplierProductOption {
  id: string;
  name: string;
  code: string;
  type: string;
  options: string[];
  curves: SupplierProductOptionCurve[];
}

export interface SupplierProductOptionCurve {
  id: string;
  name: string;
  code: string;
  products: SupplierProductOptionCurveProduct[];
  createdAt: Date;
}

export interface SupplierProductOptionCurveProduct {
  optionType: string;
  optionValue: string;
  quantity: number;
}

export interface SupplierSplit {
  id: string;
  alias: string;
}

// Products

export interface OrderPayloadProduct {
  id: string;
  curves: OrderPayloadProductCurve[];
  quantity: number;
  splits: OrderPayloadProductSplit[];
  meta: {
    imageUrl: string;
    articleNumber: string;
    name: string;
    wholesalePrice: number;
  };
}

export interface OrderPayloadProductCurve {
  id: string;
  name: string;
  quantity: number;
  products: {
    optionType: string;
    optionValue: string;
    quantity: number;
  }[];
}

export interface OrderPayloadProductSplit {
  id: string;
  alias: string;
  percentage: number;
}
