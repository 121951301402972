import { ReactNode, useEffect, useState } from "react";
import Select from "react-select";
import { getSupplierProductOptions, TBrand } from "@/http";
import { useTranslation } from "react-i18next";
import { singleSelectStyles } from "@/constants/input";
import useSWR from "swr";
import { Controller, useFormContext } from "react-hook-form";
import { get } from "lodash-es";
import styles from "./styles.module.scss";
import clsx from "clsx";

type TSingleSelectionProductOption = {
  disabled?: boolean;
  required?: boolean;
  infoLabel?: string;
  name: string;
  label?: ReactNode;
};

export const SingleSelectionProductOption = ({
  disabled,
  required,
  infoLabel,
  name,
  label,
}: TSingleSelectionProductOption) => {
  const [t] = useTranslation("global");
  const [options, setOptions] = useState<any[]>([]);
  const params: any = {
    size: 200,
    page: 0,
    sort: "createdAt,desc",
  };
  const { data } = useSWR(
    [params, "product-options-list"],
    getSupplierProductOptions
  );

  const { content: productOptions = [] } = data || { content: [] };

  const {
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    const formattedOptions = (productOptions || []).map((s: TBrand) => ({
      value: s.id,
      label: s.name,
    }));

    if (JSON.stringify(options) !== JSON.stringify(formattedOptions)) {
      setOptions(formattedOptions);
    }
  }, [productOptions, options]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required ? t("FIELD_REQUIRED") : undefined }}
      render={({ field: { onChange, value } }) => (
        <div
          className={clsx(styles.field, {
            [styles.required]: get(errors, name),
          })}
        >
          {label ? (
            <>{label}</>
          ) : (
            <p className={styles.label}>{t("PROUCT_OPTION")}</p>
          )}
          <Select
            options={options}
            value={options?.filter(opt => value?.includes(opt.value))}
            onChange={selected => {
              onChange(selected.value);
            }}
            isDisabled={disabled}
            placeholder={t("SELECT_OPTION")}
            styles={singleSelectStyles}
            noOptionsMessage={() => t("NO_OPTIONS")}
          />
          {!!infoLabel && <span className={styles.infoLabel}>{infoLabel}</span>}
          {!!get(errors, name) && (
            <span className={styles.errorsLabel}>
              {get(errors as any, name).message}
            </span>
          )}
        </div>
      )}
    />
  );
};
