import { CreditMemoDetail } from "@/components/credit-memo/CreditMemoDetail";
import { CreditMemoModal } from "@/components/credit-memo/CreditMemoModal";
import { Card } from "@/components/shared/Card/Card";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { getCreditMemoBySeller, getSellerById } from "@/http";
import { formatCurrency } from "@/utils/currency";
import useUrlState from "@ahooksjs/use-url-state";
import clsx from "clsx";
import { format } from "date-fns";
import { get } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import useSWR from "swr";

import styles from "./styles.module.scss";
import { DeleteCreditMemo } from "./DeleteCreditMemo";

export const ClientCreditMemo = () => {
  const { id } = useParams();
  const [t] = useTranslation("global");
  const [{ cmpage: page }] = useUrlState({ cmpage: "0" });
  const { data: seller } = useSWR(
    id ? [id, "sellerById"] : null,
    getSellerById
  );

  const params: any = {
    size: 10,
    page,
    sort: "createdAt,desc",
    search: `sellerId:${id}`,
  };

  const swrData = useSWR(
    id ? [params, "clientMemo"] : null,
    getCreditMemoBySeller
  );
  const { data: transactions, error, mutate } = swrData;

  return (
    <>
      {seller && (
        <>
          <div className="contentInline">
            <span />
            <CreditMemoModal
              actionElement={
                <span className="purpleText bold textUnderline">
                  {t("CREATE_CREDIT_MEMO")}
                </span>
              }
              seller={seller}
              onDone={() => {
                mutate();
              }}
            />
          </div>
          <div className="spacer" />
        </>
      )}
      <Card className={clsx("noPadding", styles.tableContainer)}>
        {transactions && !error && (
          <PaginatedTable swrData={swrData} paginationKey={"cmpage"}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.purpleHead}>
                  <th>{t("DATE")}</th>
                  <th className="leftAlign">{t("REASON")}</th>
                  <th>{t("AMOUNT")}</th>
                  <th>{t("ACTIONS")}</th>
                </tr>
              </thead>
              <tbody>
                {transactions?.content?.map(transaction => (
                  <tr key={transaction.id} className={styles.row}>
                    <td>
                      {transaction?.createdAt
                        ? format(
                            new Date(transaction.createdAt),
                            isUSA ? USA_DATE_HOUR : DATE_HOUR
                          )
                        : "-"}
                    </td>
                    <td className="leftAlign">
                      {get(transaction, "products[0].reason", "-")}
                    </td>
                    <td
                      className={clsx("flexColumn lh1", {
                        ["redText"]:
                          transaction?.orientation === "NEGATIVE" &&
                          !transaction?.purchaseId,
                        ["greenText"]:
                          transaction?.orientation === "POSITIVE" &&
                          !transaction?.purchaseId,
                      })}
                    >
                      <span>{formatCurrency(transaction?.amount ?? 0)}</span>
                      {transaction?.purchaseId && (
                        <span className="mdFont purpleText">
                          {t("IN_USE_ON_PURCHASE")}{" "}
                          <Link
                            to={`/purchases/${transaction?.purchaseId}/show`}
                            className="textUnderline"
                          >
                            {transaction?.purchaseNumber}
                          </Link>
                        </span>
                      )}
                    </td>
                    <td>
                      <div className="flexGap center centerAlign">
                        <CreditMemoDetail creditMemo={transaction} />
                        <DeleteCreditMemo
                          transaction={transaction}
                          onDone={() => mutate()}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PaginatedTable>
        )}
      </Card>
    </>
  );
};
