import { Card } from "@/components/shared/Card/Card";
import { formatCurrency } from "@/utils/currency";
import { useTranslation } from "react-i18next";
import { SaleCurveEdition } from "./SaleCurveEdition";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { AiOutlineDelete, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { useState } from "react";

export const SaleCurveDetail = ({
  curves = [],
  unitPrice = 0,
  editCurves = false,
  productId = "",
}: {
  curves: any[];
  unitPrice: number;
  editCurves: boolean;
  productId?: string;
}) => {
  const [t] = useTranslation("global");
  const [toggleVisibility, setToggleVisibility] = useState<boolean>(false);

  const allOptions = Array.from(
    new Set(
      curves.flatMap(item => item.products.map((p: any) => p.optionValue))
    )
  ).sort();

  return (
    <div className="pw1">
      <div className="bold contentInline">
        <div className="flexGap">
          <p className={styles.productsTitle}>{t("SALE_CURVES")}</p>
          <div className={clsx(styles.searchInput)}></div>
        </div>
        <span
          className="pointer circleButton"
          onClick={() => setToggleVisibility(!toggleVisibility)}
        >
          {!toggleVisibility ? <AiOutlineDown /> : <AiOutlineUp />}
        </span>
      </div>
      <div className="divider" />
      {editCurves && productId && (
        <SaleCurveEdition
          curves={curves}
          unitPrice={unitPrice}
          productId={productId}
        />
      )}

      {toggleVisibility && curves.length > 0 ? (
        <Card className="noPadding">
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("CURVE")}</th>
                {allOptions.map((option: string) => (
                  <th key={option}>{option}</th>
                ))}
                <th>{t("UNITS")}</th>
                <th>{t("QUANTITY")}</th>
                <th>{t("TOTAL")}</th>
              </tr>
            </thead>
            <tbody>
              {curves.map((curve: any) => {
                const totalUnits = (curve?.products || []).reduce(
                  (sum: number, p: any) => sum + Number(p?.quantity || 0),
                  0
                );
                return (
                  <tr key={curve?.id} className={styles.row}>
                    <td className="grayBackground">{curve?.name}</td>
                    {allOptions.map((option: string) => {
                      const product = curve?.products?.find(
                        (p: any) => p?.optionValue === option
                      );
                      return (
                        <td key={option}>
                          {product.quantity ? product.quantity : "-"}
                        </td>
                      );
                    })}
                    <td className="grayBackground">{totalUnits}</td>
                    <td className="grayBackground">{curve?.quantity ?? 0}</td>
                    <td className="grayBackground">
                      {formatCurrency(unitPrice * totalUnits * curve?.quantity)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      ) : (
        toggleVisibility && <p>{t("NO_DATA")}</p>
      )}
    </div>
  );
};
