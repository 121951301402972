import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import { format, isSameDay } from "date-fns";
import { DATE_MONT_YEAR, USA_DATE_MONT_YEAR } from "@/constants/date";
import styles from "./styles.module.scss";
import { isUSA } from "@/constants/core";
import { TSupplierOrderDetails } from "@/models/order-details";
import { hasPermission } from "@/utils/validations/permissions";
import { DeleteDispatchOrder } from "./DeleteOrder";
import { AiOutlineDelete } from "react-icons/ai";
import { CheckboxButton } from "@/components/shared/Input/CheckboxButton";
import { useSubPurchases } from "@/store/subpurchases";
import useSWR from "swr";
import { getPurchasesByIds, getSupplierOrders } from "@/http";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import OrderFilter from "../OrderFilter/OrderFilter";
import { Card } from "@/components/shared/Card/Card";
import { useMemo } from "react";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";

const TableOrders = () => {
  const [search] = useSearchParams(
    "page=0&sortBy=createdAt&sortDirection=DESC"
  );
  const currentPage = +search.get("page")!;
  const sortBy = search.get("sortBy");
  const filter = search.get("filter");
  const sortDirection = search.get("sortDirection");
  const statusFilters = JSON.parse(search.get("status") || "{}");
  const startDate = search.get("startDate");
  const endDate = search.get("endDate");
  const { user, isCommercial, role } = useAuth();
  const [t] = useTranslation("orders-page");
  const [tPurchases] = useTranslation("purchases");

  const swrData = useSWR(
    {
      key: `dispatch_orders_${currentPage}${sortBy}${sortDirection}${filter}${statusFilters}${startDate}${endDate}`,
      currentPage,
      sortBy,
      sortDirection,
      filter,
      statusFilters,
      startDate,
      endDate,
    },
    ({ currentPage: page, startDate, endDate }) => {
      const sort = `${sortBy},${sortDirection}`;

      const tempStatusFilter = Object.entries(statusFilters ?? "")
        .filter(([, value]) => value !== "")
        .map(([key, value]) => {
          return `${key}:${value}`;
        })
        .join(",");

      const datesFilter =
        !!startDate && !!endDate
          ? `createdAt>${startDate} 00:00:00,createdAt<${endDate} 23:59:59`
          : "";
      const textFilter = filter ? `text:${filter}` : "";

      const search = [tempStatusFilter, datesFilter, textFilter]
        .filter(Boolean)
        .join(",");

      return getSupplierOrders(user.id, {
        size: 10,
        page,
        search,
        sort,
      });
    }
  );

  const { data: list, error, mutate } = swrData;

  const purchaseIds = useMemo(
    () => list?.content?.map(s => s.purchaseId) || [],
    [list]
  );
  const { data: purchases } = useSWR(
    purchaseIds.length > 0 ? [purchaseIds, "purchases-by-ids"] : null,
    getPurchasesByIds
  );

  list?.content.forEach(o => {
    const purchase = purchases?.find(p => p.id === o.purchaseId);
    o.client = purchase?.client;
    o.purchaseSupplierId = purchase?.supplierId;
  });

  return (
    <>
      <div className="spacer" />
      <OrderFilter mutate={mutate} />
      <div className="spacer" />
      <Card className="noPadding">
        {error ? (
          <>
            <div className="spacer" />
            <div className="spacer" />
            <p className="bold textCenter">{t("ERROR")}</p>
          </>
        ) : !list ? (
          <Spinner />
        ) : (
          <div>
            <PaginatedTable swrData={swrData}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th />
                    <th>{t("DETAILS.STATUS")}</th>
                    <th>{t("ORDERS.CREATED_AT")}</th>

                    <th>{t("ORDERS.ID")}</th>
                    <th>{t("CLIENT")}</th>
                    <th>{t("CITY")}</th>
                    <th>{tPurchases("COMMERCIAL")}</th>

                    {hasPermission(isCommercial, role, [
                      "action.orders.delete",
                    ]) && <th>{t("ACTIONS")}</th>}
                  </tr>
                </thead>
                <tbody>
                  {list?.content?.map(order => (
                    <DispatchOrderRow
                      order={order as any}
                      key={order.id}
                      mutate={mutate}
                    />
                  ))}
                </tbody>
              </table>
            </PaginatedTable>
          </div>
        )}
      </Card>
    </>
  );
};

const DispatchOrderRow = ({
  order,
  mutate,
}: {
  order: TSupplierOrderDetails;
  mutate: () => void;
}) => {
  const { user, isCommercial, role } = useAuth();
  const isOwnDispatch = order?.purchaseSupplierId === user.id;
  const [t] = useTranslation("orders-page");
  const navigate = useNavigate();
  const { selectedSubPurchases, setSelectedSubPurchases } = useSubPurchases();
  const isInvalidDate = (date = "", status = "") => {
    const expiresAt = new Date(date);
    const currentDate = new Date();

    return (
      isSameDay(currentDate, expiresAt) &&
      ["PENDING", "REMISSION_GENERATED"].includes(status)
    );
  };

  return (
    <tr
      key={order.id}
      className={`${styles.row} ${styles.dispatchRow} ${
        isInvalidDate(order.expiresAt, order.supplierStatus)
          ? styles.dangerRow
          : ""
      }`}
      onClick={() => {
        navigate(`/orders/${order.id}`);
      }}
    >
      <td>
        <CheckboxButton
          checked={Boolean(selectedSubPurchases[order.id])}
          onChange={() => {
            setSelectedSubPurchases(order);
          }}
          onClick={event => {
            event.stopPropagation();
          }}
        />
      </td>
      <td>{order.providerDeliveryStatusName}</td>
      <td className="textLine">
        {format(
          new Date(order.createdAt),
          isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
        )}
      </td>
      <td>
        <div>
          <p>{order?.subPurchaseNumber ?? "-"}</p>
          {isInvalidDate(order.expiresAt, order.supplierStatus) && (
            <p>{t("TO_EXPIRE_ORDER")}</p>
          )}
        </div>
      </td>

      <td>
        {isOwnDispatch && (
          <>
            <div className="flexGap lh1 leftAlign">
              <SellerResume seller={order?.client} isSlim />
            </div>
          </>
        )}
      </td>
      <td>{order.clientCity || "-"}</td>
      <td>
        <span>
          <span className="flexColumn">
            <span className="lh1">{order?.commercialName ?? "-"}</span>
          </span>
        </span>
      </td>
      {hasPermission(isCommercial, role, ["action.orders.delete"]) && (
        <td>
          <DeleteDispatchOrder
            button={<AiOutlineDelete className="pointer" />}
            orderNumber={order.subPurchaseNumber}
            orderId={order.id}
            onDone={() => mutate()}
          />
        </td>
      )}
    </tr>
  );
};

export default TableOrders;
