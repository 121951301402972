import { getProductById, searchProductsV4, TProduct } from "@/http";
import { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { InputV2 } from "../Input/InputV2";
import { useTranslation } from "react-i18next";
import { SingleMenu } from "../Menu/SingleMenu";
import { FaChevronDown } from "react-icons/fa";
import { FiBox, FiTag, FiToggleRight } from "react-icons/fi";
import { useSupplierBrands } from "@/hooks/useSupplier";
import ProductImage from "@/components/layout/ProductImage";
import { Card } from "../Card/Card";
import { useFormContext } from "react-hook-form";

type TFilterConditions = {
  supplierId: string;
  status?: boolean;
  hasStock?: boolean;
  segment?: string;
  canCreditMemo?: boolean;
  warehouseId?: string;
};

type InputProps = {
  filterConditions: TFilterConditions;
  lockedProductIds?: string[];
  onSelect: (value: TProduct) => void;
  infoLabel?: ReactNode;
};

type TSort = {
  field: string;
  sort: "asc" | "desc";
};

type TSearchQuery = {
  supplierId: string;
  text?: string;
  hasStock?: boolean;
  status?: boolean;
  segment?: string;
  canCreditMemo?: boolean;
  warehouseId?: string;
  brandId: string[];
  categoryId: string[];
  page: 0;
  size: 10;
  sort: TSort;
};

export const ProductSearch = ({
  filterConditions,
  onSelect,
  lockedProductIds,
  infoLabel,
}: InputProps) => {
  const debounceRefProduct = useRef<any>();
  const { setValue, watch } = useFormContext();
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const allBrands = useSupplierBrands(filterConditions.supplierId);
  const [options, setOptions] = useState<TProduct[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [searchConditions, setSearchConditions] = useState<TSearchQuery>({
    supplierId: filterConditions.supplierId,
    hasStock: filterConditions.hasStock,
    status: filterConditions.status,
    segment: filterConditions.segment,
    warehouseId: filterConditions.warehouseId,
    canCreditMemo: filterConditions.canCreditMemo,
    brandId: [],
    categoryId: [],
    page: 0,
    size: 10,
    sort: {
      field: "featured",
      sort: "asc",
    },
  });

  const setProduct = async (product: TProduct) => {
    getProductById(product.id, filterConditions.segment).then(res => {
      onSelect(res);
      setOptions([]);
      setValue("searchProductText", null);
      setSearchText("");
    });
  };

  const buildOptionDetail = (product: TProduct) => {
    let detail = "";

    if (product.brand?.name && product.brand?.name != "-") {
      detail = `${t("BRAND")}: ${product.brand.name}`;
    }

    if (product.sku) {
      detail = `${detail} sku: ${product.sku}`;
    }

    if (product.barCode) {
      detail = `${detail} upc: ${product.barCode}`;
    }

    return detail;
  };

  const productErrors = (product: TProduct) => {
    const errors = [];

    if (lockedProductIds?.includes(product.id)) {
      errors.push("PRODUCT_LOADED");
    }

    if (
      filterConditions.status !== undefined &&
      filterConditions.status != product.status
    ) {
      errors.push("PRODUCT_DISABLED");
    }
    const productValidStock = product.availableWarehouses
      .filter(p =>
        filterConditions.warehouseId !== undefined
          ? p.warehouseId === filterConditions.warehouseId
          : true
      )
      .filter(w => w.availableStock + (w.overStockAmount || 0) >= 0).length;

    if (filterConditions.hasStock && !productValidStock) {
      errors.push("PRODUCT_WO_STOCK");
    }

    if (product.type === "GROUP" && watch("purchaseType") !== "QUOTE") {
      errors.push("PRODUCT_FOR_QUOTES");
    }

    return errors;
  };

  useEffect(() => {
    if (searchText) {
      if (debounceRefProduct.current) {
        clearTimeout(debounceRefProduct.current);
      }
      debounceRefProduct.current = setTimeout(() => {
        searchProductsV4({
          data: { ...searchConditions, text: searchText },
        }).then(res => {
          setOptions(res.content);
        });
      }, 250);
    }
  }, [searchText, searchConditions]);

  const stockOptions = [
    { value: undefined, label: tGlobal("ALL") },
    { value: true, label: tGlobal("WITH_STOCK") },
    { value: false, label: tGlobal("WITHOUT_STOCK") },
  ];

  const statusOptions = [
    { value: undefined, label: tGlobal("ALL") },

    { value: true, label: tGlobal("ENABLED") },
    { value: false, label: tGlobal("DISABLED") },
  ];

  const brandOptions = [
    { value: null, label: t("ALL_BRANDS") },
    ...(allBrands ?? []).map(b => ({
      value: b,
      label: b.name,
    })),
  ];

  return (
    <>
      <Card
        contentBottom={
          <>
            <div className="flexGap centerAlign">
              <div className="col-6 col-sm-12">
                <InputV2
                  name={"searchProductText"}
                  label=""
                  type="text"
                  placeholder={"🔍  " + t("ORDER_SELECT_PRODUCTS")}
                  inputBG="white"
                  onChange={e => setSearchText(e.target.value)}
                />
              </div>
            </div>
            {infoLabel && (
              <div className="flexGap centerAlign pb1">{infoLabel}</div>
            )}
          </>
        }
        className="noPadding"
      />

      {searchText && (
        <div className="flexGap centerAlign">
          <div className="col-12 col-sm-12">
            <div className="contentInline mbmd pw1">
              <div className="mdFont">
                <span>{t("RESULTS_SHOW")}</span>
                <span className="bold">{` "${searchText}"`}</span>
              </div>
              <div className="flexGap">
                <SingleMenu
                  hideOnSelect
                  align="rigth"
                  label={
                    <span className="mdFont flexGap center">
                      <FiTag />
                      <span>
                        {
                          brandOptions?.find(
                            b => b.value?.id === searchConditions.brandId[0]
                          )?.label
                        }
                      </span>
                      <FaChevronDown size={10} />
                    </span>
                  }
                  options={brandOptions}
                  onSelect={value =>
                    setSearchConditions({
                      ...searchConditions,
                      brandId: value?.id ? [value.id] : [],
                    })
                  }
                />

                <SingleMenu
                  hideOnSelect
                  align="rigth"
                  label={
                    <span className="mdFont flexGap center">
                      <FiBox />
                      <span>
                        {
                          stockOptions.find(
                            s => s.value === searchConditions.hasStock
                          )?.label
                        }
                      </span>
                      <FaChevronDown size={10} />
                    </span>
                  }
                  options={stockOptions}
                  onSelect={value =>
                    setSearchConditions({
                      ...searchConditions,
                      hasStock: value,
                    })
                  }
                />

                <SingleMenu
                  hideOnSelect
                  align="rigth"
                  label={
                    <span className="mdFont flexGap center">
                      <FiToggleRight />
                      <span>
                        {
                          statusOptions.find(
                            s => s.value === searchConditions.status
                          )?.label
                        }
                      </span>
                      <FaChevronDown size={10} />
                    </span>
                  }
                  options={statusOptions}
                  onSelect={value =>
                    setSearchConditions({
                      ...searchConditions,
                      status: value,
                    })
                  }
                />
              </div>
            </div>
            <Card className="noPadding">
              <div className={styles.list}>
                <ul>
                  {options?.map((option: any) => {
                    return (
                      <li
                        className={
                          productErrors(option)?.length > 0
                            ? styles.disabledProduct
                            : ""
                        }
                        key={option?.id}
                        data-testid={`${option?.id}-option`}
                        onClick={() => {
                          if (productErrors(option)?.length === 0) {
                            setProduct(option);
                          }
                        }}
                      >
                        <div>
                          <ProductImage images={option.media ?? []} />
                        </div>
                        <div className={styles.description}>
                          <div className="flexColumn">
                            <span>{option?.name}</span>
                            {productErrors(option)?.map(error => {
                              return (
                                <span
                                  key={`${option?.id}-${error}`}
                                  className={styles.sku}
                                >
                                  * {tGlobal(error)}
                                  <br />
                                </span>
                              );
                            })}
                          </div>
                          <span className={`${styles.sku} bold`}>
                            {buildOptionDetail(option)}
                          </span>
                        </div>
                      </li>
                    );
                  })}
                  {!options?.length && <li>{t("NO_OPTIONS")}</li>}
                </ul>
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};
