import clsx from "clsx";
import { ReactNode } from "react";
import styles from "./styles.module.scss";

export type CardProps = {
  children: ReactNode;
  className?: string;
};
export function IntegrationCard({ children, className }: CardProps) {
  return <div className={clsx(styles.card, className)}>{children}</div>;
}
