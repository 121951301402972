import { Card } from "@/components/shared/Card/Card";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { getSupplierProductOptions } from "@/http";
import useUrlState from "@ahooksjs/use-url-state";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { DeleteProductOption } from "./DeleteProductOption";
import { ProductOptionModal } from "./ProductOptionModal";
import styles from "./styles.module.scss";

export const OptionsSection = () => {
  const [t] = useTranslation("global");
  const [{ page }] = useUrlState({ page: "0" });

  const params: any = {
    size: 10,
    page,
    sort: "createdAt,desc",
  };

  const swrData = useSWR(
    [params, "product-options-list"],
    getSupplierProductOptions
  );
  const { data: options, mutate } = swrData;

  return (
    <Card>
      <p className="bolder contentInline">
        <span>{t("PRODUCT_OPTIONS")}</span>
        <ProductOptionModal onDone={() => mutate()} />
      </p>
      <div className="spacer" />
      <div className="scrollTable">
        <PaginatedTable swrData={swrData}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("NAME")}</th>
                <th>{t("TYPE")}</th>
                <th>{t("OPTIONS")}</th>
                <th>{t("ACTIONS")}</th>
              </tr>
            </thead>
            <tbody>
              {options?.content?.map((o: any) => (
                <tr key={o?.id} className={styles.row}>
                  <td className="textLine">{o?.name ?? "-"}</td>
                  <td>{o?.type ?? "-"}</td>
                  <td>{(o?.options || []).join(", ") ?? "-"}</td>
                  <td>
                    <div className="flexGap centerAlign">
                      <ProductOptionModal
                        onDone={() => mutate()}
                        productOption={o}
                      />
                      <DeleteProductOption
                        onDone={() => mutate()}
                        productOption={o}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PaginatedTable>
      </div>
    </Card>
  );
};
