import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { updateSupplier } from "@/http";
import { showToastError } from "@/hooks/useToast";
import { MdOutlineEdit } from "react-icons/md";
import clsx from "clsx";

export const ProfileCurrency = () => {
  const [t] = useTranslation("global");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { user, refreshUserData } = useAuth();
  const defaultValues = { currency: user.currency };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      await updateSupplier(user.id, {
        currency: values.currency,
      } as any);
      await refreshUserData();
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      setOpen(false);
      showToastError(error);
    }
  };

  useEffect(() => {
    refreshUserData();
  }, []);

  return (
    <>
      <p className="bold">{t("CURRENCY")}</p>
      {isOpen ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input
                className={clsx(styles.input, {
                  [styles.required]: !!errors.currency,
                })}
                disabled={isLoading}
                defaultValue={user.currency}
                {...register("currency", {
                  required: t("LOGIN.FIELD_REQUIRED"),
                })}
              />
              <br />
              <span className={styles.errorsLabel}>
                {errors.currency && errors.currency.message}
              </span>
            </div>
            <div className={styles.actions}>
              <button
                disabled={isLoading}
                className="primary"
                onClick={handleSubmit(onSubmit)}
              >
                {t("ACCOUNT.ADDRESSES.SAVE")}
              </button>
              <button
                onClick={() => {
                  setOpen(false);
                  reset();
                }}
              >
                {t("ACCOUNT.ADDRESSES.CANCEL")}
              </button>
            </div>
          </form>
        </>
      ) : (
        <p className="flexGap center">
          {user?.currency || "-"}
          <span className="pointer circleButton" onClick={() => setOpen(true)}>
            <MdOutlineEdit />
          </span>
        </p>
      )}
      <div className={styles.spacer} />
    </>
  );
};
