import ProductImage from "@/components/layout/ProductImage";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { TSupplier } from "@/http";
import { ReactNode, useState } from "react";
import styles from "./styles.module.scss";
import { productTypeChoicesRead } from "@/constants/products";
import {
  CreateProcurement,
  SupplierWarehouseStockTable,
} from "./CreateProcurement";
import { useProducts } from "@/store/products";
import { VariantSwitchStatus } from "./VariantSwitchStatus";
import { TSupplierVariantPP } from "@/models/order-details";
import { useNavigate } from "react-router";
import { hasPermission } from "@/utils/validations/permissions";
import { CheckboxButton } from "@/components/shared/Input/CheckboxButton";
import { useSearchParams } from "react-router-dom";

type TProductRow = {
  product: any;
};
export const ProductRow = ({ product }: TProductRow) => {
  const [search] = useSearchParams();
  const tabCurrentFilter = search.get("tab");
  const { user, isCommercial, role } = useAuth();
  const [variant, setVariant] = useState(product);
  const navigate = useNavigate();
  const disabledSwitch = !hasPermission(isCommercial, role, [
    "action.products.status",
  ]);

  const [supplierProduct, setSupplierProduct] = useState(
    product?.suppliers?.find(
      ({ supplierId }: TSupplier) => supplierId === user.id
    ) || {}
  );
  const { setOpenModal, selectedProducts, setSelectedProducts } = useProducts();

  const onEditVariant = () => {
    navigate(`/products/${variant.id}`);
  };

  const onChangeStatusVariant = (newProduct: TSupplierVariantPP) => {
    const newVariant = { ...variant };
    setSupplierProduct(newProduct);

    const indexSupplierProduct = newVariant?.suppliers.findIndex(
      ({ supplierId }: TSupplier) => supplierId === user.id
    );

    if (indexSupplierProduct >= 0) {
      newVariant!.suppliers[indexSupplierProduct] = newProduct;
      setVariant(newVariant);
    }
  };

  const CustomTD = ({ children }: { children: ReactNode }): JSX.Element => {
    return (
      <td onClick={onEditVariant} className="textLine">
        {children}
      </td>
    );
  };

  return (
    <tr key={variant.id} className={`${styles.row} pointer`}>
      <td>
        <CheckboxButton
          data-testid={variant.id}
          checked={Boolean(selectedProducts[supplierProduct.id])}
          onChange={() => {
            setOpenModal(false);
            setSelectedProducts(supplierProduct);
          }}
        />
      </td>

      <CustomTD>
        <ProductImage images={variant.media} />
      </CustomTD>
      <CustomTD>{variant.name}</CustomTD>
      <CustomTD>
        {supplierProduct?.wholesalePrice && !["GIFT"].includes(variant.type)
          ? formatCurrency(supplierProduct?.wholesalePrice)
          : "-"}
      </CustomTD>

      <td>
        <div className={styles.procurement}>
          {supplierProduct?.warehouses?.length > 0 ? (
            <ToolTip
              title={
                <div className="pw1">
                  <SupplierWarehouseStockTable
                    warehouses={supplierProduct?.warehouses ?? []}
                    includeTitle
                  />
                </div>
              }
              position="Left"
              width="19rem"
            >
              <span
                className={`${
                  supplierProduct?.availableStock < 0 ? "redText" : ""
                }`}
              >
                {tabCurrentFilter
                  ? supplierProduct?.warehouses?.find(
                      (w: any) => w.warehouseId === tabCurrentFilter
                    )?.availableStock ?? 0
                  : supplierProduct?.availableStock?.toFixed?.(2)}
              </span>
            </ToolTip>
          ) : (
            <span
              className={`${
                supplierProduct?.availableStock < 0 ? "redText" : ""
              }`}
            >
              {supplierProduct?.availableStock?.toFixed?.(2)}
            </span>
          )}
          {variant.type !== "KIT" && (
            <CreateProcurement
              onDone={newVariant => {
                setVariant({ ...variant, suppliers: newVariant.suppliers });
                setSupplierProduct(
                  newVariant?.suppliers?.find(
                    ({ supplierId }: TSupplier) => supplierId === user.id
                  ) || {}
                );
              }}
              type={productTypeChoicesRead[variant?.type]}
              variant={supplierProduct}
            />
          )}
        </div>
      </td>
      <td>
        {!["GIFT", "VARIANT"].includes(variant.type) ? (
          <VariantSwitchStatus
            variant={supplierProduct}
            variantStatus={variant.approvedStatus}
            onDone={onChangeStatusVariant}
            property="status"
            disabled={disabledSwitch}
          />
        ) : (
          "-"
        )}
      </td>
      <td>
        {!["GIFT", "VARIANT"].includes(variant.type) ? (
          <VariantSwitchStatus
            variant={supplierProduct}
            variantStatus={variant.approvedStatus}
            onDone={onChangeStatusVariant}
            property="isMarketplace"
            disabled={disabledSwitch}
          />
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
};
