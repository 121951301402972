import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { menuItems as baseMenuItems } from "@/constants/menu-items";
import SideBarItem from "./SideBarItem";
import { useAuth } from "@/store/auth";
import styles from "./styles.module.scss";
import { CURRENT_COUNTRY } from "@/constants/core";
import { hasPermission } from "@/utils/validations/permissions";
import { useSideBarStatus } from "@/store/ui";
import { CopyClipboard } from "@/components/shared/CopyClipboard/CopyClipboard";
import { isProd } from "@/constants/env";
import { AccountMenu } from "./AccountMenu";
import { SubscriptionLink } from "./SubscriptionLink";
import { PremiumIcon } from "@/lib/subscriptions/components/icons/Premium";
import { useSubscription } from "@/hooks/useSubscription";
import { FEAT_CATALOG } from "@/constants/features";
import { LuArrowLeftToLine, LuArrowRightToLine } from "react-icons/lu";
import { onboardingSelectors } from "@/components/onboarding/selectors";
import { createPortal } from "react-dom";
import { Version } from "@/components/app/Version";
import { FiExternalLink } from "react-icons/fi";
import React from "react";
import { Supplier } from "@/models/supplier";
import { LinkItemProps } from "@/models/side-bar";

const supplierConfigHide = (supplier: Supplier, menuItems: LinkItemProps[]) => {
  if (!supplier?.showCurves) {
    menuItems.forEach(element => {
      if (element.name === "products") {
        element.subItems = [];
      }
    });
  }

  return menuItems;
};

export const SidebarContent = ({ onClick }: { onClick: any }) => {
  const [t] = useTranslation("global");
  const { isCommercial, role, user } = useAuth();
  const { isSideBarCollapsed } = useSideBarStatus();
  const catalogRootDomain = isProd ? "seeri.store" : "dev.seeri.co";
  const urlCatalog = `https://${user.slug}.${catalogRootDomain}`;
  const menuItems = supplierConfigHide(user, baseMenuItems);

  return (
    <div id="sidebar" className={styles.sidebarContent}>
      <div className={styles.collapseButtonWrapper}></div>
      <div className={styles.image}>
        <img
          src={
            isSideBarCollapsed ? "/logo-purple-mini.png" : "/logo-purple.png"
          }
          alt="Seeri logo"
          height="20px"
        />
      </div>
      <div className="spacer" />
      <div className="pw1">
        <div
          data-onboarding={onboardingSelectors.catalogLink}
          className={`${styles.sidebarItem} contentInline`}
        >
          <CatalogLink />
          <div className={isSideBarCollapsed ? styles.hide : "purpleText"}>
            <CopyClipboard toCopy={urlCatalog} hideTooltip />
          </div>
        </div>
      </div>

      <div className="pw1">
        <div className={styles.sectionIndicator}>
          {isSideBarCollapsed ? null : t("MENU")}
        </div>

        {menuItems
          .filter(item => item.activeCountry.includes(CURRENT_COUNTRY))
          .map(link =>
            hasPermission(isCommercial, role, link.permission ?? []) ? (
              <React.Fragment key={link.name}>
                <SideBarItem
                  name={link.name}
                  icon={link.icon}
                  link={link.link}
                  onClick={onClick}
                  subItems={link.subItems}
                  feature={link.feature}
                >
                  {link?.label ?? t(`SIDEBAR.${link.name.toUpperCase()}`)}
                </SideBarItem>
              </React.Fragment>
            ) : null
          )}
      </div>
      <div className={clsx(styles.userActions, "pw1")}>
        <div className={styles.sectionIndicator}>
          {isSideBarCollapsed ? null : t("GENERAL")}
        </div>

        <SubscriptionLink onClick={onClick} />
        <AccountMenu onClick={onClick} />
        <div className="spacer" />
        <div className="flexGap centerAlign">
          <Version />
        </div>
      </div>
    </div>
  );
};

const CatalogLink = () => {
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const { isBasicFeature } = useSubscription();
  const { isSideBarCollapsed } = useSideBarStatus();
  const catalogRootDomain = isProd ? "seeri.store" : "dev.seeri.co";
  const urlCatalog = `https://${user.slug}.${catalogRootDomain}`;

  return (
    <a
      href={urlCatalog}
      target="_blank"
      rel="noreferrer"
      className={clsx(
        "pointer flexGap textLine",
        !isBasicFeature(FEAT_CATALOG) && styles.premium
      )}
    >
      <span className={styles.icon}>
        <FiExternalLink size={"1.15rem"} />
        {!isBasicFeature(FEAT_CATALOG) && (
          <PremiumIcon width={10} height={10} className={styles.premiumIcon} />
        )}
      </span>
      <div className={clsx(isSideBarCollapsed ? styles.hide : "", "ml1")}>
        {t("CATALOG")}
      </div>
    </a>
  );
};

const Sidebar = () => {
  const { isSideBarCollapsed, setSideBarCollapsed } = useSideBarStatus();

  return (
    <>
      <div
        className={`${styles.sidebar} ${isSideBarCollapsed ? "" : styles.open}`}
      >
        <SidebarContent
          onClick={() => {
            return;
          }}
        />
      </div>
      {createPortal(
        <div
          className={clsx(styles.collapseButton, {
            [styles.closed]: isSideBarCollapsed,
          })}
        >
          <button onClick={() => setSideBarCollapsed(!isSideBarCollapsed)}>
            {isSideBarCollapsed ? (
              <LuArrowRightToLine />
            ) : (
              <LuArrowLeftToLine />
            )}
          </button>
        </div>,
        document.body
      )}
    </>
  );
};

export default Sidebar;
