import ProductImage from "@/components/layout/ProductImage";
import {
  CreateProcurement,
  SupplierWarehouseStockTable,
} from "@/components/products/ProductsTable/CreateProcurement";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { Modal } from "@/components/shared/Modal/Modal";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { WarehouseName } from "@/components/shared/WarehouseName";
import { productTypeChoicesRead } from "@/constants/products";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createPurchaseFromQuote, getProductsbyIds } from "@/http";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { getProductSupplierFromProduct } from "@/utils/products";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type TCreatePurchase = {
  purchaseId: string;
  purchaseProducts: any[];
};
export const CreatePurchaseFromQuote = ({
  purchaseId,
  purchaseProducts,
}: TCreatePurchase) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [products, setProducts] = useState<any[]>([]);
  const { getPurchase } = usePurchase();
  const [t] = useTranslation("purchases");
  const methods = useForm({ mode: "onChange" });
  const isFormValid = methods.formState.isValid;

  const handleClick = async (values: any) => {
    try {
      console.log("values", values);
      setIsLoading(true);
      await createPurchaseFromQuote(purchaseId, values);
      getPurchase(purchaseId);
      setIsOpen(false);
      showToastSuccess(t("PURCHASE_CREATED"));
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadProducts = () => {
    setIsLoadingProducts(true);
    const productIds = purchaseProducts.map(({ id }) => id);
    getProductsbyIds(productIds)
      .then(data => {
        const fixedProducts = purchaseProducts.map(purchaseProduct => {
          const product = data.find(d => d.id === purchaseProduct.id);
          return {
            ...product,
            warehouses: product?.availableWarehouses,
            orderProductId: purchaseProduct?.orderProductId,
            quantity: purchaseProduct?.quantity ?? 0,
            unitType: purchaseProduct?.unitType ?? "",
            unitRate: purchaseProduct?.unitRate
              ? Number(purchaseProduct?.unitRate)
              : 1,
          };
        });

        setProducts(fixedProducts);
        methods.setValue(
          "products",
          fixedProducts?.map(p => ({
            id: p.id,
            warehouseId: "",
            orderProductId: p.orderProductId,
          }))
        );
      })
      .catch(() => setProducts([]))
      .finally(() => setIsLoadingProducts(false));
  };

  useEffect(() => {
    if (purchaseProducts?.length && isOpen) {
      loadProducts();
    }
  }, [isOpen]);

  return (
    <>
      <button className="primary" onClick={() => setIsOpen(true)}>
        {t("CREATE")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t("SURE_PURCHASE_CREATE")}
        footer={
          <>
            <button
              disabled={isLoading || isLoadingProducts}
              onClick={() => setIsOpen(false)}
            >
              {t("CANCEL")}
            </button>
            <button
              disabled={isLoading || isLoadingProducts || !isFormValid}
              className="primary lg"
              onClick={methods.handleSubmit(handleClick)}
            >
              {t("ACTIONS.CREATE")}
            </button>
          </>
        }
      >
        {isLoadingProducts ? (
          <Spinner />
        ) : (
          <FormProvider {...methods}>
            <QuoteProducts products={products} />
          </FormProvider>
        )}
      </Modal>
    </>
  );
};

const QuoteProducts = ({ products }: { products: any[] }) => {
  const [t] = useTranslation("purchases");

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th />
            <th>{t("NAME")}</th>
            <th>{t("QUANTITY")}</th>
            <th>{t("UNIT_TYPE")}</th>
            <th>{t("AVAILABLE")}</th>
            <th>{t("WAREHOUSE")}</th>
          </tr>
        </thead>
        <tbody>
          {products.map((p: any, idx: number) => (
            <QuoteProductsRow key={idx} product={p} index={idx} />
          ))}
        </tbody>
      </table>
    </>
  );
};

const QuoteProductsRow = ({
  product,
  index,
}: {
  product: any;
  index: number;
}) => {
  const [images] = useState(product?.images ?? []);
  const [name] = useState(product?.name ?? "");
  const [quantity] = useState(product?.quantity);
  const [unitType] = useState(product?.unitType);
  const [unitRate] = useState(product?.unitRate);
  const [variant, setVariant] = useState(product);
  const [t] = useTranslation("purchases");
  const { user } = useAuth();
  const { trigger } = useFormContext();
  const methods = useFormContext();
  console.log("product", product);
  const updateVariant = (data: any) => {
    const variantUpdated = getProductSupplierFromProduct(data, user.id);
    setVariant({ ...product, ...variantUpdated });
    trigger(`products[${index}].warehouseId`);
  };

  return (
    <tr className={styles.row}>
      <td>
        <ProductImage images={images} />
      </td>
      <td>{name}</td>
      <td>{quantity}</td>
      <td>{unitType}</td>
      <td className="textLine">
        {variant?.warehouses?.length > 0 ? (
          <ToolTip
            title={
              <div className="pw1">
                <SupplierWarehouseStockTable
                  warehouses={variant?.warehouses ?? []}
                  includeTitle
                  unitRate={unitRate}
                  overStock
                />
              </div>
            }
            position="Left"
            width="19rem"
          >
            <span className={`${variant?.availableStock < 0 ? "redText" : ""}`}>
              {(variant?.availableStock / unitRate)?.toFixed(2)}
            </span>
          </ToolTip>
        ) : (
          <span className={`${variant?.availableStock < 0 ? "redText" : ""}`}>
            {(variant?.availableStock / unitRate)?.toFixed(2)}
          </span>
        )}
        <CreateProcurement
          type={productTypeChoicesRead["PRODUCT"]}
          variant={variant}
          onDone={updateVariant}
        />
      </td>
      <td className={styles.inputContainer}>
        <input
          type="hidden"
          value={variant?.orderProductId}
          {...methods.register(`products[${index}].orderProductId`)}
        />
        <SelectV2
          name={`products[${index}].warehouseId`}
          label=""
          required
          disabled={variant?.availableStock <= 0}
          choices={variant?.warehouses?.map((w: any) => ({
            value: w?.warehouseId,
            label: <WarehouseName id={w?.warehouseId} useOnlyName />,
          }))}
          validate={(warehouseId: string) => {
            const warehouse = variant?.warehouses?.find(
              (w: any) => w?.warehouseId === warehouseId
            );
            return (
              (unitRate !== 1
                ? Math.floor(
                    Number(warehouse?.availableStock ?? 0) / unitRate +
                      (warehouse?.overStock
                        ? Number(warehouse?.overStockAmount ?? 1000000) /
                          unitRate
                        : 0)
                  )
                : Number(warehouse?.availableStock ?? 0) +
                  (warehouse?.overStock
                    ? Number(warehouse?.overStockAmount ?? 1000000)
                    : 0)) >= quantity
            );
          }}
          validMessage={t("WAREHOUSE_NOT_VALID_QUANTITY")}
        />
      </td>
    </tr>
  );
};
