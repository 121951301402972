import { useCompletePurchase } from "@/hooks/usePurchaseProducts";
import { TSupplierOrderDetails } from "@/models/order-details";
import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useCallback, useEffect } from "react";
import { TProduct } from "@/http";
import clsx from "clsx";
import { get } from "lodash-es";
import { useForm } from "react-hook-form";
import { SaleCurveDetail } from "./SaleCurveDetail/SaleCurveDetail";
import { ProductDetailTableType } from "./ProducDetailTableType";

type props = {
  isCreation?: boolean;
  product: any;
  editCurves: boolean;
  orderDetail?: TSupplierOrderDetails | undefined;
  tableColumns: ProductDetailTableType[];
  idx: number;
};

export const ProductDetailsRow = ({
  product,
  editCurves = false,
  orderDetail,
  tableColumns,
  idx,
}: props) => {
  const { user } = useAuth();

  const getDiscount = useCallback(
    (product: any) => {
      return (
        (product?.wholesalePrice || 0) * (product?.quantity || 0) -
        (product.subTotalWithDiscount || 0)
      );
    },
    [user.id]
  );

  const getDiscountPercentage = useCallback(
    (product: any) => {
      const discountTotal = getDiscount(product);
      const discountPercentage =
        discountTotal /
        ((product?.wholesalePrice || 0) * (product?.quantity || 0));

      const finalDiscount = (discountPercentage || 0) * 100;

      return Math.floor(finalDiscount * 100) / 100;
    },
    [user.id]
  );

  const tableRows: Record<
    string,
    (product: TProduct, idx: number) => JSX.Element
  > = {
    "unit-type": (product: TProduct) => {
      return <>{product?.unitType ?? "-"}</>;
    },
    serials: (product: TProduct) => {
      return (
        <ProductSerial
          product={product}
          purchaseId={orderDetail?.purchaseId || orderDetail?.id || ""}
        />
      );
    },
    quantity: (product: TProduct) => {
      return <>{product.quantity}</>;
    },
    warehouse: (product: TProduct) => {
      return (
        <>{user?.addresses?.find(a => a.id === product.warehouseId)?.name}</>
      );
    },
    "unit-price": (product: TProduct) => {
      return <>{formatCurrency(product?.wholesalePrice || 0)}</>;
    },
    "unit-discount": (product: TProduct) => {
      return <>{getDiscountPercentage(product)} %</>;
    },
  };

  return (
    <>
      <tr
        className={clsx(
          {
            [styles.noBorderBottom]: product?.curves?.length > 0,
          },
          styles.productsTableRow
        )}
      >
        {tableColumns.map((column: ProductDetailTableType) => {
          if (!column.visible) {
            return null;
          }

          return (
            <td key={column.key} className={clsx(styles.row, column.className)}>
              {column?.render && column?.render(product, idx)}
              {!column?.render && tableRows[column.key]?.(product, idx)}

              {}
            </td>
          );
        })}
      </tr>

      {product?.type === "GROUP" && (
        <tr className={styles.productsTableRow}>
          <td colSpan={tableColumns.length}>
            <div>
              <SaleCurveDetail
                curves={product?.curves ?? []}
                unitPrice={product.wholesalePrice || 0}
                editCurves={editCurves}
                productId={product?.id ?? ""}
              />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const ProductSerial = ({
  product,
  purchaseId,
}: {
  product: any;
  purchaseId: string;
}) => {
  const fullPurchase = useCompletePurchase(purchaseId);
  const serials = get(product, "serials", []).map((s: any) => s.id);
  const [t] = useTranslation("global");

  const methods = useForm({ defaultValues: { serials } });

  useEffect(() => {
    methods.setValue(
      "serials",
      get(product, "serials", []).map((s: any) => s.id)
    );
  }, [fullPurchase]);

  return (
    <div className={styles.serialCell}>
      <div className="flexGap center">
        <div className="col-12 noPadding">
          <div className={clsx("flexColumn", styles.serialsInfo)}>
            {Array.from({ length: product?.quantity || 0 }, (_, index) => (
              <div key={index} className="flexGap gapSm mdFont">
                <span>#{index + 1}:</span>
                <span>
                  {get(product, `serials[${index}].serialNumber`) || (
                    <span className="redText">{t("NO_SERIAL")}</span>
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
