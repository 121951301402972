import { TRestListData, TSeller, TSellerAddress } from "@/http";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BiCheck } from "react-icons/bi";
import { AddressModal } from "./AddressModal";
import { useRecordContext } from "./ui/RecordContext";
import { Table, TableCell } from "./ui/Table/Table";
import AddressDelete from "./Addresses/AddressDelete/AddressDelete";

type AddressTableProps = {
  list: TRestListData<TSellerAddress>;
  page: number;
  onPageChange: (page: number) => void;
  refresh: () => void;
};

export const AddressTable: FC<AddressTableProps> = ({ list, refresh }) => {
  const client = useRecordContext<TSeller>();
  const [t] = useTranslation("global");

  return (
    <>
      <Table records={list.content}>
        <TableCell label={t("CLIENTS.COUNTRY")} source="country" />
        <TableCell label={t("CLIENTS.STATE")} source="state" />
        <TableCell label={t("CLIENTS.CITY")} source="city" />
        <TableCell label={t("CLIENTS.POSTAL_CODE")} source="postalCode" />
        <TableCell label={t("CLIENTS.ADDRESS")} source="addressLine1" />
        <TableCell
          label={t("CLIENTS.IS_MAIN")}
          source="isMain"
          render={address => (address.isMain ? <BiCheck /> : "-")}
        />
        <TableCell
          label={t("ACTIONS")}
          render={(address: TSellerAddress) => (
            <>
              <div className="flexGap">
                <AddressModal
                  iconButton
                  clientId={client.id}
                  address={address}
                  onDone={() => refresh()}
                />
                <AddressDelete address={address} onDone={() => refresh()} />
              </div>
            </>
          )}
        />
      </Table>
    </>
  );
};
