import { Card } from "@/components/shared/Card/Card";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { getSegments, getSupplierProductOptions } from "@/http";
import useUrlState from "@ahooksjs/use-url-state";
import React from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { CurveProduct } from "./CurveProduct";

export const CurvesSection = () => {
  const [t] = useTranslation("global");
  const [{ curvesPage }] = useUrlState({ curvesPage: "0" });
  const { data: segments = [] } = useSWR("segments", getSegments);

  const params: any = {
    size: 10,
    page: curvesPage,
    sort: "createdAt,desc",
  };

  const swrData = useSWR(
    [params, "product-options-list"],
    getSupplierProductOptions
  );

  const { data: options, isValidating } = swrData;

  return (
    <Card>
      <p className="bolder contentInline">
        <span>{t("PRODUCT_CURVES")}</span>
      </p>
      <div className="spacer" />
      <>
        <PaginatedTable swrData={swrData} paginationKey="curvesPage">
          {isValidating
            ? null
            : options?.content.map((o: any, i: number) => (
                <React.Fragment key={o?.id}>
                  <CurveProduct id={o?.id} segments={segments} />
                  {options.content.length !== i + 1 && (
                    <div className="spacer" />
                  )}
                </React.Fragment>
              ))}
        </PaginatedTable>
      </>
    </Card>
  );
};
