import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { isUSA } from "@/constants/core";
import { getPurchases, getSuppliersByIdsV2 } from "@/http";
import { useAuth } from "@/store/auth";
import { hasPermission, isAdmin } from "@/utils/validations/permissions";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { PurchaseListRow } from "./PurchaseListRow";
import styles from "./styles.module.scss";
import { useMemo } from "react";

type props = {
  type: "SELL_IN" | "SELL_OUT" | "QUOTATION";
  sellerId?: string;
};

export const PurchaseListTable = ({ type, sellerId }: props) => {
  const [t] = useTranslation("purchases");
  const [search] = useSearchParams(
    "page=0&sortBy=createdAt&sortDirection=DESC"
  );
  const currentPage = +search.get("page")!;
  const supplierIdFilter = search.get("supplierId");
  const searchFilter = search.get("filter");
  const sortBy = search.get("sortBy");
  const sortDirection = search.get("sortDirection");
  const statusFilters = JSON.parse(search.get("filterStatus") || "{}");
  const supplierFilter = search.get("supplier");
  const startDate = search.get("startDate");
  const endDate = search.get("endDate");

  const { isCommercial, commercial, role } = useAuth();
  const isSale = type === "SELL_OUT";

  const hasMultiplePermissions = hasPermission(isCommercial, role, [
    "action.purchase.show-all-purchases",
  ]);

  const swrData = useSWR(
    {
      key: `clients${currentPage}${searchFilter}${supplierIdFilter}${sortBy}${sortDirection}${type}${statusFilters}${supplierFilter}${startDate}${endDate}`,
      currentPage,
      supplierIdFilter,
      sortBy,
      sortDirection,
      statusFilters,
      supplierFilter,
    },
    ({ currentPage: page }) => {
      const commercialId = commercial?.id;
      const showAllPurchases =
        hasMultiplePermissions || isAdmin(isCommercial, role);

      const filter = supplierIdFilter
        ? ""
        : Object.entries(statusFilters ?? "")
            .filter(([, value]) => value !== "")
            .map(([key, value]) => {
              if (key === "status" && value === "IS_INVOICED") {
                return "hasInvoice:false,status:PENDING;DELIVERED;DONE;IN_TRANSIT";
              }
              return `${key}:${value}`;
            })
            .join(",");

      const purchaseSegment = searchFilter ? `text:${searchFilter}` : "";
      const datesFilter =
        !!startDate && !!endDate
          ? `createdAt>${startDate} 00:00:00,createdAt<${endDate} 23:59:59`
          : "";
      const supplierSegment = supplierFilter
        ? `supplierId:${supplierFilter}`
        : "";
      const commercialSegment =
        !isAdmin(isCommercial, role) && !showAllPurchases
          ? `commercialId:${commercialId}`
          : "";
      const filterSegment = filter.length ? filter : "";

      const typeFilter = `type:${type}`;

      const sellerSegment = sellerId ? `sellerId:${sellerId}` : "";

      const search = [
        sellerSegment,
        datesFilter,
        supplierSegment,
        purchaseSegment,
        commercialSegment,
        filterSegment,
        typeFilter,
      ]
        .filter(Boolean)
        .join(",");

      const purchases = getPurchases({
        size: 10,
        page,
        search,
        sort: `${sortBy},${sortDirection}`,
        slim: "true",
      });

      return purchases;
    }
  );

  const { data: list, error } = swrData;
  const supplierIds = useMemo(
    () => list?.content?.map(s => s.supplierId) || [],
    [list]
  );
  const { data: suppliers } = useSWR(
    supplierIds.length > 0 ? [supplierIds, "supplier-providers-by-ids"] : null,
    getSuppliersByIdsV2
  );

  list?.content.forEach(p => {
    p.supplierName = suppliers?.find(s => s.id === p.supplierId)?.name;
  });

  return (
    <>
      {error ? (
        <>
          <div className="spacer" />
          <div className="spacer" />
          <p className="bold textCenter">{t("ERROR")}</p>
        </>
      ) : !list ? (
        <Spinner />
      ) : (
        <div>
          <PaginatedTable swrData={swrData}>
            <table className={styles.table}>
              <thead>
                <tr>
                  {type === "SELL_OUT" && <th />}
                  <th>{t("CREATED_AT")}</th>
                  <th>
                    {t(
                      type === "QUOTATION" ? "QUOTE_NUMBER" : "PURCHASE_NUMBER"
                    )}
                  </th>

                  {type !== "SELL_IN" && (
                    <>
                      <th className="leftAlign">
                        {t(isUSA ? "LEGAL_NAME" : "CLIENT")}
                      </th>
                    </>
                  )}
                  {type === "SELL_IN" && (
                    <>
                      <th className="leftAlign">{t("SUPPLIER")}</th>
                    </>
                  )}
                  <th>{t("TOTAL")}</th>
                  {type === "SELL_OUT" && (
                    <>
                      <th className={isSale ? "leftAlign" : ""}>
                        {t("PURCHASE_PAYMENT_METHOD")}
                      </th>
                    </>
                  )}

                  {type !== "SELL_IN" && (
                    <>
                      <th>{t("COMMERCIAL")}</th>
                    </>
                  )}

                  {type !== "QUOTATION" && (
                    <>
                      <th className="leftAlign">{t("PURCHASE_STATUS")}</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {list.content.map(purchase => (
                  <PurchaseListRow key={purchase.id} purchase={purchase} />
                ))}
              </tbody>
            </table>
          </PaginatedTable>
        </div>
      )}
    </>
  );
};
