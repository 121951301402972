import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useState } from "react";
import { TProduct } from "@/http";
import clsx from "clsx";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { ProductDetailsRow } from "./ProductDetailsRow";
import { SaleProductRowV2 } from "@/components/purchases/SaleCreation/SaleProductsV2/SaleProductRowV2";
import { Link } from "react-router-dom";
import ProductImage from "@/components/layout/ProductImage";
import { ProductResume } from "./ProductResume";
import { ProductDetailTableType } from "./ProducDetailTableType";
import { formatCurrency } from "@/utils/currency";
import DeleteActionButton from "@/components/clients/ActionButtons/DeleteActionButton";
import UpsertActionButton from "@/components/clients/ActionButtons/UpsertActionButton";
import SaveActionButton from "@/components/clients/ActionButtons/SaveActionButton";
import RolbackActionButton from "@/components/clients/ActionButtons/RollbackActionButton";

type props = {
  orderType?: string;
  currentProduct?: TProduct;
  isCreation?: boolean;
  lightStyle?: boolean;
  products: TProduct[];
  allowEdit?: boolean;
  onEditProduct?: (product: TProduct) => void;
  onDeleteProduct?: (product: TProduct) => void;
  onSaveEdition?: (product: TProduct) => void;
  onRollbackChanges?: (product: TProduct) => void;
};

const ProductsDetailsV2 = ({
  allowEdit = false,
  orderType,
  currentProduct,
  products,
  lightStyle,
  isCreation,
  onEditProduct,
  onDeleteProduct,
  onSaveEdition,
  onRollbackChanges,
}: props) => {
  const [t] = useTranslation("orders-page");
  const [tGlobal] = useTranslation("global");
  const { user, commercial } = useAuth();
  const allowSerials = user.allowSerials;
  const [page, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState<string>();
  const orderProducts = products;

  if (!orderProducts?.length) {
    return null;
  }

  const printUnitTypes = orderProducts.filter(p => !!p.unitType).length > 0;
  const printDiscounts =
    orderProducts.filter(p => p.subtotal !== p.subTotalWithDiscount).length > 0;

  const chunkProducts = (array: any[], size: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  const chunkedProducts = chunkProducts(
    !filter
      ? orderProducts
      : orderProducts?.filter(p =>
          p.name?.toLowerCase().includes(filter?.toLowerCase())
        ),
    100
  );
  const swrData = {
    data: {
      content: chunkedProducts[page],
      totalPages: chunkedProducts.length,
    },
  };

  const tableColumns: ProductDetailTableType[] = [
    {
      key: "product-detail",
      title: t("PRODUCTS_DETAILS.PRODUCT"),
      visible: true,
      width: "auto",
      render: (product: TProduct) => {
        return (
          <div className={clsx(styles.productResume, "flexGap", "noGap")}>
            <div className="col-3 noPadding">
              <Link to={`/products/${product.id}`} target="_blank">
                <ProductImage images={product.images} />
              </Link>
            </div>
            <div className="col-9 noPadding">
              <ProductResume product={product} />
            </div>
          </div>
        );
      },
    },
    {
      key: "warehouse",
      title: tGlobal("WAREHOUSE"),
      visible: orderType === "SELL_OUT" && !commercial?.warehouseId,
      width: "auto",
    },
    {
      key: "serials",
      title: tGlobal("SERIALS"),
      visible: allowSerials && orderType === "SELL_OUT",
      width: "auto",
    },
    {
      key: "delivery-status",
      title: t("FILTER.DELIVERYSTATUS"),
      visible: !isCreation && orderType === "SELL_OUT",
      width: "auto",
      render: (product: TProduct) => {
        return (
          <>
            {product?.providerDeliveryStatusName || product?.deliveryStatus
              ? t(`FILTER.${product.deliveryStatus}`)
              : ""}
          </>
        );
      },
    },
    {
      key: "unit-type",
      title: t("PRODUCTS_DETAILS.UNIT_TYPE"),
      visible: printUnitTypes || (currentProduct?.unitTypes?.length || 0) > 0,
      width: "auto",
    },
    {
      key: "quantity",
      title: t("PRODUCTS_DETAILS.QUANTITY"),
      visible: true,
      width: "auto",
    },
    {
      key: "unit-price",
      title: t("UNIT_PRICE"),
      visible: true,
      width: "auto",
    },
    {
      key: "unit-discount",
      title: t("DISCOUNT") + " (%)",
      visible: Boolean(printDiscounts || isCreation || currentProduct),
      width: "auto",
    },
    {
      key: "final-product-price",
      title: t("TOTAL"),
      visible: true,
      width: "auto",
      render: (product: TProduct, idx: number) => {
        if (
          !product.subTotalWithDiscount ||
          isCreation ||
          product.idx == currentProduct?.idx
        ) {
          const discount = product.discountPercentage || 0;
          const price = (product.quantity || 0) * (product.wholesalePrice || 0);
          const discountValue = (price * discount) / 100;
          product.subTotalWithDiscount = price - discountValue;
        }

        return <>{formatCurrency(product.subTotalWithDiscount)}</>;
      },
    },
    {
      key: "actions",
      title: tGlobal("EDIT"),
      visible: allowEdit,
      width: "auto",
      className: styles.stickyColumn,
      render: (product: TProduct) => {
        return (
          <div className={`${styles.actions} flexGap centerAlign gapSm`}>
            {product.idx !== currentProduct?.idx && (
              <UpsertActionButton
                tooltip={false}
                iconButton
                isEdit={true}
                onAction={() => onEditProduct?.(product)}
              />
            )}
            {product.idx !== currentProduct?.idx && (
              <DeleteActionButton
                tooltip={false}
                iconButton
                onAction={() => onDeleteProduct?.(product)}
              />
            )}

            {product.idx === currentProduct?.idx && isCreation && (
              <DeleteActionButton
                tooltip={false}
                iconButton
                onAction={() => onDeleteProduct?.(product)}
              />
            )}

            {product.idx == currentProduct?.idx && !isCreation && (
              <>
                <RolbackActionButton
                  tooltip={false}
                  iconButton={true}
                  onAction={() => onRollbackChanges?.(product)}
                />
                <SaveActionButton
                  tooltip={false}
                  iconButton={true}
                  onAction={() => onSaveEdition?.(product)}
                />
              </>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className={styles.productsDetails}>
        <div className="flexGap">
          <p className={styles.productsTitle}>{t("PRODUCTS_DETAILS.TITLE")}</p>
          {orderProducts?.length > 50 && (
            <div className={clsx(styles.searchInput)}>
              <input
                autoComplete={"off"}
                type="text"
                placeholder={t("SEARCH")}
                defaultValue={filter ?? ""}
                onChange={e => setFilter(e.target.value)}
              />
            </div>
          )}
        </div>
        <div
          className={clsx(styles.productsTable, {
            [styles.lightStyle]: lightStyle,
          })}
        >
          <div className={clsx(styles.paginatorContainer)}>
            <PaginatedTable
              swrData={swrData}
              setCurrentPage={n => setCurrentPage(n)}
            >
              <table id="products-details-table">
                <thead>
                  <tr className={styles.productsTableHead}>
                    {tableColumns.map(
                      ({ key, title, visible, className }) =>
                        visible && (
                          <th
                            key={key}
                            className={clsx(styles.tableHead, className)}
                          >
                            {title}
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {chunkedProducts[page]?.map(
                    (product: TProduct, index: number) => {
                      if (product.idx === currentProduct?.idx) {
                        return (
                          <SaleProductRowV2
                            key={`${product.sku}_${index}`}
                            idx={index}
                            product={currentProduct}
                            showUnitType={printUnitTypes}
                            isCreation={isCreation}
                            tableColumns={tableColumns}
                          />
                        );
                      } else {
                        return (
                          <ProductDetailsRow
                            tableColumns={tableColumns}
                            idx={index}
                            key={`${product.sku}_${index}`}
                            product={product}
                            editCurves={false}
                            isCreation={isCreation}
                          />
                        );
                      }
                    }
                  )}
                </tbody>
              </table>
            </PaginatedTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsDetailsV2;
