import { InputV2 } from "@/components/shared/Input/InputV2";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  createProductCurve,
  deleteProductCurve,
  getSegments,
  updateProductCurve,
} from "@/http";
import { normalizeString } from "@/utils/object";
import { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import useSWR from "swr";
import styles from "./styles.module.scss";

export const CurveProductModal = ({
  productOption,
  onDone,
}: {
  productOption: any;
  curve?: any;
  onDone?: () => void;
}) => {
  const [t] = useTranslation("global");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [deletedCurves, setDeletedCurves] = useState<any[]>([]);
  const { data: segments = [] } = useSWR("segments", getSegments);

  const getDefaultValues = () => {
    const curves = (productOption?.curves || []).map((curve: any) => ({
      id: curve?.id ?? `${crypto.randomUUID()}_fake`,
      segmentId: curve?.segmentId ?? "",
      name: curve?.name,
      products: (productOption?.options || []).map((option: any) => ({
        optionValue: option,
        quantity:
          curve.products.find((p: any) => p.optionValue === option)?.quantity ||
          0,
      })),
    }));

    if (curves.length < 1) {
      curves.push({
        id: `${crypto.randomUUID()}_fake`,
        segmentId: "",
        name: "",
        products: (productOption?.options || []).map((option: string) => ({
          optionValue: option,
          quantity: 0,
        })),
      });
    }

    return { curves };
  };

  const methods = useForm<any>({ defaultValues: getDefaultValues() });
  const curves = methods.watch("curves");
  const { append } = useFieldArray({
    control: methods.control,
    name: "curves",
  });

  const onClose = () => {
    setOpen(false);
    methods.reset(getDefaultValues());
  };

  const addRow = () => {
    append({
      id: `${crypto.randomUUID()}_fake`,
      segmentId: "",
      name: "",
      products: (productOption.options || []).map((option: string) => ({
        optionValue: option,
        quantity: 0,
      })),
    });
  };

  const deleteRow = (curve: any) => {
    setDeletedCurves(prev => [...prev, curve]);
    methods.setValue(
      "curves",
      (curves || []).filter((c: any) => curve?.id !== c?.id)
    );
  };

  const onSubmit = async (values: any) => {
    const { curves = [] } = values;
    try {
      setLoading(true);

      const curvesToDelete = deletedCurves.filter(
        c => !(c?.id ?? "").endsWith("_fake")
      );

      if (curvesToDelete.length) {
        const deletePromises = curvesToDelete.map(curve =>
          deleteProductCurve(productOption.id, curve.id)
        );
        await Promise.all(deletePromises);
      }

      const curvesToCreate = curves
        .filter((c: any) => (c?.id ?? "").endsWith("_fake"))
        .map((c: any) => ({
          name: c.name,
          code: normalizeString(c.name),
          products: c.products,
          segmentId: c?.segmentId ?? null,
        }));

      if (curvesToCreate.length) {
        const createPromises = curvesToCreate.map((c: any) =>
          createProductCurve(productOption.id, c)
        );
        await Promise.all(createPromises);
      }

      const curvesToUpdate = curves
        .filter((c: any) => !(c?.id ?? "").endsWith("_fake"))
        .map((c: any) => ({
          products: c.products,
          id: c.id,
          segmentId: c?.segmentId ?? null,
        }));

      if (curvesToUpdate.length) {
        const updatePromises = curvesToUpdate.map((c: any) =>
          updateProductCurve(productOption.id, c.id, {
            products: c.products,
            segmentId: c.segmentId,
          })
        );
        await Promise.all(updatePromises);
      }

      onDone?.();
      onClose();
      showToastSuccess(t("PRODUCT_CURVE_UPDATED"));
    } catch (e) {
      showToastError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button onClick={() => setOpen(true)} className="primary">
        + {t("EDIT_PRODUCT_CURVES")}
      </button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        title={t("EDIT_PRODUCT_CURVES")}
        size="xl"
        footer={
          <>
            <button disabled={isLoading} onClick={onClose}>
              {t("CANCEL")}
            </button>
            <button
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
              className="primary lg"
            >
              {t("SAVE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("NAME")}</th>
                {(productOption?.options || []).map(
                  (option: string, i: number) => (
                    <th key={i}>{option}</th>
                  )
                )}
                <th>{t("TOTAL")}</th>
                <th>{t("SEGMENT")}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {(curves || []).map((curve: any, curveIndex: number) => {
                const totalQuantity = (curve?.products || []).reduce(
                  (sum: number, p: any) => sum + Number(p?.quantity),
                  0
                );

                return (
                  <tr key={curve.id} className={styles.row}>
                    <td className={styles.name}>
                      <InputV2
                        name={`curves[${curveIndex}].name`}
                        label=""
                        required
                        disabled={!curve?.id?.endsWith("_fake")}
                      />
                    </td>
                    {(productOption?.options || []).map(
                      (option: any, optionIndex: number) => (
                        <td key={option} className={styles.number}>
                          <NumericInput
                            name={`curves[${curveIndex}].products[${optionIndex}].quantity`}
                            hideControls
                            min={0}
                            label=""
                            required
                          />
                        </td>
                      )
                    )}
                    <td>{totalQuantity}</td>
                    <td className={styles.name}>
                      <SelectV2
                        name={`curves[${curveIndex}].segmentId`}
                        label=""
                        choices={segments.map(s => ({
                          value: s.id,
                          label: s.name,
                        }))}
                      />
                    </td>
                    <td>
                      <span
                        className="circleButton pointer"
                        onClick={() => deleteRow(curve)}
                      >
                        <AiOutlineDelete />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FormProvider>
        <div className="spacer" />
        <button className="purpleOutlined" onClick={addRow}>
          + {t("ADD_CURVE")}
        </button>
        <div className="spacer" />
      </Modal>
    </>
  );
};
