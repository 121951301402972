import { SaleCreation } from "@/components/purchases/SaleCreation/SaleCreation";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const QuoteCreationPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");

  useEffect(() => {
    setTitle(t("QUOTES.CREATE_TITLE"), "/purchases");
  }, [t]);

  return (
    <>
      <SaleCreation type="QUOTATION" />
    </>
  );
};
