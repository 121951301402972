import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { AiOutlineRollback } from "react-icons/ai";
import clsx from "clsx";
import Tooltip from "@/components/shared/Tooltip/Tooltip";

type props = {
  iconButton?: boolean;
  tooltip?: boolean;
  onAction: () => void;
};
const RolbackActionButton = ({ onAction, iconButton, tooltip }: props) => {
  const [t] = useTranslation("global");

  return (
    <>
      {iconButton ? (
        <>
          {tooltip && (
            <Tooltip title={t("ROLLBACK")}>
              <span className="pointer circleButton" onClick={() => onAction()}>
                <AiOutlineRollback />
              </span>
            </Tooltip>
          )}
          {!tooltip && (
            <span className="pointer circleButton" onClick={() => onAction()}>
              <AiOutlineRollback />
            </span>
          )}
        </>
      ) : (
        <button
          type="button"
          onClick={() => onAction()}
          className={clsx("primary", styles.modalTriggerButton)}
        >
          {t("ROLLBACK")}
        </button>
      )}
    </>
  );
};
export default RolbackActionButton;
