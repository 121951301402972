import { TProductSupplier, TSeller } from "@/http";
import { TCustomAttribute } from "@/http/types/custom-attribute";
import { ReactNode, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FiTool } from "react-icons/fi";
import { CustomAttributesTableModal } from "./CustomAttributesTableModal";
import { RecordCustomAttributesTableInputs } from "./RecordCustomAttributesTableInputs";
import styles from "./styles.module.scss";
import { DisabledZone } from "../shared/DisabledZone/DisabledZone";
import { useSubscription } from "@/hooks/useSubscription";
import { FEAT_CUSTOM_ATTRIBUTES } from "@/constants/features";
import { PremiumFeatureIndicator } from "../subscription/PremiumFeatureIndicator";

type Props = {
  appliesTo: TCustomAttribute["appliesTo"];
  record?: TProductSupplier | TSeller;
  forForm?: boolean;
  additionalAction?: ReactNode;
};

export const RecordCustomAttributes = ({
  record,
  appliesTo,
  forForm,
  additionalAction,
}: Props) => {
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();

  return (
    <div className="col-12 noPadding">
      <div className={styles.heading}>
        <h2 className="bold">{t("CUSTOM_ATTRIBUTES.PLURAL")}</h2>{" "}
        <PremiumFeatureIndicator feature={FEAT_CUSTOM_ATTRIBUTES} />
        <CustomAttributesTableModal
          appliesTo={appliesTo}
          trigger={show => (
            <button type="button" onClick={show}>
              <FiTool />
            </button>
          )}
        />
        {additionalAction}
      </div>
      <br />
      <DisabledZone when={!hasFeature(FEAT_CUSTOM_ATTRIBUTES)}>
        {forForm ? (
          <RecordCustomAttributesForForm
            appliesTo={appliesTo}
            record={record}
          />
        ) : (
          <RecordCustomAttributesSolo appliesTo={appliesTo} record={record} />
        )}
      </DisabledZone>
    </div>
  );
};

export const RecordCustomAttributesSolo = ({ record, appliesTo }: Props) => {
  const [values, setValues] = useState(record?.customAttributes ?? []);

  return (
    <RecordCustomAttributesTableInputs
      appliesTo={appliesTo}
      values={values}
      onChange={setValues}
      disabled
    />
  );
};

export const RecordCustomAttributesForForm = ({ record, appliesTo }: Props) => {
  return (
    <Controller
      name="customAttributes"
      defaultValue={record?.customAttributes ?? []}
      shouldUnregister
      render={({ field: { onChange, value } }) => (
        <RecordCustomAttributesTableInputs
          appliesTo={appliesTo}
          values={value}
          onChange={onChange}
        />
      )}
    />
  );
};
