import { Card } from "@/components/shared/Card/Card";
import { useTranslation } from "react-i18next";
import { NameInputs } from "./NameInputs";
import { PriceInputs } from "./PriceInputs";

export const ProductGeneralInfo = ({
  isEdit = false,
}: {
  isEdit?: boolean;
}) => {
  const [t] = useTranslation("products-page");

  return (
    <Card borderTop>
      <p className="bold">{t("BASIC_INFO")}</p>
      <div className="spacer" />
      <div className="flexGap gapSm flexSmColumn">
        <div className="col-6 col-sm-12 noPadding">
          <NameInputs isEdit={isEdit} />
        </div>
        <div className="spacerVertical" />
        <div className="dividerVertical" />
        <div className="spacerVertical" />
        <div className="col-6 col-sm-12 noPadding">
          <PriceInputs isEdit={isEdit} />
        </div>
      </div>
    </Card>
  );
};
