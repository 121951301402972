import { useAuth } from "@/store/auth";
import { getSuppliersByIds } from "@/http";
import useSWR from "swr";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import styles from "./styles.module.scss";
import { useSearchParams } from "react-router-dom";
import { usePurchases } from "@/store/purchases";

type props = {
  type: "SELL_IN" | "SELL_OUT" | "QUOTATION";
};

export const PurchaseSupplierFilter = ({ type }: props) => {
  const [search, setSearch] = useSearchParams();
  const supplierFilter = search.get("supplier");
  const { user } = useAuth();
  const methods = useForm();
  const [t] = useTranslation("global");
  const { data: suppliers } = useSWR(
    [user.id, "supplier-providers"],
    getSuppliersByIds
  );
  const { clearSelectedPurchases } = usePurchases();

  const setSupplierFilter = (value: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("supplier", value);
    setSearch(newSearch);
    clearSelectedPurchases();
  };

  if (type !== "SELL_IN") {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <div className={styles.selectInput}>
        <SelectV2
          label={t("PURCHASES.ORDERS.SELECT_SUPPLIER")}
          name="supplierId"
          choices={(suppliers ?? []).map(s => ({
            value: s.id,
            label: s.name,
          }))}
          required
          onChange={e => setSupplierFilter(e?.target?.value ?? "")}
          defaultValue={supplierFilter ?? ""}
          allowEmpty
        />
      </div>
    </FormProvider>
  );
};
