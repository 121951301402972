import SearchFilter from "@/components/orders/IdFilter/SearchFilter";
import { PurchaseList } from "@/components/purchases/PurchaseList/PurchaseList";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { BatchExport } from "./BatchExport/BatchExport";
import styles from "./styles.module.scss";

export const QuotesListPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  useEffect(() => {
    setTitle(t("QUOTES.LIST_TITLE"), "", "", <SearchFilter />);
  }, [t]);

  return (
    <>
      <div className={`flexGap flexSmColumn ${styles.actions}`}>
        <BatchExport type={"QUOTATION"} />
        <button className="primary" onClick={() => navigate("/quotes/create")}>
          {t("QUOTES.CREATE_QUOTE")}
        </button>
      </div>
      <PurchaseList type={"QUOTATION"} />
    </>
  );
};
