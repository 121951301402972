import { SplitsList } from "@/components/splits/SplitsList";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const SplitsPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");

  useEffect(() => {
    setTitle("Splits");
  }, [t]);

  return <SplitsList />;
};
