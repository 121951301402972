import {
  TOrderProduct,
  TProduct,
  TPurchase,
  TPurchaseProduct,
  TSupplier,
} from "@/http";

export const groupByPending = (products: TProduct[]) => {
  return products.reduce(
    function (
      memo: {
        [key: string]: TProduct[];
      },
      currentValue
    ) {
      if (currentValue.isLocked && !currentValue.subPurchaseId) {
        memo["PREPARING"].push(currentValue);
      }
      if (!currentValue.isLocked && !currentValue.subPurchaseId) {
        memo["PENDING"].push(currentValue);
      }
      return memo;
    },
    {
      PENDING: [],
      PREPARING: [],
    }
  );
};

export const getUpdateLockProducts = (purchase: TPurchase) => {
  return purchase.subPurchases
    .flatMap(sp =>
      sp.products.map(p => ({
        productId: p.id,
        deliveryStatus: sp.deliveryStatus,
        deliveryProvider: sp.deliveryProvider,
        hasTrackingCode:
          !!sp.trackingCode || (sp?.trackingCodes || [])?.length > 0,
      }))
    )
    .filter(p => p.deliveryProvider === "COORDINADORA" && p.hasTrackingCode)
    .reduce<Record<string, boolean>>((acc: any, item) => {
      acc[item.productId] = true;
      return acc;
    }, {});
};

export const getProductToDispatch = (purchase: TPurchase) => {
  return purchase.products.filter(p => !p.subPurchaseId).map(({ id }) => id);
};

export const getProductSupplierFromProduct = (
  product: any,
  userId: string,
  useFirstIfEmpty = false
) => {
  let productSupplier = product?.suppliers?.find(
    ({ supplierId }: TSupplier) => supplierId === userId
  );

  if (!productSupplier && useFirstIfEmpty) {
    productSupplier = product?.suppliers?.[0];
  }

  return productSupplier || {};
};

export const mapProductToOrderProduct = (product: TProduct): TOrderProduct => {
  return {
    id: product.id,
    orderProductId: product.orderProductId,
    mandatoryDiscount: product.totalDiscount,
    unitPrice: product.unitPrice,
    forcedWholesalePrice: product.priceToThirdParty,
    wholesalePrice: product.wholesalePrice,
    discountPercentage: product.discountPercentage,
    quantity: product.quantity,
    supplierId: product.supplierId,
    warehouseId: product.warehouseId ? product.warehouseId : null,
    deliveryStatus: product.deliveryStatus || null,
    unitType: product.unitType || null,
    unitRate: product.unitRate || null,
    serials:
      product.serials?.filter(s => s.id).map(serial => serial.id || "") || [],
    curves: product.curves || [],
  };
};
