import { InputV2 } from "@/components/shared/Input/InputV2";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  createSupplierProductOption,
  updateSupplierProductOption,
} from "@/http";
import { useAuth } from "@/store/auth";
import { normalizeString } from "@/utils/object";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineEdit } from "react-icons/md";

export const ProductOptionModal = ({
  productOption,
  onDone,
}: {
  productOption?: any;
  onDone?: () => void;
}) => {
  const [t] = useTranslation("global");
  const isEdit = !!productOption?.id;
  const defaultValues = isEdit
    ? { ...productOption }
    : { name: "", type: "", options: [""] };
  const methods = useForm({ defaultValues });
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { user } = useAuth();

  const onClose = () => {
    setOpen(false);
    methods.reset(defaultValues);
  };

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);

      if (isEdit) {
        const newOption = await updateSupplierProductOption(
          productOption?.id,
          values
        );
        methods.reset(newOption);
        showToastSuccess(t("OPTION_UPDATED"));
      } else {
        const payload = {
          ...values,
          supplierId: user.id,
          code: normalizeString(values.name),
        };
        methods.reset({});
        await createSupplierProductOption(payload);
        showToastSuccess(t("OPTION_CREATED"));
      }
      onDone?.();
      setOpen(false);
    } catch (e) {
      showToastError(e);
    } finally {
      setLoading(false);
    }
  };

  const addOption = () => {
    methods.setValue("options", [...(methods.watch("options") || []), ""]);
  };

  const deleteOption = (idx: number) => {
    const options = methods.watch("options") || [];
    const newOptions = options.filter((_: any, i: number) => i !== idx);

    methods.setValue("options", newOptions);
  };

  return (
    <>
      {isEdit ? (
        <span onClick={() => setOpen(true)} className="circleButton pointer">
          <MdOutlineEdit />
        </span>
      ) : (
        <button onClick={() => setOpen(true)} className="primary">
          + {t("ADD_PRODUCT_OPTION")}
        </button>
      )}
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        title={t(isEdit ? "EDIT_PRODUCT_OPTION" : "ADD_PRODUCT_OPTION")}
        size="sm"
        footer={
          <>
            <button disabled={isLoading} onClick={onClose}>
              {t("CANCEL")}
            </button>
            <button
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
              className="primary lg"
            >
              {t("SAVE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <InputV2 name="name" label={t("NAME")} required />
          <InputV2 name="type" label={t("TYPE")} required />

          <p className="bold">{t("OPTIONS")}</p>
          {(methods.watch("options") || []).map((o: any, idx: number) => (
            <div className="contentInline" key={idx}>
              <div className="col-12 noPadding">
                <InputV2
                  name={`options[${idx}]`}
                  label={`${t("OPTION")} ${idx + 1}`}
                  required
                  validate={(value: string) => {
                    return !(methods.watch("options") || [])
                      .filter((_: any, i: number) => idx !== i)
                      .includes(value);
                  }}
                  validMessage={t("OPTION_EXIST")}
                />
              </div>
              {(methods.watch("options") || []).length > 1 ? (
                <span
                  className="circleButton pointer mt1"
                  onClick={() => deleteOption(idx)}
                >
                  <AiOutlineDelete />
                </span>
              ) : (
                <span />
              )}
            </div>
          ))}
          <button className="purpleOutlined" onClick={addOption}>
            + {t("ADD_OPTION")}
          </button>
          <div className="spacer" />
        </FormProvider>
      </Modal>
    </>
  );
};
