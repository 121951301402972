import { Modal } from "@/components/shared/Modal/Modal";
import { TSellerBillingAddress } from "@/http";
import { FC, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import UpsertActionButton from "@/components/clients/ActionButtons/UpsertActionButton";
import { BillingAddressForm } from "./BillingAddressForm";

type TCreateBillingAddressModal = {
  onDone?: (address: TSellerBillingAddress) => void;
  sellerId: string;
  iconButton?: boolean;
  address?: TSellerBillingAddress;
};
export const CreateBillingAddressModal: FC<TCreateBillingAddressModal> = ({
  onDone,
  sellerId,
  iconButton,
  address,
}) => {
  const [open, setOpen] = useState(false);

  const [t] = useTranslation("global");

  return (
    <>
      <UpsertActionButton
        isEdit={!!address}
        iconButton={iconButton}
        onAction={() => setOpen(true)}
      />
      {createPortal(
        <Modal isOpen={open} onClose={() => setOpen(false)}>
          {open && (
            <div className="leftAlign marginTop1">
              <h6 className="bolder">
                {t(address ? "CLIENTS.EDIT_ADDRESS" : "CLIENTS.NEW_ADDRESS")}
              </h6>
              <div className="divider" />
              <div className="spacer" />
              <BillingAddressForm
                clientId={sellerId}
                address={address}
                onDone={address => {
                  onDone?.(address);
                  setOpen(false);
                }}
              />
            </div>
          )}
        </Modal>,
        document.body
      )}
    </>
  );
};
