import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputProps } from "./Input";
import { get } from "lodash-es";
import styles from "./styles.module.scss";
import { ReactNode } from "react";
import clsx from "clsx";

export const SelectV2 = ({
  className,
  defaultValue,
  name,
  label,
  required = false,
  choices,
  disabled = false,
  onChange,
  validate,
  validMessage,
  infoLabel,
  allowEmpty,
}: Omit<InputProps, "register"> & {
  className?: string;
  validMessage?: string;
  defaultValue?: string;
  infoLabel?: string | ReactNode;
  allowEmpty?: boolean;
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [t] = useTranslation("global");
  const typeError = get(errors, `${name}.type`);

  const uniqueValues = new Set<string>();

  const uniqueArray = (choices ?? []).filter(item => {
    if (!uniqueValues.has(item.value)) {
      uniqueValues.add(item.value);
      return true;
    }
    return false;
  });
  return (
    <div
      className={clsx(
        className,
        styles.selectContainer,
        styles.field,
        typeError ? styles.required : ""
      )}
    >
      <label>
        <span>{label}</span>
        <select
          className={clsx(
            styles.select,
            typeError ? styles.required : "",
            uniqueArray.length === 1 && allowEmpty ? styles.singleOption : ""
          )}
          {...register(name, {
            required: required ? t("LOGIN.FIELD_REQUIRED") : false,
            onChange: onChange,
            validate,
          })}
          disabled={disabled}
          defaultValue={defaultValue ? defaultValue : ""}
        >
          <option disabled={!allowEmpty} hidden={!allowEmpty} value="">
            {t("SELECT_OPTION")}
          </option>

          {uniqueArray?.map(choice => (
            <option
              key={choice.value}
              value={choice.value}
              disabled={choice.disabled}
            >
              {choice.label}
            </option>
          ))}
        </select>
      </label>
      {!!infoLabel && <span className={styles.infoLabel}>{infoLabel}</span>}

      {typeof typeError === "string" && typeError === "required" && (
        <span className={styles.errorsLabel}>{t("LOGIN.FIELD_REQUIRED")}</span>
      )}
      {typeof typeError === "string" && typeError === "validate" && (
        <span className={styles.errorsLabel}>
          {validMessage ||
            get(errors as any, `${name}.message`) ||
            t("LOGIN.INVALID_FIELD")}
        </span>
      )}
    </div>
  );
};
