import { TSubPurchase } from "@/http";
import { useTranslation } from "react-i18next";
import styles from "./SubPurchases.module.scss";
import format from "date-fns/format";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { paymentStatus } from "@/constants/purchases";
import { Link } from "react-router-dom";
import { useAuth } from "@/store/auth";
import { isUSA } from "@/constants/core";
import { DeleteDispatchOrder } from "@/components/orders/TableOrders/DeleteOrder";
import { AiOutlineDelete } from "react-icons/ai";
import { usePurchase } from "@/store/purchase";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { SubPurchaseInvoicePDF } from "../Actions/SubPurchaseInvoicePDF";

type TSubPurchases = {
  subpurchase: TSubPurchase;
  children?: React.ReactNode;
};
export const SubPurchasesV2 = ({ subpurchase, children }: TSubPurchases) => {
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const { user } = useAuth();
  const { purchase, getPurchase } = usePurchase();

  return (
    <>
      <div key={subpurchase.id}>
        <div className="flexGap center">
          <Link to={`/orders/${subpurchase.id}`} className={"bold"}>
            <span className={styles.linkTitle}>{`${t("SUBPURCHASE_ID")}: ${
              subpurchase?.subPurchaseNumber ?? "-"
            }`}</span>
          </Link>

          <SubPurchaseInvoicePDF subpurchase={subpurchase} useIconButton />

          {user.id === subpurchase?.supplierId ? (
            <>
              <DeleteDispatchOrder
                button={
                  <ToolTip title={tGlobal("DELETE")}>
                    <span className="circleButton pointer">
                      <AiOutlineDelete />
                    </span>
                  </ToolTip>
                }
                orderNumber={subpurchase.subPurchaseNumber}
                orderId={subpurchase.id}
                onDone={() => getPurchase(purchase.id)}
              />
            </>
          ) : (
            <span className="statusChip purple">
              {t("OTHER_PROVIDER_SUPPLIES")}
            </span>
          )}
        </div>
        <div className="spacer" />
        <div className="row">
          <div className="col-6 noPadding">
            <div className={styles.subpurchaseContainer}>
              <div className={styles.field}>
                <span className={styles.title}>{t("CREATED_AT")}:</span>
                <span>
                  {subpurchase?.createdAt
                    ? format(
                        new Date(subpurchase?.createdAt),
                        isUSA ? USA_DATE_HOUR : DATE_HOUR
                      )
                    : "-"}
                </span>
              </div>

              {!isUSA && subpurchase?.supplierStatus !== "CANCELLED" && (
                <div className={styles.field}>
                  <span className={styles.title}>{t("GUIDE")}:</span>

                  {subpurchase.trackingLink ? (
                    <a
                      target={"_blank"}
                      rel={"noreferrer"}
                      className={styles.link}
                      href={subpurchase.trackingLink}
                    >
                      {subpurchase.trackingCode || t("OPEN_LINK")}
                    </a>
                  ) : (
                    <span>{subpurchase.trackingCode || "-"}</span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-6 noPadding">
            <div className={styles.subpurchaseContainer}>
              {!isUSA && (
                <div className={styles.field}>
                  <span className={styles.title}>
                    {t("PURCHASE_PAYMENT_STATUS")}:
                  </span>
                  <span>
                    {
                      paymentStatus.find(
                        s => s.code === subpurchase.paymentStatus
                      )?.name
                    }
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 noPadding">{children}</div>
        </div>
      </div>
      <div className="divider" />
    </>
  );
};
