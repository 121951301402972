import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { AiOutlineSave } from "react-icons/ai";
import clsx from "clsx";
import Tooltip from "@/components/shared/Tooltip/Tooltip";

type props = {
  tooltip?: boolean;
  iconButton?: boolean;
  onAction: () => void;
};
const SaveActionButton = ({ onAction, iconButton, tooltip }: props) => {
  const [t] = useTranslation("global");

  return (
    <>
      {iconButton ? (
        <>
          {tooltip && (
            <Tooltip title={t("SAVE")}>
              <span className="pointer circleButton" onClick={() => onAction()}>
                <AiOutlineSave />
              </span>
            </Tooltip>
          )}
          {!tooltip && (
            <span className="pointer circleButton" onClick={() => onAction()}>
              <AiOutlineSave />
            </span>
          )}
        </>
      ) : (
        <button
          type="button"
          onClick={() => onAction()}
          className={clsx("primary", styles.modalTriggerButton)}
        >
          {t("SAVE")}
        </button>
      )}
    </>
  );
};
export default SaveActionButton;
