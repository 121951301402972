import { Checkbox } from "@/components/shared/Input/Checkbox";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import {
  getSellerBillingAddresses,
  TSellerAddress,
  TSellerBillingAddress,
} from "@/http";
import { addressToString } from "@/utils/address";
import { logError } from "@/utils/errors";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CreateBillingAddressModal } from "../../PurchaseCreateForm/PurchaseInvoice/CreateBillingAddressModal";

export const Invoicing = () => {
  const sellerId = useWatch({ name: "sellerId" });
  const addressIdWatch = useWatch({ name: "addressId" });
  const useSameDirectionWatch = useWatch({ name: "useSameDirection" });
  const [addresses, setAddresses] = useState<TSellerBillingAddress[]>([]);
  const [t] = useTranslation("purchases");
  const { setValue } = useFormContext();

  const updateAdresses = (address?: TSellerBillingAddress) =>
    getSellerBillingAddresses(sellerId)
      .then(data => {
        setAddresses(data);
        if (address?.id) {
          setTimeout(() => {
            setValue("invoiceThirdPartyId", address.id);
          }, 500);
        }
      })
      .catch(error => {
        logError(error);
        setAddresses([]);
      });

  useEffect(() => {
    if (sellerId) {
      updateAdresses();
    }
  }, [sellerId]);

  return (
    <>
      {!!addressIdWatch && (
        <>
          <p className="bold mbmd">{t("INVOICING")}</p>
          <Checkbox
            name="useSameDirection"
            label={t("USE_SAME_DIRECTION")}
            onChange={() => {
              setValue("invoiceThirdPartyId", "");
            }}
          />
          {!useSameDirectionWatch && (
            <>
              <SelectV2
                name="invoiceThirdPartyId"
                label={
                  <div className="contentInline">
                    <span className="bold">
                      {t("SELECT_SELLER_BILLING_ADDRESS")}
                    </span>
                    {!!sellerId && (
                      <CreateBillingAddressModal
                        onDone={updateAdresses}
                        sellerId={sellerId}
                        iconButton
                      />
                    )}
                  </div>
                }
                required
                choices={addresses.map(a => ({
                  value: a.id!,
                  label: addressToString(a),
                }))}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
