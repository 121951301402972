import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { isUSA } from "@/constants/core";
import { getProductTaxes } from "@/http";
import { validateDecimalNumber, validateOnlyNumber } from "@/utils/keyboard";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import styles from "./styles.module.scss";

export const PriceInputs = ({ isEdit = false }: { isEdit?: boolean }) => {
  const [t] = useTranslation("products-page");
  const methods = useFormContext();
  const chargeTaxes = methods.watch("chargeTaxes");
  const { data: metadataTaxes } = useSWR(
    ["getMetadataTaxTypes"],
    getProductTaxes
  );

  const isKit = methods.watch("type") === "KIT";
  const isGroup = methods.watch("type") === "GROUP";

  return (
    <>
      <p className="bold">{t("PRODUCT.PRICE")}</p>
      <div className={styles.inputsContainer}>
        <InputV2
          name="wholesalePrice"
          label={t("WHOLESALE_PRICE")}
          onKeyPress={validateDecimalNumber}
          required
        />
        <InputV2
          name="purchasePrice"
          label={t("PURCHASE_PRICE")}
          onKeyPress={validateDecimalNumber}
        />
        <InputV2
          name="retailPrice"
          label={<span className="textLine">{t("PVP")}</span>}
          onKeyPress={validateDecimalNumber}
        />

        {!isUSA && (
          <>
            <div className={styles.fullWidth}>
              <Checkbox name="chargeTaxes" label={t("CHARGE_TAXES")} />
            </div>
            {chargeTaxes && metadataTaxes && (
              <SelectV2
                name="taxesType"
                label={t("TAX")}
                choices={metadataTaxes
                  .sort((a, b) => (a.value > b.value ? 1 : -1))
                  .map(t => ({
                    value: t.code,
                    label: `${t.value}%`,
                  }))}
              />
            )}
          </>
        )}
      </div>
      <div className="spacer" />
      <p className="bold">{t("PRODUCT.INVENTORY")}</p>

      <div className={styles.inputsContainer}>
        <InputV2
          name="barCode"
          label={t("PRODUCT.SUPPLIER_BAR_CODE")}
          onKeyPress={validateOnlyNumber}
        />
        {!isUSA && (
          <InputV2 name="sku" label={t("PRODUCT.DETAIL.SKU")} type="text" />
        )}
        {!isEdit && !isKit && (
          <InputV2
            name="stock"
            label={t("STOCK_LABEL")}
            onKeyPress={validateDecimalNumber}
            required
          />
        )}
        {isGroup && (
          <InputV2 name="retailIntroductionDate" label="RID" type="date" />
        )}
        {!isKit && (
          <div className={styles.fullWidth}>
            <Checkbox
              name="useSecurityStock"
              label={t("PRODUCT.SECURITY_STOCK")}
            />
          </div>
        )}
        {!isKit && !!methods.watch("useSecurityStock") && (
          <InputV2
            name="securityStock"
            label={t("PRODUCT.SECURITY_STOCK")}
            onKeyPress={validateDecimalNumber}
            required
          />
        )}
        {isEdit && !isKit && (
          <>
            <InputV2
              name="departureStock"
              label={t("DEPARTURE_STOCK")}
              type="text"
              disabled
            />
            <InputV2
              name="reservedStock"
              label={t("RESERVED_STOCK")}
              type="text"
              disabled
            />
          </>
        )}
      </div>
      <div className="spacer" />
      <div className={styles.inputsContainer}>
        <p className="bold">{t("SHIPPING")}</p>
        <div className="spacer" />
        <NumericInput
          name="height"
          label={t("PRODUCT.DETAIL.HEIGHT")}
          min={0}
          hideControls
          decimals
        />
        <NumericInput
          name="width"
          label={t("PRODUCT.DETAIL.WIDTH")}
          min={0}
          hideControls
          decimals
        />

        <NumericInput
          name="length"
          label={t("PRODUCT.DETAIL.LENGTH")}
          min={0}
          hideControls
          decimals
        />
        <NumericInput
          name="weight"
          label={t("PRODUCT.DETAIL.WEIGHT")}
          min={0}
          hideControls
          decimals
        />
      </div>
    </>
  );
};
