import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { buckets } from "@/core/buckets";
import { showToastError } from "@/hooks/useToast";
import { updateDeliveryStatusDeliveryProvider } from "@/http";
import { getSupplierDeliveryProviderByCode } from "@/http/resources/suppliers/delivery-provider";
import { useAuth } from "@/store/auth";
import { useOrderDetails } from "@/store/order-detail";
import { uploadFile } from "@/utils/file-upload";
import { nanoid } from "nanoid";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoMdRemoveCircle } from "react-icons/io";
import useSWR from "swr";
import { MdOutlineEdit } from "react-icons/md";

type props = {
  onDone?: () => void;
};

export const UpdateProviderDeliveryStatus = ({ onDone }: props) => {
  const [files, setFiles] = useState<any>([]);
  const { getOrderDetail, orderDetail } = useOrderDetails();
  const { deliveryProvider, providerDeliveryStatus } = orderDetail;
  const methods = useForm({ defaultValues: { deliveryStatus: "" } });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { data: deliveryProviderStatus } = useSWR(
    deliveryProvider ? [deliveryProvider, "supplier-delivery-providers"] : null,
    getSupplierDeliveryProviderByCode
  );
  const [t] = useTranslation("orders-page");
  const { user } = useAuth();
  const uploadEvidence = async (evidenceFile: any) => {
    const url = await uploadFile(
      buckets.purchases,
      "SupplierEvidence",
      nanoid(),
      evidenceFile
    );

    return url;
  };

  const onSubmit = async ({ deliveryStatus }: any) => {
    try {
      setIsLoading(true);
      let url;
      if (files.length) {
        url = await uploadEvidence(files[0]);
      }

      await updateDeliveryStatusDeliveryProvider(
        orderDetail.id,
        (orderDetail.trackingCodes ?? []).find(code => code.id)?.id ?? "",
        { providerDeliveryStatus: deliveryStatus, trackingFile: url }
      );
      getOrderDetail(orderDetail.id, user.id);
      setIsOpen(false);
      onDone?.();
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToolTip title={t("DETAILS.GENERATE_GUIDE")} position="Right">
        <span className="pointer circleButton" onClick={() => setIsOpen(true)}>
          <MdOutlineEdit />
        </span>
      </ToolTip>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="sm"
        title={t("UPDATE_DELIVERY_STATUS")}
        footer={
          <>
            <button
              disabled={isLoading}
              onClick={() => {
                methods.reset({ deliveryStatus: "" });
                setIsOpen(false);
              }}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("UPDATE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <SelectV2
            label={t("SELECT_DELIVERY_STATUS")}
            name="deliveryStatus"
            choices={deliveryProviderStatus?.map(ds => ({
              label: ds.providerDeliveryStatusName,
              value: ds.providerDeliveryStatus,
              disabled: ds.providerDeliveryStatus === providerDeliveryStatus,
            }))}
          />
          <p>{t("EVIDENCE")}</p>
          <div>
            {!files.length && (
              <DragAndDropFile
                files={files}
                setFiles={setFiles}
                minifiedStyle
              />
            )}
            {files.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginTop: "0.7rem",
                }}
              >
                <ToolTip title={t("DELETE_EVIDENCE")}>
                  <span className="redText">
                    <IoMdRemoveCircle
                      cursor="pointer"
                      onClick={() => setFiles([])}
                    />
                  </span>
                </ToolTip>
                {files[0].name}
              </div>
            )}
          </div>
        </FormProvider>
      </Modal>
    </>
  );
};
