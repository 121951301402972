import { TRestListData, TRestListParams } from "@/http/types/rest";
import { getInstance } from "../../core/axios-gateway";
import { TPaymentMethodV2 } from "../../types/payment-method-v2";

export const getPaymentMethods = async () => {
  return getInstance()
    .get<TPaymentMethodV2[]>("/api/payment-methods")
    .then(res => res.data);
};

export const getSupplierPaymentMethods = async () => {
  return getInstance()
    .get<TPaymentMethodV2[]>("/api/payment-methods/supplier")
    .then(res => res.data);
};

export const setSupplierPaymentMethods = async (body: any) => {
  return getInstance()
    .post<TPaymentMethodV2[]>("/api/payment-methods/supplier", body)
    .then(res => res.data);
};

export const createCreditMemo = async (data: any) => {
  return getInstance()
    .post<any>("/api/payment-methods/credit-memo/transactions", data)
    .then(res => res.data);
};

export const getCreditMemoBySeller = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<any>>("/api/payment-methods/credit-memo/transactions", {
      params,
    })
    .then(res => res.data);
};

export const getCreditMemoBalanceBySeller = async (sellerId: string) => {
  return getInstance()
    .get<any>("/api/payment-methods/credit-memo/balance", {
      params: { sellerId },
    })
    .then(res => res.data);
};

export const getCreditMemoById = async (id: string) => {
  return getInstance()
    .get<any>(`/api/payment-methods/credit-memo/transactions/${id}`)
    .then(res => res.data);
};

export const deleteCreditMemoById = async (id: string) => {
  return getInstance()
    .delete<any>(`/api/payment-methods/credit-memo/transactions/${id}`)
    .then(res => res.data);
};
