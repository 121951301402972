import { useCities, useCountries, useStates } from "@/hooks/useCountry";
import { createAddress, TSellerAddress, TSupplierAddress, updateAddress } from "@/http";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { customToast, showToastError } from "@/hooks/useToast";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Autocomplete } from "../shared/Autocomplete/Autocomplete";
import { SelectV2 } from "../shared/Input/SelectV2";
import { Checkbox } from "../shared/Input/Checkbox";
import { NumericInput } from "../shared/Input/NumericInput";
import { InputV2 } from "../shared/Input/InputV2";
import { isUSA } from "@/constants/core";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { getCoordinatesFromAddress } from "@/utils/address";
import { GOOGLE_MAPS_API_KEY } from "@/config/google";

type AddressFormProps = {
  clientId: string;
  address?: TSellerAddress;
  onDone?: (address: TSellerAddress) => void;
};

export const AddressForm: FC<AddressFormProps> = ({ clientId, address, onDone }) => {
  const defaultValues = address
    ? { ...address, countryName: address.country }
    : {
      name: "",
      addressLine1: "",
      addressLine2: "",
      isMain: false,
      country: "",
      state: "",
      city: "",
      latitude: "",
      longitude: "",
      postalCode: "",
      phone: "",
      contactName: "",
      countryName: "",
    };

  const methods = useForm<Partial<TSupplierAddress & { countryName: string }>>({
    defaultValues,
  });

  const countries = useCountries();
  const stateName = methods.watch("state");
  const [country, setCountry] = useState<any>(null);
  const { states, statesIsLoading } = useStates(country?.id);
  const { cities, citiesIsLoading } = useCities(states, stateName ?? "");
  const [t] = useTranslation("global");
  const isRequired = ["mx", "us"].includes(country?.prefix?.toLowerCase());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [hasInitialCoords, setHasInitialCoords] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const [countryName, state, city, addressLine1] = useWatch({
    control: methods.control,
    name: ["countryName", "state", "city", "addressLine1"],
  });

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    const latLng = e.latLng;
    if (!latLng) return;
    const coords = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    setMarkerPosition(coords);
    methods.setValue("latitude", coords.lat.toString());
    methods.setValue("longitude", coords.lng.toString());
  };

  useEffect(() => {
    if (isLoaded && address?.latitude && address?.longitude && !hasInitialCoords) {
      const lat = parseFloat(address.latitude);
      const lng = parseFloat(address.longitude);
      setMarkerPosition({ lat, lng });
      methods.setValue("latitude", address.latitude);
      methods.setValue("longitude", address.longitude);
      setHasInitialCoords(true);
    }
  }, [isLoaded, address, hasInitialCoords]);

  useEffect(() => {
    const run = async () => {
      const allFieldsFilled = countryName && state && city && addressLine1;
      if (!allFieldsFilled) return;
      const coords = await getCoordinatesFromAddress({ country: countryName, state, city, street: addressLine1 });
      if (coords) {
        setMarkerPosition(coords);
        methods.setValue("latitude", coords.lat.toString());
        methods.setValue("longitude", coords.lng.toString());
      }
    };
    const timeout = setTimeout(run, 500);
    return () => clearTimeout(timeout);
  }, [countryName, state, city, addressLine1, showMap, isLoaded, markerPosition]);

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    try {
      const payload = {
        ...values,
        countryCode: country?.prefix?.toLowerCase(),
        country: values.countryName,
      };
      let savedAddress;
      if (address) {
        savedAddress = await updateAddress(clientId, address.id!, payload);
      } else {
        savedAddress = await createAddress(clientId, payload as any);
      }
      customToast({ title: t("CLIENTS.ADDRESS_SAVED"), status: "success" });
      onDone?.(savedAddress);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (address) {
      const found = countries?.find(c => c.name === address.country);
      if (found) setCountry(found);
    }
  }, [countries]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [address]);

  useEffect(() => {
    if (address?.country !== countryName) {
      methods.setValue("state", "");
      methods.setValue("city", "");
    }
  }, [countryName]);

  useEffect(() => {
    if (state !== address?.state) {
      methods.setValue("city", "");
    }
  }, [state]);

  return (
    <FormProvider {...methods}>
      <div className={styles.formGrid}>
        <Autocomplete
          inputName="countryName"
          inputLabel={t("COUNTRY")}
          inputPlaceholder=""
          required
          choices={countries?.map(country => ({ ...country, id: "" + country.id })) ?? []}
          action={selected => {
            methods.setValue("countryName", selected?.name ?? "");
            setCountry(selected);
          }}
        />
        <SelectV2
          name="state"
          label={t("ACCOUNT.ADDRESSES.STATE")}
          required
          disabled={statesIsLoading}
          choices={states?.map(state => ({
            value: state.name,
            label: state.name,
          }))}
        />
        <SelectV2
          name="city"
          label={t("ACCOUNT.ADDRESSES.CITY")}
          required
          disabled={citiesIsLoading}
          choices={cities?.map(city => ({
            value: city.name,
            label: city.name,
          }))}
        />
        <NumericInput
          name="postalCode"
          label={t("CLIENTS.POSTAL_CODE") + (isRequired ? " *" : "")}
          required={isRequired}
          max={isRequired ? 99999 : 999999}
          hideControls
        />
        <InputV2
          name="addressLine1"
          type="text"
          label={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_1")}
          placeholder={isUSA ? "1105 NE 3rd Ave Unit 23" : t("ACCOUNT.ADDRESSES.ADDRESS_LINE_1_PLACEHOLDER")}
          required
          maxLength={170}
        />
        <InputV2
          name="addressLine2"
          type="text"
          label={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_2")}
          placeholder={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_2_PLACEHOLDER")}
          maxLength={170}
        />
        <Checkbox name="isMain" label={t("MAIN_ADDRESS")} />
        <Checkbox
          name="showMap"
          label={t("CLIENTS.LOCATE_ON_MAP")}
          onChange={() => setShowMap(!showMap)}
        />
      </div>

      {showMap && isLoaded && (
        <div style={{ height: "400px", width: "100%", marginTop: "1rem" }}>
          <GoogleMap
            zoom={14}
            center={markerPosition || { lat: 4.813, lng: -75.696 }}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            onClick={onMapClick}
          >
            {markerPosition && <Marker position={markerPosition} draggable onDragEnd={onMapClick} />}
          </GoogleMap>
        </div>
      )}

      <div className="row rigthAlign">
        <button
          className="primary lg"
          onClick={methods.handleSubmit(onSubmit)}
          disabled={isSubmitting}
        >
          {t("ACCOUNT.ADDRESSES.SAVE")}
        </button>
      </div>
    </FormProvider>
  );
};
