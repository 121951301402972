import { TSeller, TSellerAddress, TSellerBillingAddress } from "@/http";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DeleteBillingAddress } from "./DeleteBillingAddress";
import { Table, TableCell } from "./ui/Table/Table";
import { useRecordContext } from "./ui/RecordContext";
import { CreateBillingAddressModal } from "../purchases/PurchaseCreateForm/PurchaseInvoice/CreateBillingAddressModal";

type BillingAddressTableProps = {
  list: TSellerBillingAddress[];
  page: number;
  onPageChange: (page: number) => void;
  refresh: () => void;
};

export const BillingAddressTable: FC<BillingAddressTableProps> = ({
  list,
  refresh,
}) => {
  const [t] = useTranslation("global");
  const client = useRecordContext<TSeller>();

  return (
    <>
      <Table records={list}>
        <TableCell
          label={t("CLIENTS.NAME")}
          render={(address: TSellerAddress) => <>{`${address.contactName}`}</>}
        />
        <TableCell
          label={t("CLIENTS.ADDRESS")}
          render={(address: TSellerAddress) => (
            <>{`${address.addressLine1}, ${address.addressLine2 ?? ""}`}</>
          )}
        />
        <TableCell label={t("CLIENTS.STATE")} source="state" />
        <TableCell label={t("CLIENTS.CITY")} source="city" />
        <TableCell
          label={t("ACTIONS")}
          render={(address: TSellerBillingAddress) => (
            <div className="flexGap">
              <CreateBillingAddressModal
                iconButton
                address={address}
                sellerId={client.id}
                onDone={() => refresh()}
              />
              <DeleteBillingAddress
                clientId={client.id}
                address={address}
                onDone={() => refresh()}
              />
            </div>
          )}
        />
      </Table>
    </>
  );
};
