import { showToastError } from "@/hooks/useToast";
import { get } from "lodash-es";
import { mapProductToOrderProduct } from "./products";
import { TOrderProduct } from "@/http";

export const getSaleProductStock = (
  product: any,
  warehouseId: string,
  unitRate: any
) => {
  const unitRateNumber = Number(unitRate);
  const warehouse = (
    (product?.warehouses || product?.availableWarehouses) ??
    []
  ).find((w: any) => w.warehouseId === warehouseId);

  return unitRate
    ? Number(product?.availableStock ?? 0) / unitRateNumber +
        (warehouse?.overStock
          ? Number(warehouse?.overStockAmount ?? 1000000) / unitRateNumber
          : 0)
    : Number(product?.availableStock ?? 0) +
        (warehouse?.overStock
          ? Number(warehouse?.overStockAmount ?? 1000000)
          : 0);
};

export const mapSaleProducts = (
  products: any[],
  {
    seller,
    productsMethod = "classic",
  }: {
    seller?: any;
    productsMethod?: "classic" | "excel";
  }
) => {
  return products?.map(
    (p: any): TOrderProduct => ({
      ...mapProductToOrderProduct(p),
      curves: p?.curves
        ? p?.curves
            ?.filter((c: any) =>
              !seller || !(seller?.segments || []).length
                ? !c.segmentId
                : !!(seller?.segments || []).find((s: any) =>
                    !c.segmentId ? true : s?.id === c.segmentId
                  )
            )
            ?.filter((c: any) => productsMethod === "excel" || !!c?.selected)
        : undefined,
    })
  );
};

export const validateStockAvailability = (
  products: TOrderProduct[],
  availableStocks: Record<string, number>
) => {
  return products?.filter((p: any) => {
    const availableStock = availableStocks[p.id];
    const unitRateNumber = p?.unitRate ? Number(p?.unitRate) : 1;

    return availableStock / unitRateNumber > 0;
  });
};

export const mapSaleCreationError = (
  error: any,
  navigate: any,
  type: string,
  tGlobal: any
) => {
  const code = get(error, "response.data.code", get(error, "code", ""));
  if (code === "purchases.errors.P011") {
    const message = get(
      error,
      "response.data.message",
      get(error, "message", "")
    );
    const purchaseIdMatch = message.match(/purchaseId:([a-f0-9-]+)/);
    const purchaseId = purchaseIdMatch ? purchaseIdMatch[1] : null;
    showToastError(error);
    if (purchaseId) {
      navigate(
        `/${type === "SALE" ? "purchases" : "quotes"}/${purchaseId}/show`
      );
    }
  } else {
    showToastError(error);
  }
};
