import { isUSA } from "@/constants/core";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createCreditMemo, TClient, TProduct, TSeller } from "@/http";
import { useAuth } from "@/store/auth";
import { useAddresses } from "@/store/profile";
import { addressToString } from "@/utils/address";
import { formatCurrency } from "@/utils/currency";
import { hasPermission } from "@/utils/validations/permissions";
import clsx from "clsx";
import { get } from "lodash-es";
import React, { useEffect } from "react";
import { ReactNode, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ProductImage from "../layout/ProductImage";
import { Checkbox } from "../shared/Input/Checkbox";
import { NumericInput } from "../shared/Input/NumericInput";
import { SelectV2 } from "../shared/Input/SelectV2";
import { Modal } from "../shared/Modal/Modal";
import styles from "./styles.module.scss";
import { ProductSearch } from "../shared/ProductSearch/ProductSearch";

type TCreditMemoModal = {
  actionElement: ReactNode;
  seller: TSeller | TClient;
  onDone?: (value: any) => void;
};

export const CreditMemoModal = ({
  actionElement,
  seller,
  onDone,
}: TCreditMemoModal) => {
  const { user, isCommercial, role } = useAuth();
  const { getAddresses, loadingOrders, addresses } = useAddresses();
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  const products = useWatch({ name: "products", control: methods.control });
  const sellerId = seller?.id;

  const onClose = () => {
    setIsOpen(false);
    methods.reset({});
  };

  const onDelete = (idx: number) => {
    const productsFiltered = (products ?? []).filter(
      (p: string, i: number) => idx !== i
    );
    methods.setValue("products", productsFiltered);
  };

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      if (!values?.products?.length) {
        showToastError({ message: t("ADD_ONE_PRODUCT") });
        return;
      }
      const payload = {
        sellerId,
        orientation: "POSITIVE",
        amount: values?.amount,
        type: "PRODUCTS",
        products: values?.products?.map((p: any) => ({
          id: p?.id,
          quantity: p?.quantity,
          wholesalePrice: p?.wholesalePrice,
          canReturnQuantity: p?.canReturnQuantity,
          warehouseId: p?.canReturnQuantity ? p?.warehouseId : null,
          reason: p?.reason,
        })),
      };
      const creditMemo = await createCreditMemo(payload);
      onDone?.(creditMemo);
      showToastSuccess(t("CREDIT_MEMO_CREATED"));
      onClose();
    } catch (e) {
      showToastError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const sum = (products ?? []).reduce(
      (acc: any, item: any) =>
        acc + (item.quantity || 0) * (item.wholesalePrice || 0),
      0
    );
    methods.setValue("amount", sum);
  }, [products]);

  useEffect(() => {
    if (isOpen) {
      getAddresses(user.id ?? "", 0);
    }
  }, [isOpen]);

  return (
    <>
      <span className="pointer" onClick={() => setIsOpen(true)}>
        {actionElement}
      </span>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t("CREATE_CREDIT_MEMO")}
        footer={
          <>
            <button onClick={onClose} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              onClick={methods.handleSubmit(onSubmit)}
              className="primary lg"
              disabled={isLoading}
            >
              {t("CREATE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          {(products ?? [])?.length > 0 && (
            <React.Fragment>
              <div className="tableScroll">
                <table className={styles.table}>
                  <tbody>
                    {(products ?? [])?.map((p: any, idx: number) => (
                      <tr key={p.id + "_" + idx} className={styles.row}>
                        <td>
                          <ProductImage images={p?.media ?? []} />
                        </td>
                        <td className={styles.productText}>
                          <div className="lh1 leftAlign">
                            <span className="bold mdFont textLine">
                              {t("PURCHASES.ORDERS.PRODUCT")}
                            </span>

                            <div className="mtmd mdFont">{p?.name}</div>
                          </div>
                        </td>
                        <td className={styles.inputContainer}>
                          <div className="lh1">
                            <SelectV2
                              name={`products[${idx}].reason`}
                              label={t("REASON")}
                              required
                              choices={(isUSA
                                ? ["Expired", "Return", "Failure", "Other"]
                                : ["Expirado", "Devolucion", "Averia", "Otro"]
                              ).map(o => ({ value: o, label: o }))}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="lh1">
                            <NumericInput
                              name={`products[${idx}].quantity`}
                              label={
                                <span className="mdFont bold textLine">
                                  {t("PURCHASES.ORDERS.QUANTITY")}
                                </span>
                              }
                              required
                              decimals={p?.partialUnitsAllowed}
                              min={p?.partialUnitsAllowed ? 0.1 : 1}
                            />
                            <NumericInput
                              name={`products[${idx}].wholesalePrice`}
                              label={
                                <span className="mdFont bold textLine">
                                  {t("PURCHASES.ORDERS.PRICE")}
                                </span>
                              }
                              decimals
                              hideControls
                              disabled={
                                !hasPermission(isCommercial, role, [
                                  "action.purchase.product-price",
                                ])
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="lh1">
                            <span className="bold mdFont textLine">
                              {t("PURCHASES.ORDERS.TOTAL_PRICE")}
                            </span>

                            <div className="textLine mdFont bold mtmd">
                              {formatCurrency(
                                (methods.watch(`products[${idx}].quantity`) ||
                                  0) *
                                  (methods.watch(
                                    `products[${idx}].wholesalePrice`
                                  ) || 0)
                              )}
                            </div>
                          </div>
                        </td>
                        {hasPermission(isCommercial, role, [
                          "action.clients.stock-credit-memo",
                        ]) && (
                          <td>
                            <div className="lh1">
                              <span className="bold mdFont textLine">
                                {t("RETURN_STOCK")}
                              </span>
                              <div
                                className={clsx(
                                  "mtmd",
                                  styles.checkboxContainer
                                )}
                              >
                                <Checkbox
                                  name={`products[${idx}].canReturnQuantity`}
                                  singleInput
                                />

                                {!loadingOrders &&
                                  !!methods.watch(
                                    `products[${idx}].canReturnQuantity`
                                  ) && (
                                    <>
                                      {addresses?.content?.length > 1 ? (
                                        <>
                                          <div className="spacer" />
                                          <SelectV2
                                            name={`products[${idx}].warehouseId`}
                                            required
                                            label={
                                              <b className="mdFont mtmd">
                                                {t(
                                                  "PURCHASES.ORDERS.WAREHOUSE"
                                                )}
                                              </b>
                                            }
                                            choices={addresses?.content?.map(
                                              address => ({
                                                value: address?.id ?? "",
                                                label: addressToString(
                                                  address,
                                                  true
                                                ),
                                              })
                                            )}
                                          />
                                        </>
                                      ) : (
                                        <input
                                          type="hidden"
                                          value={get(
                                            addresses,
                                            "content[0].id"
                                          )}
                                          {...methods.register(
                                            `products[${idx}].warehouseId`
                                          )}
                                        />
                                      )}
                                    </>
                                  )}
                              </div>
                            </div>
                          </td>
                        )}
                        <td>
                          <div
                            className="purpleText bolder pointer mt1"
                            onClick={() => onDelete(idx)}
                          >
                            X
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="spacer" />
            </React.Fragment>
          )}
          <div className="col-12 col-sm-12">
            <ProductSearch
              lockedProductIds={methods
                .watch("products")
                ?.map((p: TProduct) => p.id)}
              filterConditions={{
                supplierId: user.id,
                canCreditMemo: true,
              }}
              onSelect={(product: TProduct) => {
                methods.setValue("products", [
                  ...(methods.watch("products") || []),
                  product,
                ]);
              }}
            />
          </div>
          <div className="spacer" />
          <div className="col-4 col-sm-12 noPadding">
            <NumericInput
              name="amount"
              label={t("AMOUNT")}
              required
              min={0.1}
              decimals
              hideControls
              disabled
            />
          </div>
          <div className="col-8 col-sm-12 noPadding" />
        </FormProvider>
      </Modal>
    </>
  );
};
