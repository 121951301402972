import { isUSA } from "@/constants/core";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { PremiumFeatureIndicator } from "@/components/subscription/PremiumFeatureIndicator";
import { FEAT_LOGISTICS } from "@/constants/features";
import { useAuth } from "@/store/auth";

import { useSubscription } from "@/hooks/useSubscription";
import { useTranslation } from "react-i18next";
import { TDeliveryProvider } from "@/http";
import { useFormContext } from "react-hook-form";

export const LogisticOptionsForm = ({
  deliveryProviders,
}: {
  deliveryProviders: TDeliveryProvider[];
}) => {
  const { user } = useAuth();
  const [t] = useTranslation("orders-page");
  const showSeeriLogistic = user.canUseLogisticIntegration;
  const { hasFeature } = useSubscription();
  const { setValue, watch } = useFormContext();

  const options = [
    ...(showSeeriLogistic && !isUSA
      ? [
          {
            label: (
              <>
                {t("SEERI_LOGISTIC")}
                <PremiumFeatureIndicator feature={FEAT_LOGISTICS} />
              </>
            ),
            value: "SEERI",
            disabled: !hasFeature(FEAT_LOGISTICS),
          },
        ]
      : []),
    ...(deliveryProviders ?? []).map(dp => ({
      label: dp.name,
      value: dp.code,
    })),
  ];
  if (
    (watch("dispatchValue") == null || watch("dispatchValue") == "") &&
    options.length === 1
  ) {
    setValue("dispatchValue", options[0].value);
  }

  return (
    <>
      <RadioGroupV2
        label={""}
        name="dispatchValue"
        options={options}
        required
        inline
        color="purple"
      />
    </>
  );
};
