import { deleteSupplierAddressbyId, TSellerAddress } from "@/http";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Modal } from "@/components/shared/Modal/Modal";
import { useState } from "react";
import { addressToString } from "@/utils/address";
import { useForm } from "react-hook-form";
import DeleteActionButton from "../../ActionButtons/DeleteActionButton";

type TAddressDelete = {
  address: TSellerAddress;
  onDone?: () => void;
};
const AddressDelete = ({ address, onDone }: TAddressDelete) => {
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm();
  const { user } = useAuth();
  const [t] = useTranslation("global");

  const onDelete = async () => {
    try {
      if (!address.id) {
        return;
      }
      await deleteSupplierAddressbyId(user.id, address.id);
      showToastSuccess(t("CLIENTS.DELETE_ADDRESS_CONFIRMATION_TEXT"));
      setIsOpen(false);
      onDone?.();
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <>
      <DeleteActionButton iconButton onAction={() => setIsOpen(true)} />
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="sm"
        title={t("DELETE")}
        footer={
          <div className={styles.actions}>
            <button onClick={() => setIsOpen(false)}>{t("CANCEL")}</button>
            <button
              className="primary"
              onClick={methods.handleSubmit(onDelete)}
            >
              {t("DELETE")}
            </button>
          </div>
        }
      >
        <p>{t("CLIENTS.DELETE_ADDRESS_CONFIRMATION_TEXT")} </p>
        {addressToString(address)}
      </Modal>
    </>
  );
};

export default AddressDelete;
