export const deleteDuplicatedObjects = (array: any[], key: string): any[] => {
  const unique = new Set();
  return array.filter(o => {
    if (!unique.has(o[key])) {
      unique.add(o[key]);
      return true;
    }
    return false;
  });
};

export const normalizeString = (str: string): string => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "")
    .replace(/\s+/g, "_")
    .toUpperCase();
};
