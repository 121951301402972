import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TOrderDetailsPanel } from "@/models/order-details";
import {
  getSupplierBoxById,
  quoteDeliverySupplierOrder,
  updateSupplierOrder,
  updateTrackingCodeDeliveryProvider,
} from "@/http";
import { useOrderDetails } from "@/store/order-detail";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { uploadFile } from "@/utils/file-upload";
import { buckets } from "@/core/buckets";
import { nanoid } from "nanoid";
import { Modal } from "../../shared/Modal/Modal";
import { useAddresses } from "@/store/profile";
import { useAuth } from "@/store/auth";
import useSWR from "swr";
import { getSupplierDeliveryProviders } from "@/http/resources/suppliers/delivery-provider";
import { get } from "lodash-es";
import { formatCurrency } from "@/utils/currency";
import { SeeriLogisticDispatchForm } from "./SeeriLogisticDispatchForm";
import { LogisticOptionsForm } from "./LogisticOptionsForm";
import { ProviderLogisticDispatchForm } from "./ProviderLogisticDispatchForm";
import { isUSA } from "@/constants/core";
import { DeliveryProviderModal } from "@/components/profile/DeliveryProviders/DeliveryProviderModal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { MdOutlineEdit } from "react-icons/md";

const ConfirmOrderModal = ({
  orderDetail,
  userId,
  purchase,
  lightStyle,
  onDone,
}: TOrderDetailsPanel) => {
  const { user } = useAuth();
  const showSeeriLogistic = user.canUseLogisticIntegration;

  const { data: deliveryProviders, mutate } = useSWR(
    ["supplier-delivery-providers"],
    getSupplierDeliveryProviders
  );

  const methods = useForm<any>({
    defaultValues: {
      dispatchValue: !isUSA && showSeeriLogistic ? "SEERI" : "",
      warehouseId: orderDetail?.warehouseId ?? "",
      useSameBox: true,
      boxes: 1,
    },
  });
  const { watch, setValue } = methods;
  const [open, setOpen] = useState(false);
  const [usedTrackingCode, setUsedTrackingCode] = useState(false);
  const [openShipping, setOpenShipping] = useState(false);
  const [quotation, setQuotation] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const { getAddresses, loadingOrders, addresses } = useAddresses();
  const { setOrderDetail } = useOrderDetails();
  const [t] = useTranslation("orders-page");

  const showForm =
    (!isUSA && showSeeriLogistic) || (deliveryProviders ?? [])?.length > 0;

  const uploadGuide = async (trackingFile: any) => {
    const url = await uploadFile(
      buckets.purchases,
      "SupplierGuide",
      nanoid(),
      trackingFile
    );

    return url;
  };

  const confirmOrder = async (values: any) => {
    const { boxes = 0, trackingCode = "", dispatchValue = "" } = values;

    if (orderDetail?.id && userId) {
      setIsLoading(true);
      if (dispatchValue === "SEERI") {
        if (boxes < 1) {
          showToastError(t("ERROR_BOXES"));
          setIsLoading(false);
          return;
        }
        try {
          const address = user.addresses?.find(
            a => a.id === values.warehouseId
          );
          const box = await getSupplierBoxById(user.id, values.singleBoxSize);

          const payload = {
            destino: get(purchase, "client.location.city", ""),
            departamentoDestino: get(purchase, "client.location.state", ""),
            valorDeclarado: Number(values?.declaredValue ?? "0"),
            origen: address?.city,
            departamentoOrigen: address?.state,
            alto: box.height,
            ancho: box.width,
            largo: box.length,
            peso: Number(values.singleWeight),
            unidades: Number(values.boxes),
          };
          const quote = await quoteDeliverySupplierOrder(payload);
          setQuotation(quote);
          setOpenShipping(true);
        } catch (e) {
          await confirmOrderSeeri(values);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          if (usedTrackingCode) {
            setIsLoading(false);
            return;
          }
          const { providerDeliveryStatus = "" } = values;
          const url =
            files?.length > 0 ? await uploadGuide(files[0]) : undefined;

          const supplierOrder = (await updateTrackingCodeDeliveryProvider(
            orderDetail.id,
            trackingCode ? trackingCode : orderDetail.subPurchaseNumber,
            url,
            dispatchValue,
            providerDeliveryStatus
          )) as any;
          showToastSuccess(t("ORDER_APPROVED"));
          setOrderDetail(supplierOrder);
          setOpen(false);
          onDone?.();
        } catch (error) {
          showToastError(error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const confirmOrderSeeri = async (values: any) => {
    try {
      const supplierOrder = (await updateSupplierOrder(
        orderDetail?.id ?? "",
        "approve",
        {
          amountBoxes: values?.boxes,
          warehouseId: values?.warehouseId,
          boxId: values?.singleBoxSize,
          weight: values?.singleWeight,
        }
      )) as any;
      showToastSuccess(t("ORDER_APPROVED"));
      setOrderDetail(supplierOrder);
      setIsLoading(false);
      setOpen(false);
    } catch (error: any) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  useEffect(() => {
    getAddresses(userId ?? "", 0);
  }, []);

  useEffect(() => {
    if (deliveryProviders?.length && !watch("dispatchValue")) {
      setValue("dispatchValue", deliveryProviders[0].code);
    }
  }, [deliveryProviders]);

  return (
    <>
      {!lightStyle && (
        <button
          onClick={() => setOpen(true)}
          className="primary"
          disabled={loadingOrders || !addresses.content}
        >
          {t("DETAILS.GENERATE_GUIDE")}
        </button>
      )}

      {lightStyle && (
        <ToolTip title={t("DETAILS.GENERATE_GUIDE")} position="Right">
          <span className="pointer circleButton" onClick={() => setOpen(true)}>
            <MdOutlineEdit />
          </span>
        </ToolTip>
      )}

      <Modal
        isOpen={open}
        onClose={() => {
          methods.reset();
          setOpen(false);
        }}
        size="sm"
        title={t("DETAILS.CONFIRM_DISPATCH")}
        footer={
          <>
            <button onClick={() => setOpen(false)} disabled={isLoading}>
              {t("DETAILS.CLOSE")}
            </button>
            {showForm && (
              <button
                className="primary lg"
                onClick={methods.handleSubmit(confirmOrder)}
                disabled={isLoading}
              >
                {t("DETAILS.DISPATCH")}
              </button>
            )}
          </>
        }
      >
        {showForm ? (
          <FormProvider {...methods}>
            <p className="bold">{`${t("DETAILS.ORDER_ID")} ${
              orderDetail?.subPurchaseNumber
            }`}</p>
            {orderDetail?.supplierId != user.id && (
              <>
                <div className="spacer" />
                <p>{t("DETAILS.CONFIRMATION_DETAIL")}</p>
              </>
            )}
            <div className="spacer" />
            <LogisticOptionsForm deliveryProviders={deliveryProviders ?? []} />

            <div className="spacer" />

            {methods.watch("dispatchValue") === "SEERI" && (
              <SeeriLogisticDispatchForm />
            )}

            {!!methods.watch("dispatchValue") &&
              !["SEERI", "SUPPLIER"].includes(
                methods.watch("dispatchValue")
              ) && (
                <ProviderLogisticDispatchForm
                  setIsLoading={setIsLoading}
                  setUsedTrackingCode={setUsedTrackingCode}
                  usedTrackingCode={usedTrackingCode}
                  files={files}
                  setFiles={setFiles}
                />
              )}
          </FormProvider>
        ) : (
          <>
            <p>{t("STILL_NO_LOGISTIC_PROVIDERS")}</p>
            <div className="spacer" />
            <div className="rigthAlign">
              <DeliveryProviderModal onDone={() => mutate()} />
            </div>
            <div className="spacer" />
          </>
        )}
      </Modal>

      <Modal
        title={t("DISPATCH_QUOTATION")}
        size="sm"
        isOpen={openShipping}
        onClose={() => {
          setOpenShipping(false);
        }}
        footer={
          <>
            <button
              onClick={() => {
                setOpenShipping(false);
                methods.setValue("dispatchValue", "SUPPLIER");
              }}
            >
              {t("USE_OWN_LOGISTIC")}
            </button>
            <button
              className="primary"
              onClick={methods.handleSubmit(confirmOrderSeeri)}
            >
              {t("DETAILS.DISPATCH")}
            </button>
          </>
        }
      >
        <>
          <p>
            <span className="bold">{t("DELIVERY_DAYS") + ": "}</span>
            <span>{quotation?.diasEntrega}</span>
          </p>
          <div className="spacer" />
          <p>
            <span className="bold">{t("FIXED_SHIPPING") + ": "}</span>
            <span>
              {quotation?.fleteFijo ? formatCurrency(quotation?.fleteFijo) : 0}
            </span>
          </p>
          <div className="spacer" />
          <p>
            <span className="bold">{t("VARIABLE_SHIPPING") + ": "}</span>
            <span>
              {quotation?.fleteVariable
                ? formatCurrency(quotation?.fleteVariable)
                : 0}
            </span>
          </p>
          <div className="spacer" />
          <p>
            <span className="bold">{t("TOTAL_SHIPPING") + ": "}</span>
            <span>
              {quotation?.fleteTotal
                ? formatCurrency(quotation?.fleteTotal)
                : 0}
            </span>
          </p>
          <div className="spacer" />
        </>
      </Modal>
    </>
  );
};

export default ConfirmOrderModal;
