import { isUSA } from "@/constants/core";
import { DATE_HOUR_SHORT, USA_DATE_HOUR_SHORT } from "@/constants/date";
import { paymentStatus, purchaseStatus } from "@/constants/purchases";
import { TPurchase } from "@/http";
import { formatCurrency } from "@/utils/currency";
import format from "date-fns/format";
import { useNavigate } from "react-router";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { exists } from "i18next";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { get } from "lodash-es";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { CheckboxButton } from "@/components/shared/Input/CheckboxButton";
import { usePurchases } from "@/store/purchases";
import clsx from "clsx";

type TPurchaseListRow = {
  purchase: TPurchase;
};
export const PurchaseListRow = ({ purchase }: TPurchaseListRow) => {
  const [globalT] = useTranslation("global");
  const [t] = useTranslation("purchases");
  const navigate = useNavigate();
  const isSale = purchase.type === "SELL_OUT";
  const parentRoute =
    purchase.type === "QUOTATION"
      ? "quotes"
      : purchase.type === "SELL_IN"
      ? "acquisitions"
      : "purchases";
  const { selectedPurchases, setSelectedPurchases } = usePurchases();

  const onClick = () => navigate(`/${parentRoute}/${purchase.id}/show`);

  const paymentProvider = get(
    purchase,
    "paymentProvider",
    get(purchase, "payments[0].paymentProvider")
  );

  return (
    <tr className={styles.purchaseRow} onClick={onClick}>
      {isSale && (
        <td>
          <CheckboxButton
            checked={Boolean(selectedPurchases[purchase.id])}
            onClick={event => {
              event.stopPropagation();
            }}
            onChange={() => {
              setSelectedPurchases(purchase);
            }}
          />
        </td>
      )}
      <td>
        <div className="flexColumn lh1">
          <span className="textLine">
            {format(
              new Date(purchase.createdAt),
              isUSA ? USA_DATE_HOUR_SHORT : DATE_HOUR_SHORT
            )}
          </span>
          {purchase.type !== "SELL_IN" && (
            <span className={clsx("textLine bold", styles.originFont)}>
              {purchase?.origin?.name
                ? globalT(
                    `PAYMENT_METHOD_CODE.${purchase?.origin?.name?.toUpperCase()}`
                  )
                : "-"}
            </span>
          )}
        </div>
      </td>

      <td>{purchase.purchaseNumber}</td>

      {purchase.type !== "SELL_IN" && (
        <>
          <td>
            <div className="flexGap lh1 leftAlign">
              <SellerResume seller={purchase?.client} isSlim />
            </div>
          </td>
        </>
      )}
      {purchase.type === "SELL_IN" && (
        <>
          <td>
            <div className="flexGap lh1 leftAlign">
              {purchase?.supplierName}
            </div>
          </td>
        </>
      )}
      <td>{formatCurrency(purchase.total)}</td>
      {purchase.type === "SELL_OUT" && (
        <>
          <td>
            <div className={isSale ? "flexColumn center leftAlign lh1" : ""}>
              {paymentProvider && (
                <span className="bold mdFont">
                  {exists(`global:PAYMENT_METHOD.PROVIDERS.${paymentProvider}`)
                    ? globalT(`PAYMENT_METHOD.PROVIDERS.${paymentProvider}`)
                    : purchase.paymentProvider}
                </span>
              )}
            </div>
          </td>
        </>
      )}

      {purchase.type !== "SELL_IN" && (
        <>
          <td>
            <div className="flexGap centerAlign">
              <span className="lh1">{purchase?.commercialName ?? "-"}</span>
            </div>
          </td>
        </>
      )}

      {purchase.type !== "QUOTATION" && (
        <>
          <td>
            <div className="flexColumn center leftAlign lh1 textLine">
              <p>
                <span
                  className={
                    ["PENDING"].includes(purchase.status)
                      ? "redText"
                      : ["DONE"].includes(purchase.status)
                      ? "greenText"
                      : ""
                  }
                >
                  {purchaseStatus.find(s => s.code === purchase.status)?.name}
                </span>
              </p>
              <p className="mdFont">
                <span className="bold">
                  {t("PURCHASE_DELIVERY_STATUS")}
                  {": "}
                </span>
                {purchase?.providerDeliveryStatusName}
              </p>
              <p className="mdFont">
                <span className="bold">
                  {t("PURCHASE_PAYMENT_STATUS")}
                  {": "}
                </span>
                {
                  paymentStatus.find(s => s.code === purchase.paymentStatus)
                    ?.name
                }
              </p>
              {isSale && (
                <p className="flexGap gapSm mdFont bold">
                  {purchase.hasInvoice ? (
                    <span className="greenText">
                      <BsFillCheckCircleFill />
                    </span>
                  ) : (
                    <span className="redText">
                      <BsFillXCircleFill />
                    </span>
                  )}
                  {t("INVOICED")}
                </p>
              )}
            </div>
          </td>
        </>
      )}
    </tr>
  );
};
