import { paymentStatus, purchaseStatus } from "@/constants/purchases";
import { QUOTED } from "@/http";
import { useTranslation } from "react-i18next";
import { TPurchaseShow } from "../PurchaseShow";
import styles from "./styles.module.scss";
import ConfirmOrderModal from "@/components/order-details/Dispatch/ConfirmOrderModal";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { useOrderDetails } from "@/store/order-detail";
import { UpdateProviderDeliveryStatus } from "@/components/order-details/InfoPanel/UpdateProviderDeliveryStatus";

export const PurchaseStatusInfo = ({ purchase }: TPurchaseShow) => {
  const { user } = useAuth();
  const [t] = useTranslation("purchases");
  const { getPurchase } = usePurchase();
  const { orderDetail } = useOrderDetails();
  const onDone = () => {
    getPurchase(purchase.id);
  };
  return (
    <div>
      <div className={styles.field}>
        <span className={styles.title}>{t("STATUS")}:</span>
        <span>
          {purchaseStatus.find(s => s.code === purchase.status)?.name}
        </span>
      </div>

      {purchase.status != QUOTED && (
        <>
          <div className={styles.field}>
            <span className={styles.title}>{t("SHIP")}:</span>
            <span>{purchase.providerDeliveryStatusName}</span>
            {orderDetail?.id && !orderDetail?.deliveryProvider && (
              <ConfirmOrderModal
                lightStyle
                orderDetail={orderDetail}
                userId={user.id}
                purchase={purchase}
                onDone={onDone}
              />
            )}
            {orderDetail?.id && orderDetail?.deliveryProvider && (
              <UpdateProviderDeliveryStatus onDone={onDone} />
            )}
          </div>

          <div className={`${styles.field} centerVAlign`}>
            <span className={styles.title}>{t("PAYMENT")}:</span>
            <span>
              {paymentStatus.find(s => s.code === purchase.paymentStatus)?.name}
            </span>
          </div>
        </>
      )}

      {!!purchase.shippingMethod && (
        <div className={styles.field}>
          <span className={styles.title}>{t("SHIPPING_METHOD")}:</span>
          <span className="textLine">{t(purchase?.shippingMethod)}</span>
        </div>
      )}
    </div>
  );
};
