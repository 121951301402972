import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OptionsSection } from "@/components/profile/product-options/OptionsSection";
import { CurvesSection } from "@/components/profile/product-options/CurvesSection";

export const CurvesPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");

  useEffect(() => {
    setTitle(t("CURVES"));
  }, [t]);

  return (
    <>
      <OptionsSection />
      <div className="spacer" />
      <CurvesSection />
    </>
  );
};
