import { PurchasesListSort } from "../PurchaseListActions/PurchasesListSort";
import { PurchaseTableFilters } from "../PurchaseListActions/PurchaseTableFilters";
import { PurchaseProcess } from "./PurchaseProcess";
import { PurchaseSupplierFilter } from "./PurchaseSupplierFilter";
import styles from "./styles.module.scss";
import RangeDatePicker from "@/components/orders/RangeDatePicker/RangeDatePicker";

type props = {
  type: "SELL_IN" | "SELL_OUT" | "QUOTATION";
};

export const PurchaseListFilters = ({ type }: props) => {
  return (
    <div className={styles.containerFilters}>
      <PurchaseProcess />
      <RangeDatePicker />
      <PurchaseSupplierFilter type={type} />
      <PurchasesListSort />
      <PurchaseTableFilters type={type} />
    </div>
  );
};
