import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteProductToPurchase, TProduct } from "@/http";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  orderId: string;
  product: TProduct;
  onSuccess?: () => void;
};
export const DeleteOrderProduct = ({ orderId, product, onSuccess }: Props) => {
  const [t] = useTranslation("global");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteProductToPurchase(orderId, product.orderProductId);
      setModalOpen(false);
      showToastSuccess(t("PRODUCT_DELETED"));
      onSuccess?.();
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={true}
        size="sm"
        onClose={() => onSuccess?.()}
        title={t("DELETE_PRODUCT")}
        footer={
          <>
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={onSubmit}
            >
              {t("IM_SURE")}
            </button>
          </>
        }
      >
        <p>{`${t("SURE_DELETE_PRODUCT")} ${product?.name}?`}</p>
      </Modal>
    </>
  );
};
