import { ProductDetailTableType } from "@/components/order-details/ProductsDetailsV2/ProducDetailTableType";
import styles from "./styles.module.scss";
import { SerialModal } from "@/components/products/ProductDetail/Serials/SerialsModal";
import { Card } from "@/components/shared/Card/Card";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { MultipleSelectionSerial } from "@/components/shared/Input/MultipleSelectionSerial";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { TSeller, TProductSerial, TUnitType, TProduct } from "@/http";
import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { hasPermission } from "@/utils/validations/permissions";
import clsx from "clsx";
import { ReactNode, useState, useMemo, useCallback, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

type props = {
  product: any;
  idx: number;
  showUnitType: boolean;
  useV4?: boolean;
  customActions?: ReactNode;
  isCreation?: boolean;
  tableColumns: ProductDetailTableType[];
};

export const SaleProductRowV2 = ({
  product,
  idx,
  showUnitType,
  tableColumns,
}: props) => {
  const [previewUnitRate, setPreviewUnitRate] = useState(
    product?.unitRate || 1
  );
  const { watch, setValue } = useFormContext();
  const { t } = useTranslation("global");
  const { user, isCommercial, role } = useAuth();
  const isSale = watch("purchaseType") === "SALE";
  const warehouseDispatch = watch("warehouseId");
  const methods = useFormContext();
  const seller = watch("seller") as TSeller | undefined;
  const warehouseId = useWatch({ name: `editProduct.warehouseId` });
  const unitRate = Number(useWatch({ name: `editProduct.unitRate` }) || 1);
  const wholesalePrice = useWatch({ name: `editProduct.wholesalePrice` });
  const curves = useWatch({ name: `editProduct.curves` });

  const canEditUnitPrice = hasPermission(isCommercial, role, [
    "action.purchase.product-price",
  ]);

  const canEditDiscount = hasPermission(isCommercial, role, [
    "action.purchase.discount",
  ]);

  const productSupplier = useMemo(
    () => ({ ...product, warehouses: product?.availableWarehouses }),
    [product]
  );

  const isCurvesAvailable = useMemo(
    () => product?.type === "GROUP" && product?.curves?.length > 0,
    [product]
  );

  const warehouse = useMemo(
    () =>
      productSupplier?.warehouses?.find(
        (w: any) => w.warehouseId === warehouseId
      ) ?? {
        id: warehouseId,
        name: user?.addresses?.find(a => a.id === warehouseId)?.name,
      },
    [warehouseId, productSupplier, user]
  );

  const isValidStock = useCallback(
    () =>
      (product?.stock || product?.availableStock) / unitRate > 0 ||
      productSupplier?.warehouses?.some(
        (w: any) =>
          w?.overStock && (w?.overStockAmount ?? 1000000) / unitRate > 0
      ),
    [product, productSupplier, unitRate]
  );

  const isProductWarehouseValid = useCallback(
    () =>
      warehouseDispatch
        ? productSupplier?.warehouses?.some(
            (w: any) => w?.warehouseId === warehouseDispatch
          )
        : true,
    [warehouseDispatch, productSupplier]
  );

  const setSerial = useCallback(
    (serial: TProductSerial) => {
      const currentSerial = watch(`editProduct.serials`) ?? [];
      setValue(`editProduct.serials`, [...currentSerial, serial]);
    },
    [idx, setValue, watch]
  );

  const getColspanCount = () => {
    let colspanCount = 7;

    if (showUnitType) colspanCount++;

    if (isSale && !warehouseDispatch) colspanCount++;

    if (hasPermission(isCommercial, role, ["action.purchase.discount"]))
      colspanCount++;

    if (user.allowSerials) colspanCount++;

    return colspanCount;
  };

  const getWarehouseName = (id: string) => {
    return user?.addresses?.find(a => a.id === id)?.name || "Unknown Warehouse";
  };

  useEffect(() => {
    setValue(`editProduct.productSupplier`, productSupplier);
    setValue(`editProduct.wholesalePrice`, product?.wholesalePrice);
    setValue(`editProduct.discountPercentage`, product?.discountPercentage);
    console.log(warehouseId, productSupplier?.warehouses?.length);
    if (!warehouseId && isSale && productSupplier?.warehouses?.length === 1) {
      setValue(
        `editProduct.warehouseId`,
        productSupplier?.warehouses?.[0]?.warehouseId
      );
    }
  }, [productSupplier, isSale, warehouseId, idx, setValue]);

  useEffect(() => {
    if (unitRate) {
      const newWholesalePrice = Number(
        ((wholesalePrice * unitRate) / previewUnitRate).toFixed(2)
      );
      const unit = product.unitTypes?.find(
        (u: any) => Number(u?.rate) === unitRate
      );
      setValue(`editProduct.unitType`, unit?.name);
      setValue(`editProduct.wholesalePrice`, newWholesalePrice);
      setPreviewUnitRate(unitRate);
    }
  }, [unitRate, product, idx, setValue]);

  useEffect(() => {
    if (curves?.length) {
      const getTotal = (item: any) => {
        const multiplier = Number(item.quantity) || 0;
        const sum = item.products.reduce(
          (acc: any, p: any) => acc + p.quantity,
          0
        );
        return sum * multiplier;
      };

      const finalTotal = curves
        .filter((c: any) => {
          if (!seller?.segments?.length) {
            return !c?.segmentId;
          }

          return seller?.segments.some(
            (s: any) => !c.segmentId || s.id === c.segmentId
          );
        })
        .filter((c: any) => !!c?.selected)
        .flatMap((c: any) => c)
        .reduce((acc: any, item: any) => acc + getTotal(item), 0);

      methods.setValue(`editProduct.quantity`, finalTotal);
    }
  }, [curves]);

  const tableRows: Record<
    string,
    (product: TProduct, idx: number) => JSX.Element
  > = {
    "unit-type": (product: TProduct, idx: number) => {
      return (
        <>
          {(product?.unitTypes ?? []).length > 0 ? (
            <SelectV2
              className={styles.smallInput}
              name={`editProduct.unitRate`}
              label=""
              required
              choices={(product?.unitTypes ?? [])
                .sort((a: any, b: any) => {
                  if (a?.isDefault === b?.isDefault) {
                    return 0;
                  }
                  return a?.isDefault ? -1 : 1;
                })
                .map((u: TUnitType) => ({
                  value: String(u?.rate),
                  label: u?.name,
                  disabled: warehouse
                    ? Number(
                        product?.partialUnitsAllowed
                          ? (warehouse?.availableStock / u?.rate).toFixed(2)
                          : Math.floor(warehouse?.availableStock / u?.rate)
                      ) < 1
                    : false,
                }))}
            />
          ) : (
            <>
              N/A
              <input
                type="hidden"
                value={1}
                {...methods.register(`editProduct.unitRate`)}
              />
            </>
          )}
        </>
      );
    },
    quantity: (product: TProduct, idx: number) => {
      return (
        <NumericInput
          className={styles.smallInput}
          name={`editProduct.quantity`}
          label=""
          required
          decimals={product?.partialUnitsAllowed}
          disabled={(isSale ? !warehouse?.id : false) || isCurvesAvailable}
          hideControls={isCurvesAvailable}
          max={
            isSale
              ? unitRate !== 1
                ? Math.floor(
                    Number(warehouse?.availableStock ?? 0) / unitRate +
                      (warehouse?.overStock
                        ? Number(warehouse?.overStockAmount ?? 1000000) /
                          unitRate
                        : 0)
                  )
                : Number(warehouse?.availableStock ?? 0) +
                  (warehouse?.overStock
                    ? Number(warehouse?.overStockAmount ?? 1000000)
                    : 0)
              : undefined
          }
          min={product?.partialUnitsAllowed ? 0.1 : 1}
        />
      );
    },
    "unit-price": (product: TProduct, idx: number) => {
      return (
        <>
          {canEditUnitPrice ? (
            <NumericInput
              className={styles.smallInput}
              name={`editProduct.wholesalePrice`}
              label=""
              decimals
              hideControls
              required
              disabled={!canEditUnitPrice}
            />
          ) : (
            formatCurrency(product?.wholesalePrice || 0)
          )}
        </>
      );
    },
    "unit-discount": (product: TProduct, idx: number) => {
      return (
        <>
          {canEditDiscount ? (
            <NumericInput
              className={styles.smallInput}
              name={`editProduct.discountPercentage`}
              label=""
              decimals
              hideControls
              max={100}
              disabled={!canEditDiscount}
              inputProps={{ className: styles.inputNumber }}
            />
          ) : (
            `${product?.discountPercentage || 0}%`
          )}
        </>
      );
    },
    warehouse: (product: TProduct, idx: number) => {
      return (
        <SelectV2
          className={styles.smallInput}
          name={`editProduct.warehouseId`}
          label=""
          required
          choices={(
            (product?.warehouses || product?.availableWarehouses) ??
            []
          ).map((w: any) => ({
            value: w.warehouseId,
            label: getWarehouseName(w.warehouseId),
          }))}
          infoLabel={
            warehouse?.id
              ? `${t("PURCHASES.ORDERS.STOCK_AVAILABLE")}: ${
                  product?.partialUnitsAllowed
                    ? (warehouse?.availableStock / unitRate).toFixed(2)
                    : Math.floor(warehouse?.availableStock / unitRate)
                }`
              : ""
          }
        />
      );
    },
    serials: (product: TProduct) => {
      return (
        <MultipleSelectionSerial
          className={styles.smallInput}
          slim
          disabled={!((methods.watch(`editProduct.quantity`) || 0) > 0)}
          maxOptions={methods.watch(`editProduct.quantity`) || 0}
          name={`editProduct.serials`}
          productId={product.id}
          infoLabel={
            (methods.watch(`editProduct.quantity`) || 0) > 0 && (
              <div className="sepe rigthAlign">
                <SerialModal productId={product.id} onDone={setSerial} slim />
              </div>
            )
          }
        />
      );
    },
  };
  return (
    <>
      <tr
        className={clsx(
          {
            [styles.warningProduct]:
              isSale &&
              (!isProductWarehouseValid() ||
                !productSupplier?.id ||
                !productSupplier?.status ||
                !isValidStock()),
          },
          styles.productsTableRow
        )}
      >
        {tableColumns.map((column: ProductDetailTableType) => {
          if (!column.visible) {
            return null;
          }

          return (
            <td key={column.key} className={column.className}>
              {column?.render && column?.render(product, idx)}
              {!column?.render && tableRows[column.key]?.(product, idx)}

              {}
            </td>
          );
        })}
      </tr>
      {isCurvesAvailable && (
        <tr
          className={clsx(
            {
              [styles.warningProduct]:
                isSale &&
                (!isProductWarehouseValid() ||
                  !productSupplier?.id ||
                  !productSupplier?.status ||
                  !isValidStock()),
            },
            styles.row
          )}
        >
          <td colSpan={getColspanCount()}>
            <div className="col-12 lh1">
              <Card className="noPadding">
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>{t("NAME")}</th>
                      {(product?.curveOptions || []).map(
                        (option: string, i: number) => (
                          <th key={i}>{option}</th>
                        )
                      )}
                      <th>{t("UNITS")}</th>
                      <th>{t("QUANTITY")}</th>
                      <th>{t("TOTAL")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(curves || []).map((curve: any, curveIndex: number) => {
                      const totalQuantity = (curve?.products || []).reduce(
                        (sum: number, p: any) => sum + Number(p?.quantity),
                        0
                      );
                      const quantity = methods.watch(
                        `editProduct.curves[${curveIndex}].quantity`
                      );

                      const selected = methods.watch(
                        `editProduct.curves[${curveIndex}].selected`
                      );
                      const segments = seller?.segments || [];
                      const segmentId = curve?.segmentId;

                      if (segments.length === 0 && segmentId) {
                        return null;
                      }

                      if (
                        segments.length &&
                        !segments.some(
                          (s: any) => !segmentId || s.id === segmentId
                        )
                      ) {
                        return null;
                      }

                      return (
                        <tr key={curve.id} className={styles.row}>
                          <td>
                            <div className="mt1">
                              <Checkbox
                                name={`editProduct.curves[${curveIndex}].selected`}
                                label={
                                  <span className="textLine">
                                    {methods.watch(
                                      `editProduct.curves[${curveIndex}].name`
                                    )}
                                  </span>
                                }
                              />
                            </div>
                          </td>
                          {(product?.curveOptions || []).map(
                            (option: any, optionIndex: number) => (
                              <td key={option} className={styles.number}>
                                <NumericInput
                                  name={`editProduct.curves[${curveIndex}].products[${optionIndex}].quantity`}
                                  hideControls
                                  min={0}
                                  label=""
                                  disabled
                                  required={selected}
                                />
                              </td>
                            )
                          )}
                          <td>{totalQuantity}</td>
                          <td>
                            <NumericInput
                              name={`editProduct.curves[${curveIndex}].quantity`}
                              hideControls
                              min={selected ? 1 : undefined}
                              label=""
                              required={selected}
                            />
                          </td>
                          <td className={styles.name}>
                            {formatCurrency(
                              (totalQuantity || 0) *
                                (wholesalePrice || 0) *
                                (quantity || 0)
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
