import { useTranslation } from "react-i18next";
import { MultipleMenu } from "@/components/shared/Menu/MultipleMenu";
import styles from "./styles.module.scss";
import { FaChevronDown } from "react-icons/fa";
import clsx from "clsx";
import { IoFilterSharp } from "react-icons/io5";
import { useEffect, useState } from "react";
import { getPurchaseListFilters } from "@/http";
import { useSearchParams } from "react-router-dom";
import { get } from "lodash-es";
import { usePurchases } from "@/store/purchases";

type props = {
  type: "SELL_IN" | "SELL_OUT" | "QUOTATION";
};

export const PurchaseTableFilters = ({ type }: props) => {
  const [filters, setFilters] = useState<any>({});

  const getFilters = () => {
    getPurchaseListFilters(type)
      .then(data => setFilters(data))
      .catch(() => setFilters({}));
  };
  useEffect(() => {
    getFilters();
  }, []);

  return (
    <>
      {Object.keys(filters ?? {}).length > 0 && (
        <FiltersMenu filtersList={filters} />
      )}
    </>
  );
};

const FiltersMenu = ({ filtersList = {} }: { filtersList: any }) => {
  const [search, setSearch] = useSearchParams();
  const statusFilters = JSON.parse(search.get("filterStatus") || "{}");
  const { clearSelectedPurchases } = usePurchases();

  const setStatusFilters = (key: string, value: string) => {
    const finalValue = get(statusFilters, key, "") === value ? "" : value;
    const payload = {
      ...statusFilters,
      [key]: finalValue,
    };

    const newSearch = new URLSearchParams(search);

    newSearch.set("filterStatus", JSON.stringify(payload));
    newSearch.set("page", "0");
    setSearch(newSearch);
    clearSelectedPurchases();
  };

  const [t] = useTranslation("orders-page");
  const currentFilters = Object.keys(statusFilters ?? {}).filter(
    f => !!statusFilters[f] && !["filterStatus"].includes(f)
  );
  return (
    <>
      <MultipleMenu
        className={styles.lineContent}
        label={
          <div className="col-12 noPadding">
            <p className="mdFont flexGap center">
              <IoFilterSharp />
              {t("FILTER.TITLE")}
            </p>
            <div className={clsx(styles.filterContainer, "contentInline")}>
              <span>
                {currentFilters.length === 0
                  ? t("FILTER.SELECT_FILTER")
                  : currentFilters
                      .map(cf => t("FILTER." + cf?.toUpperCase()))
                      .join(", ")}
              </span>
              <span>
                <FaChevronDown size={"0.7rem"} />
              </span>
            </div>
          </div>
        }
        values={Object.keys(filtersList ?? {})
          .filter(f => !["filterStatus"].includes(f) && !["status"].includes(f))
          .map(f => ({
            onSelect: (value: string) => setStatusFilters(f, value),
            title: t(`FILTER.${f.toUpperCase()}`),
            selectedValue: statusFilters[f] ?? "",
            options: Object.keys(filtersList[f] || {})
              .filter((o: string) => !!o)
              .map((o: string) => ({
                label: filtersList[f][o],
                value: o,
              })),
          }))}
      />
    </>
  );
};
