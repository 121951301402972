import { getInstance } from "../../core/axios-gateway";
import { TRestListParams, TRestListData } from "../../types/rest";
export * from "./order-products";

export const BULK_LOADS_BASE_PATH = "/api/bulk-loads";

export const getBulkLoadTypes = () =>
  getInstance()
    .get<BulkLoadType[]>(`${BULK_LOADS_BASE_PATH}/types`)
    .then(res => res.data);

export const generateBulkLoadTemplate = (payload: {
  resource: string;
  mode: BulkLoad["mode"];
  fields: string[];
  filters: Record<string, any>;
}): Promise<Blob> =>
  getInstance()
    .post(`${BULK_LOADS_BASE_PATH}/template`, payload, {
      responseType: "blob",
    })
    .then(res => res.data);

export const createBulkLoad = (payload: {
  excelUrl: string;
  files: { filename: string; url: string }[];
}) =>
  getInstance()
    .post(BULK_LOADS_BASE_PATH, payload)
    .then(res => res.data);

export const getBulkLoads = (params: TRestListParams) =>
  getInstance()
    .get<TRestListData<BulkLoad>>(BULK_LOADS_BASE_PATH, { params })
    .then(res => res.data);

export const getBulkLoad = (id: string) =>
  getInstance()
    .get<BulkLoad>(`${BULK_LOADS_BASE_PATH}/${id}`)
    .then(res => res.data);

export const getBulkLoadItems = (id: string, params: TRestListParams) =>
  getInstance()
    .get<TRestListData<BulkLoadItem>>(`${BULK_LOADS_BASE_PATH}/${id}/items`, {
      params,
    })
    .then(res => res.data);

export const cancelBulkLoad = (id: string) =>
  getInstance()
    .post(`${BULK_LOADS_BASE_PATH}/${id}/cancel`)
    .then(res => res.data);

export const processBulkLoadItem = (bulkLoadId: string, itemId: string) =>
  getInstance()
    .post(`${BULK_LOADS_BASE_PATH}/${bulkLoadId}/items/${itemId}/process`)
    .then(res => res.data);

export const downloadRetryTemplate = (bulkLoadId: string): Promise<Blob> =>
  getInstance()
    .post(`${BULK_LOADS_BASE_PATH}/${bulkLoadId}/template-for-retry`, null, {
      responseType: "blob",
    })
    .then(res => res.data);

export interface BulkLoadType {
  resource: string;
  availableModes: BulkLoad["mode"][];
  fields: BulkLoadTypeField[];
}

export interface BulkLoadTypeField {
  name: string;
  description?: string;
  humanName: string;
  requiredOnCreate?: boolean;
  required?: boolean;
  filter?: {
    type: "text" | "select";
    multiple: boolean;
    choices: Option[];
  };
}

export interface Option {
  id: string;
  name: string;
  quantity?: number;
}

export interface BulkLoad {
  id: string;
  resource: string;
  mode: "create" | "update";
  columns: string[];
  status: "in-progress" | "done" | "cancelled";
  total: number;
  progress: number;
  successCount: number;
  cancelledCount: number;
  date: string;
  estimatedStart?: string;
}

export interface BulkLoadItem {
  id: string;
  index: number;
  name: string;
  status: "pending" | "processing" | "error" | "success" | "cancelled";
  intents: number;
  data: Record<string, any>;
  recordId: string;
}
