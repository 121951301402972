import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineEdit } from "react-icons/md";
import { Modal } from "../shared/Modal/Modal";
import { InputV2 } from "../shared/Input/InputV2";
import { NumericInput } from "../shared/Input/NumericInput";
import { createSupplierSplit, updateSupplierSplit } from "@/http";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { formatDateToInput } from "@/utils/date";

type Props = {
  split?: any;
  onDone?: () => void;
};
export const SplitModal = ({ split, onDone }: Props) => {
  const [t] = useTranslation("global");
  const isEdit = !!split?.id;
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const defaultValues = isEdit
    ? { ...split, date: split?.date ? formatDateToInput(split?.date) : "" }
    : { alias: "", date: "", percentage: "" };
  const methods = useForm();

  const onClose = () => {
    setOpen(false);
    methods.reset(defaultValues);
  };

  const onOpen = () => {
    methods.reset(defaultValues);
    setOpen(true);
  };

  const onSubmit = async (values: any) => {
    try {
      const payload = {
        ...values,
        date: values.date + "T06:00:00Z",
      };
      if (isEdit) {
        const newOption = await updateSupplierSplit(split?.id, payload);
        methods.reset(newOption);
        showToastSuccess(t("SPLIT_UPDATED"));
      } else {
        methods.reset({});
        await createSupplierSplit(payload);
        showToastSuccess(t("SPLIT_CREATED"));
      }
      onDone?.();
      setOpen(false);
    } catch (e) {
      showToastError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isEdit ? (
        <span onClick={onOpen} className="circleButton pointer">
          <MdOutlineEdit />
        </span>
      ) : (
        <button onClick={onOpen} className="primary">
          + {t("ADD_SPLIT")}
        </button>
      )}
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        title={t(isEdit ? "EDIT_SPLIT" : "ADD_SPLIT")}
        size="sm"
        footer={
          <>
            <button disabled={isLoading} onClick={onClose}>
              {t("CANCEL")}
            </button>
            <button
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
              className="primary lg"
            >
              {t("SAVE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <InputV2 name="alias" label={t("SPLIT_ALIAS")} required />
          <InputV2 name="date" type="date" label={t("SPLIT_DATE")} required />
          <NumericInput
            hideControls
            name="percentage"
            label={t("SPLIT_PERCENTAGE")}
            required
            min={0}
            max={100}
          />
          <div className="spacer" />
        </FormProvider>
      </Modal>
    </>
  );
};
