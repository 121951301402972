import { InputV2 } from "@/components/shared/Input/InputV2";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TDeliveryProvider, TDeliveryStatusProvider } from "@/http";
import {
  createSupplierDeliveryProvider,
  createSupplierDeliveryStatusProvider,
  deleteSupplierDeliveryStatusProvider,
  getSupplierDeliveryProviderById,
  updateSupplierDeliveryProvider,
  updateSupplierDeliveryStatusProvider,
} from "@/http/resources/suppliers/delivery-provider";
import { validateNoSpaces } from "@/utils/keyboard";
import clsx from "clsx";
import { debounce, get } from "lodash-es";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineDelete,
} from "react-icons/ai";

import { MdOutlineEdit } from "react-icons/md";
import useSWR from "swr";

type TDeliveryProviderModal = {
  deliveryProvider?: TDeliveryProvider;
  onDone?: (dp: TDeliveryProvider) => void;
};

export const DeliveryProviderModal = ({
  deliveryProvider,
  onDone,
}: TDeliveryProviderModal) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isEdit = !!deliveryProvider?.id;
  const methods = useForm();
  const { data, mutate } = useSWR(
    isEdit ? [deliveryProvider?.id, "supplier-delivery-providers"] : null,
    getSupplierDeliveryProviderById
  );
  const deliveryStatusWatch = useWatch({
    control: methods.control,
    name: "deliveryStatus",
    defaultValue: [],
  });

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const { name, code, deliveryStatus = [] } = values;
      const deliveryStatusPayload = deliveryStatus.filter(
        (ds: any) => !ds?.deleted
      );
      const deliveryStatusDelete = deliveryStatus.filter(
        (ds: any) => ds?.deleted && ds?.id
      );

      let dp;
      if (isEdit) {
        dp = await updateSupplierDeliveryProvider(deliveryProvider.id ?? "", {
          name,
          code,
        });

        await Promise.all([
          ...deliveryStatusPayload.map(
            (ds: TDeliveryStatusProvider, position: number) =>
              ds?.id
                ? updateSupplierDeliveryStatusProvider(
                    deliveryProvider?.id ?? "",
                    ds.id,
                    {
                      providerDeliveryStatusName: ds.providerDeliveryStatusName,
                      position,
                    }
                  )
                : createSupplierDeliveryStatusProvider(
                    deliveryProvider?.id ?? "",
                    { ...ds, position }
                  )
          ),
          ...deliveryStatusDelete.map((ds: TDeliveryStatusProvider) =>
            deleteSupplierDeliveryStatusProvider(
              deliveryProvider?.id ?? "",
              ds.id ?? ""
            )
          ),
        ]);
      } else {
        dp = await createSupplierDeliveryProvider({
          name,
          code,
          deliveryStatus: deliveryStatusPayload,
        });
      }
      await mutate();
      onDone?.(dp);
      showToastSuccess(
        t(isEdit ? "DELIVERY_PROVIDER_UPDATED" : "DELIVERY_PROVIDER_CREATED")
      );
      setIsOpen(false);
    } catch (error) {
      showToastError(
        get(error, "message", "")
          ? error
          : { message: t("ERROR_SOME_BAD"), error }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const moveIndex = (idx: number, newIndex: number) => {
    const newDeliverStatus = [...deliveryStatusWatch];
    newDeliverStatus.splice(newIndex, 0, newDeliverStatus.splice(idx, 1)[0]);
    methods.setValue("deliveryStatus", newDeliverStatus);
  };

  useEffect(() => {
    methods.reset(data ?? { deliveryStatus: [{}] });
  }, [data]);

  useEffect(() => {
    const setData = async () => {
      await mutate();
      methods.reset(data ?? { deliveryStatus: [{}] });
    };
    if (isOpen) {
      setData();
    }
  }, [isOpen]);

  return (
    <>
      {isEdit ? (
        <span className="circleButton pointer" onClick={() => setIsOpen(true)}>
          <MdOutlineEdit />
        </span>
      ) : (
        <button className="primary" onClick={() => setIsOpen(true)}>
          {"+ " + t("ADD_DELIVERY_PROVIDER")}
        </button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t(isEdit ? "EDIT_DELIVERY_PROVIDER" : "ADD_DELIVERY_PROVIDER")}
        footer={
          <>
            <button disabled={isLoading} onClick={() => setIsOpen(false)}>
              {t("CANCEL")}
            </button>
            <button
              className="primary"
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t(isEdit ? "SAVE" : "ADD")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <InputV2
            label={t("NAME_DELIVERY_PROVIDER")}
            infoLabel={t("NAME_DELIVERY_PROVIDER_INFO")}
            name="name"
            required
          />
          <InputV2
            label={t("CODE")}
            name="code"
            onKeyPress={validateNoSpaces}
            required
            disabled={isEdit}
            infoLabel={t("CODE_DELIVERY_INFO")}
          />
          {deliveryStatusWatch.map(
            (
              ds: TDeliveryStatusProvider & { deleted: boolean },
              idx: number
            ) => (
              <div key={idx}>
                <p className="bold flexGap center">
                  {t("DELIVERY_STATUS_TITLE") + " " + (idx + 1)}{" "}
                  {!ds.deleted && (
                    <span className="pointer circleButton">
                      <AiOutlineDelete
                        onClick={() => {
                          methods.setValue(
                            `deliveryStatus[${idx}].deleted`,
                            true
                          );
                        }}
                      />
                    </span>
                  )}
                  {idx !== 0 && deliveryStatusWatch.length > 1 && (
                    <span className="pointer circleButton">
                      <AiOutlineArrowUp
                        onClick={() => {
                          moveIndex(idx, idx - 1);
                        }}
                      />
                    </span>
                  )}
                  {deliveryStatusWatch.length > 1 &&
                    deliveryStatusWatch.length - 1 != idx && (
                      <span className="pointer circleButton">
                        <AiOutlineArrowDown
                          onClick={() => {
                            moveIndex(idx, idx + 1);
                          }}
                        />
                      </span>
                    )}
                </p>
                {ds?.deleted && (
                  <p className="flexGap">
                    {t("ITEM_DELETED")}
                    <span
                      className="purpleText textUnderline bold pointer"
                      onClick={() => {
                        methods.setValue(
                          `deliveryStatus[${idx}].deleted`,
                          false
                        );
                      }}
                    >
                      {t("UNDO")}
                    </span>
                  </p>
                )}

                <div
                  className={clsx("flexGap flexSmColumn ", {
                    ["dNone"]: ds?.deleted,
                  })}
                >
                  <SelectV2
                    label={t("DELIVERY_STATUS")}
                    name={`deliveryStatus[${idx}].deliveryStatus`}
                    disabled={!!ds?.id}
                    choices={[
                      { value: "PENDING", label: t("PENDING") },
                      { value: "IN_PROCESS", label: t("IN_PROCESS") },
                      { value: "IN_TRANSIT", label: t("IN_TRANSIT") },
                      { value: "DELIVERED", label: t("DELIVERED") },
                      { value: "CANCELLED", label: t("CANCELLED") },
                    ]}
                    required={!ds.deleted}
                  />
                  <InputV2
                    label={t("PROVIDER_DELIVERY_STATUS")}
                    name={`deliveryStatus[${idx}].providerDeliveryStatus`}
                    disabled={!!ds?.id}
                    required={!ds.deleted}
                    onKeyPress={validateNoSpaces}
                    infoLabel={t("PROVIDER_DELIVERY_STATUS_INFO")}
                  />
                  <InputV2
                    label={t("PROVIDER_DELIVERY_STATUS_NAME")}
                    name={`deliveryStatus[${idx}].providerDeliveryStatusName`}
                    required={!ds.deleted}
                  />
                </div>

                {idx + 1 !== deliveryStatusWatch.length && (
                  <div className="divider" />
                )}
              </div>
            )
          )}
          <div className="spacer" />
          <button
            className="primary"
            onClick={() => {
              methods.setValue("deliveryStatus", [...deliveryStatusWatch, {}]);
              const focus = debounce(() => {
                methods.setFocus(
                  `deliveryStatus[${deliveryStatusWatch.length}].deliveryStatus`
                );
              }, 300);
              focus();
            }}
          >
            + {t("ADD_DELIVERY_STATUS")}
          </button>
        </FormProvider>
      </Modal>
    </>
  );
};
