import SearchFilter from "@/components/orders/IdFilter/SearchFilter";
import { PurchaseList } from "@/components/purchases/PurchaseList/PurchaseList";
import { PurchaseListActions } from "@/components/purchases/PurchaseList/PurchaseListActions/PurchaseListActions";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const PurchasesListPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");

  useEffect(() => {
    setTitle(t("PURCHASES.LIST.TITLE"), "", "", <SearchFilter />);
  }, [t]);

  return (
    <>
      <PurchaseListActions type={"SELL_OUT"} />
      <PurchaseList type={"SELL_OUT"} />
    </>
  );
};
