import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Modal } from "@/components/shared/Modal/Modal";

import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TClient, TSeller, unlinkSupplierSeller } from "@/http";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import DeleteActionButton from "../ActionButtons/DeleteActionButton";

type TClientDelete = {
  client: TClient | TSeller;
  onDone: () => void;
};
export const ClientDelete = ({ client, onDone }: TClientDelete) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const [t] = useTranslation("global");

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await unlinkSupplierSeller(client.id, user.id);
      setIsLoading(false);
      setModalOpen(false);
      onDone();
      showToastSuccess(t("CLIENTS.CLIENT_DELETED"));
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <>
      <DeleteActionButton
        iconButton
        onAction={() => {
          console.log("action");
          setModalOpen(true);
        }}
      />
      <Modal
        title={t("CLIENTS.DELETE_CLIENT")}
        isOpen={isModalOpen}
        size="sm"
        onClose={() => {
          setModalOpen(false);
        }}
        footer={
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        }
      >
        {`${t("CLIENTS.SURE_DELETE_CLIENT")}?`}
        {<SellerResume seller={client} isSlim />}
      </Modal>
    </>
  );
};
