import { TPaymentMethod } from "@/http/types/payment-methods";
import { getInstance } from "../../core/axios-gateway";
import { TLocation } from "../../types/location";
import {
  TPrePurchase,
  TPrePurchasePayload,
  TPurchase,
  TPurchasePayload,
  TRequestPurchaseCancellation,
  TRequestPurchaseDeliveryStatus,
  TRequestPurchasePaymentMethod,
  TRequestPurchasePaymentStatus,
  TRequestPurchasePaymentVouchers,
  TRequestPurchaseProduct,
  TRequestPurchaseProductsUpdate,
  TRequestPurchaseProductTracking,
  TRequestPurchaseShipping,
  TRequestSubPurchaseProductUpdate,
  TResponsePurchaseComment,
  TResponsePurchasePaymentVouchers,
  TVoucher,
} from "../../types/purchase";
import { TRestListData, TRestListParams } from "../../types/rest";
import { AxiosRequestHeaders } from "axios";

const BASE_PATH = "/api/orders";
const BASE_PATH_V2 = "/api/orders/v2";

export { getPurchaseCancellationReasons } from "./cancellation";
export * from "./subpurchases";

export const getPurchaseInvoicePDF = async (purchaseId: string, data: any) => {
  return getInstance()
    .post(`api/sreports/${purchaseId}`, data, { responseType: "blob" })
    .then(res => res.data);
};

export const getQuotationExport = async (search: string) => {
  return getInstance()
    .post(
      `api/sreports/batch`,
      {},
      { responseType: "blob", params: { search } }
    )
    .then(res => res.data);
};

export const getSubPurchaseInvoicePDF = async (
  subPurchaseId: string,
  data: any
) => {
  return getInstance()
    .post(`api/sreports/dispatch-order/${subPurchaseId}`, data, {
      responseType: "blob",
    })
    .then(res => res.data);
};

export const getSubPurchaseMultipleInvoice = async (data: any) => {
  return getInstance()
    .post("api/sreports/dispatch-orders", data, {
      responseType: "blob",
    })
    .then(res => res.data);
};

export const getPurchaseMultipleInvoice = async (data: any) => {
  return getInstance()
    .post("api/sreports/orders", data, {
      responseType: "blob",
    })
    .then(res => res.data);
};

export const getCreditMemoPDF = async (creditMemoId: string) => {
  return getInstance()
    .post(
      `api/sreports/credit-memo/${creditMemoId}`,
      {},
      { responseType: "blob" }
    )
    .then(res => res.data);
};

export const getPurchaseOrderPDF = async (purchaseId: string) => {
  return getInstance()
    .post(`api/sreports/purchase-order/${purchaseId}`, null, {
      responseType: "blob",
    })
    .then(res => res.data);
};

export const getPurchaseListFilters = async (type: string) => {
  return getInstance()
    .get(`${BASE_PATH}/filters/v2`, { params: { type } })
    .then(res => res.data);
};

export const getPurchases = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TPurchase>>(BASE_PATH, { params })
    .then(res => res.data);
};

export const getPurchasesByIds = async (ids: string[]) => {
  return getInstance()
    .post<TPurchase[]>(`${BASE_PATH}/by-ids`, { ids })
    .then(res => res.data);
};

export const getPurchaseById = async (purchaseId: string) => {
  if (!purchaseId) {
    return;
  }
  return getInstance()
    .get<TPurchase>(`${BASE_PATH}/${purchaseId}`)
    .then(res => res.data);
};

export const getPurchasesBySellerId = async (sellerId: string) => {
  return getInstance()
    .get<TPurchase[]>(`${BASE_PATH}/${sellerId}`)
    .then(res => res.data);
};

export const createPurchase = async (
  purchase: Partial<TPurchasePayload>,
  headers?: AxiosRequestHeaders
) => {
  return getInstance()
    .post<TPurchase>(BASE_PATH_V2, purchase, { headers })
    .then(res => res.data);
};

export const updatePurchaseProducts = async (
  purchaseId: string,
  data: TRequestPurchaseProductsUpdate
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/updateProducts`, data)
    .then(res => res.data);
};

export const updatePurchaseInvoiceStatus = async (
  purchaseId: string,
  hasInvoice: boolean
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/invoice`, { hasInvoice })
    .then(res => res.data);
};

export const updateProductTracking = async (
  purchaseId: string,
  data: TRequestPurchaseProductTracking[]
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/updateTracking`, data)
    .then(res => res.data);
};

export const postPurchasePaymentLink = async (purchaseId: string) => {
  return getInstance()
    .post<TPurchase>(`${BASE_PATH}/paymentLink`, { purchaseId })
    .then(res => res.data);
};

export const createPrePurchasev3 = async (data: TPrePurchasePayload) => {
  return getInstance()
    .post<TPrePurchase>(`${BASE_PATH}/v3/prepurchasedetail`, data)
    .then(res => res.data);
};

export const createPrePurchase = async (data: TPrePurchasePayload) => {
  return getInstance()
    .post<TPrePurchase>(`${BASE_PATH}/v4/prepurchasedetail`, data, {
      headers: {
        "x-log": "omit",
      },
    })
    .then(res => res.data);
};

export const createPrePurchaseV5 = async (
  data: TPrePurchasePayload,
  headers: AxiosRequestHeaders = {} as any
) => {
  return getInstance()
    .post<TPrePurchase>(`${BASE_PATH}/v5/prepurchasedetail`, data, {
      headers: {
        "x-log": "omit",
        ...headers,
      },
    })
    .then(res => res.data);
};

export const updatePurchasePaymentStatus = async (
  purchaseId: string,
  data: TRequestPurchasePaymentStatus
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/payment-status`, data)
    .then(res => res.data);
};

export const updatePurchaseDeliveryStatus = async (
  purchaseId: string,
  data: TRequestPurchaseDeliveryStatus
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/update/deliveryStatus`, data)
    .then(res => res.data);
};

export const updatePurchasePaymentMethod = async (
  purchaseId: string,
  data: TRequestPurchasePaymentMethod
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/payment-method`, data)
    .then(res => res.data);
};

export const updatePurchaseShipping = async (
  purchaseId: string,
  data: TRequestPurchaseShipping
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/shipping`, data)
    .then(res => res.data);
};

export const updatePurchaseClientLocation = async (
  purchaseId: string,
  data: TLocation
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/update/clientLocation`, data)
    .then(res => res.data);
};

export const addPurchaseComment = async (
  purchaseId: string,
  message: string
) => {
  return getInstance()
    .post<TResponsePurchaseComment>(
      `${BASE_PATH}/${purchaseId}/comments`,
      { message },
      {
        headers: {
          "x-log": "omit",
        },
      }
    )
    .then(res => res.data);
};

export const deletePurchaseComment = async (
  purchaseId: string,
  commentId: string
) => {
  return getInstance()
    .delete<TResponsePurchaseComment>(
      `${BASE_PATH}/${purchaseId}/comments/${commentId}`,
      {
        headers: {
          "x-log": "omit",
        },
      }
    )
    .then(res => res.data);
};

export const updatePurchaseComment = async (
  purchaseId: string,
  commentId: string,
  message: string
) => {
  return getInstance()
    .put<TResponsePurchaseComment>(
      `${BASE_PATH}/${purchaseId}/comments/${commentId}`,
      { message },
      {
        headers: {
          "x-log": "omit",
        },
      }
    )
    .then(res => res.data);
};

export const addPurchaseObservations = async (
  purchaseId: string,
  observations: string
) => {
  return getInstance()
    .put<TPurchase>(
      `${BASE_PATH}/${purchaseId}/observations`,
      { observations },
      {
        headers: {
          "x-log": "omit",
        },
      }
    )
    .then(res => res.data);
};

export const updatePurchasePaymentVouchers = async (
  purchaseId: string,
  data: TRequestPurchasePaymentVouchers
) => {
  return getInstance()
    .post<TResponsePurchasePaymentVouchers>(
      `${BASE_PATH}/${purchaseId}/voucher`,
      data
    )
    .then(res => res.data);
};

export const updatePurchasePaymentVouchersV2 = async (
  purchaseId: string,
  data: TRequestPurchasePaymentVouchers
) => {
  return getInstance()
    .post<TResponsePurchasePaymentVouchers>(
      `${BASE_PATH}/${purchaseId}/voucher/v2`,
      data
    )
    .then(res => res.data);
};

export const deletePurchasePaymentVoucherV2 = async (
  purchaseId: string,
  data: string[]
) => {
  return getInstance()
    .delete<TResponsePurchasePaymentVouchers>(
      `${BASE_PATH}/${purchaseId}/voucher/v2`,
      { data }
    )
    .then(res => res.data);
};

export const getPurchasePaymentVouchers = async (purchaseId: string) => {
  return getInstance()
    .get<TResponsePurchasePaymentVouchers>(`${BASE_PATH}/${purchaseId}/voucher`)
    .then(res => res.data);
};

export const updatePurchaseProductsAndTrackingCode = async (
  purchaseId: string,
  products: TRequestSubPurchaseProductUpdate[],
  trackingCode: string,
  deliveryProvider: string
) => {
  return getInstance()
    .put(`${BASE_PATH}/update/trackingCode`, {
      purchaseId,
      products,
      trackingCode,
      deliveryProvider,
    })
    .then(res => res.data);
};

export const createQuote = async (
  data: {
    userType: string;
    products: TRequestPurchaseProduct[];
    couponCode: string;
    firstSale?: boolean;
    forVip?: boolean;
  },
  headers: AxiosRequestHeaders = {} as any
) => {
  return getInstance()
    .post<TPrePurchase>(`${BASE_PATH}/quote-price`, data, {
      headers: {
        "x-log": "omit",
        ...headers,
      },
    })
    .then(res => res.data);
};

export const batchPurchasesStockSync = async () => {
  return getInstance().post("/api/stock-sync/purchases");
};

export const updatePurchasePriority = async (
  purchaseId: string,
  priority: number
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/update/priority`, { priority })
    .then(res => res.data);
};

export const getPurchaseModification = async (purchaseId: string) => {
  return getInstance()
    .get(`${BASE_PATH}/${purchaseId}/timeline`)
    .then(res => res.data);
};

export const retryPurchaseShipping = async (purchaseId: string) => {
  return getInstance()
    .post(`${BASE_PATH}/${purchaseId}/retry`)
    .then(res => res.data);
};

export const createPurchaseFromQuote = async (
  purchaseId: string,
  values: any
) => {
  return getInstance()
    .post(`${BASE_PATH}/${purchaseId}/create`, values)
    .then(res => res.data);
};

export const deleteSupplierQuote = async (purchaseId: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${purchaseId}`)
    .then(res => res.data);
};

export const getLastCartSeller = async (sellerId: string) => {
  return getInstance()
    .get(`${BASE_PATH}/cart/${sellerId}`)
    .then(res => res.data);
};

export const confirmSupplierDelivery = async (
  purchaseId: string,
  payload: any
) => {
  return getInstance()
    .post(`${BASE_PATH}/${purchaseId}/buy/v2`, payload)
    .then(res => res.data);
};

export const getPurchaseVouchers = async (purchaseId: string) => {
  if (!purchaseId) {
    return;
  }
  return getInstance()
    .get<TVoucher[]>(`${BASE_PATH}/${purchaseId}/voucher`)
    .then(res => res.data);
};

export type TCreateVoucherRequest = {
  url: string;
  amount: number;
};

export const addVoucher = async (
  purchaseId: string,
  payload: TCreateVoucherRequest[]
) => {
  return getInstance()
    .post<TVoucher>(`${BASE_PATH}/${purchaseId}/voucher/v2`, payload)
    .then(res => res.data);
};

export const getPurchaseAvailblePaymentMethods = async (purchaseId: string) => {
  return getInstance()
    .get<TPaymentMethod[]>(`${BASE_PATH}/${purchaseId}/payment-methods`)
    .then(res => res.data);
};

export const cancelPurchase = async (
  purchaseId: string,
  data: TRequestPurchaseCancellation
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/cancelPurchase`, data)
    .then(res => res.data);
};

export const deletePurchase = async (purchaseId: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${purchaseId}`)
    .then(res => res.data);
};

export const addProductToPurchase = async (
  purchaseId: string,
  payload: any[]
) => {
  return getInstance()
    .post<any>(`${BASE_PATH}/${purchaseId}/products`, payload)
    .then(res => res.data);
};

export const updateProductToPurchase = async (
  purchaseId: string,
  purchaseProductId: string,
  payload: any
) => {
  return getInstance()
    .put<any>(
      `${BASE_PATH}/${purchaseId}/products/${purchaseProductId}`,
      payload
    )
    .then(res => res.data);
};

export const deleteProductToPurchase = async (
  purchaseId: string,
  purchaseProductId: string
) => {
  return getInstance()
    .delete<any>(`${BASE_PATH}/${purchaseId}/products/${purchaseProductId}`)
    .then(res => res.data);
};
