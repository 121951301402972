import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteCreditMemoById } from "@/http";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { useTranslation } from "react-i18next";

type TDeleteCreditMemo = {
  transaction: any;
  onDone: () => void;
};
export const DeleteCreditMemo = ({
  transaction,
  onDone,
}: TDeleteCreditMemo) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("global");

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteCreditMemoById(transaction?.id ?? "");
      onDone?.();
      setModalOpen(false);
      showToastSuccess(t("CREDIT_MEMO_DELETED"));
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <span onClick={() => setModalOpen(true)} className="circleButton pointer">
        <AiOutlineDelete />
      </span>
      <Modal
        isOpen={isModalOpen}
        size="sm"
        onClose={() => setModalOpen(false)}
        title={t("DELETE_CREDIT_MEMO")}
        footer={
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        }
      >
        <p>{t("SURE_DELETE_CREDIT_MEMO")}</p>
      </Modal>
    </>
  );
};
