import {  } from "@react-google-maps/api";
import { TSupplierAddress } from "@/http";

export const addressToString = (
  address: Partial<TSupplierAddress> | Partial<TSupplierAddress>,
  includeName?: boolean
) => {
  return `${address?.name && includeName ? address.name + ", " : ""}${
    address?.addressLine1 ?? ""
  } ${address?.addressLine2 ?? ""} - ${address?.city ?? ""}, ${
    address?.state ?? ""
  }, ${address?.country ?? ""}`;
};

export const getCoordinatesFromAddress = async ({
  country,
  state,
  city,
  street,
}: {
  country: string;
  state: string;
  city: string;
  street?: string;
}): Promise<google.maps.LatLngLiteral | null> => {
  const addressParts = [street, city, state, country].filter(Boolean);
  const fullAddress = addressParts.join(", ");

  const geocoder = new window.google.maps.Geocoder();

  return new Promise((resolve) => {
    geocoder.geocode({ address: fullAddress }, (results, status) => {
      if (status === "OK" && results != null && results.length > 0) {
        const location = results[0].geometry.location;
        resolve({
          lat: location.lat(),
          lng: location.lng(),
        });
      } else {
        resolve(null);
      }
    });
  });
};


export const getAddressFromCoordinates = async (
  position: google.maps.LatLngLiteral
): Promise<string | null> => {
  const geocoder = new window.google.maps.Geocoder();

  return new Promise((resolve) => {
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === "OK" && results != null && results.length > 0) {
        resolve(results[0].formatted_address);
      } else {
        resolve(null);
      }
    });
  });
};