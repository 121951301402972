import { useState } from "react";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TSupplierVariantPP } from "@/models/order-details";
import { useAuth } from "@/store/auth";
import { validateOnlyNumber } from "@/utils/keyboard";
import { TProductSupplier, TSupplier, updateProductV2 } from "@/http";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useProducts } from "@/store/products";
import { ImagesVariant } from "./ImagesVariant";
import { RecordCustomAttributes } from "@/components/custom-fields/RecordCustomAttributes";
import { VariantsPricesInputs } from "../ProductsForm/VariantPricesInputs";
import { ProductSegments } from "@/components/segments/ProductSegments";
import { ProductDetailsIntegrations } from "./ProductIntegrations";
import { CURRENT_COUNTRY, isUSA } from "@/constants/core";
import { VariantProcurementList } from "@/pages/Products/VariantProcurementList";
import { DeliveryTimes } from "../Variants/Show/DeliveryTimes";
import { ProductStockDetail } from "../ProductDetail/ProductStockDetail";
import { Card } from "@/components/shared/Card/Card";
import { UnitTypes } from "../ProductDetail/UnitTypes/UnitTypes";
import { Serials } from "../ProductDetail/Serials/Serials";
import { CategoryTree } from "../ProductsForm/CategoryTree";
import { KitProductsDetail } from "../ProductDetail/KitProductsDetail";
import { AiOutlineEdit } from "react-icons/ai";
import { formatDateToInput } from "@/utils/date";

type TFormEditVariant = {
  onDone?: (variant: TSupplierVariantPP) => void;
  isSupplierBrand?: boolean;
};

export const FormEditVariant = ({
  isSupplierBrand = false,
}: TFormEditVariant) => {
  const [t] = useTranslation("products-page");
  const { user } = useAuth();
  const { selectedVariantEdit, setSelectedVariant } = useProducts();
  const [isLoading, setLoading] = useState(false);
  const [enableEditing, setEnableEditing] = useState(false);
  const [enableCustomFields, setEnableCustomFields] = useState(false);

  const supplierProduct: TProductSupplier =
    selectedVariantEdit?.suppliers?.find(
      ({ supplierId }: TSupplier) => supplierId === user.id
    ) || {};

  const defaultValues = {
    ...supplierProduct,
    name: supplierProduct.name ?? selectedVariantEdit?.name,
    description:
      supplierProduct.description ?? selectedVariantEdit?.description,
    barCode: supplierProduct.barCode ?? selectedVariantEdit.barCode,
    product: selectedVariantEdit,
    retailPrice: selectedVariantEdit?.retailPrice,
    isMarketplace: selectedVariantEdit?.isMarketplace,
    categoryId: selectedVariantEdit?.category?.id,
    categoryName: selectedVariantEdit?.category?.name,
    overStock: supplierProduct?.warehouses?.reduce((acc, item) => {
      acc[item.id] = {
        overStock: !!item?.overStock,
        overStockAmount: item?.overStockAmount ?? 1000000,
      };
      return acc;
    }, {}),
    retailIntroductionDate: selectedVariantEdit?.retailIntroductionDate
      ? formatDateToInput(selectedVariantEdit?.retailIntroductionDate)
      : "",
  };

  const methods = useForm<any>({ defaultValues });
  const methodsCustoms = useForm<any>({
    defaultValues: { customAttributes: defaultValues?.customAttributes },
  });

  const onDisableCustomFields = () => {
    const { customAttributes } = selectedVariantEdit;
    methods.setValue("customAttributes", customAttributes);
    setEnableCustomFields(false);
  };

  const onSubmitCustomFields = async (values: any) => {
    try {
      setLoading(true);
      const newProduct = await updateProductV2(selectedVariantEdit?.id, values);
      setSelectedVariant(newProduct);
      methods.reset({ customAttributes: newProduct.customAttributes });
      showToastSuccess(t("PRODUCT_UPDATED"));
      setEnableCustomFields(false);
    } catch (e) {
      showToastError(e);
    } finally {
      setLoading(false);
    }
  };

  const isProductGift = () => {
    return ["GIFT"].includes(selectedVariantEdit?.type);
  };

  const isGroup = () => {
    return ["GROUP"].includes(selectedVariantEdit?.type);
  };

  const integrations = selectedVariantEdit?.suppliers?.[0]?.integrations;

  return (
    <Card borderTop>
      <div className="flexGap">
        <div className="col-12 noPadding">
          <FormProvider {...methods}>
            <div className="col-12 noPadding">
              {(!enableEditing || !isSupplierBrand) && (
                <ImagesVariant
                  enableEditing={enableEditing}
                  isSupplierBrand={isSupplierBrand}
                  setEnableEditing={setEnableEditing}
                />
              )}
              <div
                className={`${
                  !enableEditing || !isSupplierBrand ? "col-7" : "col-12"
                } col-sm-12 ${styles.containerForm}`}
              >
                <div className="col-12 noPaddingH">
                  <p className="bold">{t("PRODUCT.INVENTORY")}</p>
                </div>
                <div className="col-12 noPadding">
                  {!isUSA && (
                    <div className="col-6 col-sm-12 noPaddingH">
                      <InputV2
                        name="supplierSku"
                        label={t("PRODUCT.DETAIL.SKU")}
                        type="text"
                        disabled
                      />
                    </div>
                  )}
                  <div className="col-6 col-sm-12 noPaddingH">
                    <InputV2
                      name="product.barCode"
                      label={t("PRODUCT.BAR_CODE")}
                      type="text"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 noPadding">
                  <div className="col-6 col-sm-12 noPaddingH">
                    <InputV2
                      name="securityStock"
                      label={t("PRODUCT.SECURITY_STOCK")}
                      type="text"
                      onKeyPress={validateOnlyNumber}
                      disabled
                    />
                  </div>
                  <div className="col-6 col-sm-12 noPaddingH">
                    {isGroup() && (
                      <InputV2
                        name="retailIntroductionDate"
                        label="RID"
                        type="date"
                        disabled
                      />
                    )}
                  </div>
                </div>

                <div className="col-12 noPadding">
                  <div className="col-6 col-sm-12 noPaddingH">
                    <CategoryTree disabled={!enableEditing} hideCreation />
                  </div>
                </div>

                {!isProductGift() ? (
                  <VariantsPricesInputs
                    brandId={selectedVariantEdit?.brandId}
                    disabled={!enableEditing}
                  />
                ) : null}
                <div className="col-12 noPadding">
                  <div className="col-6 col-sm-12 noPaddingH">
                    <InputV2
                      name="departureStock"
                      label={t("DEPARTURE_STOCK")}
                      type="text"
                      disabled
                    />
                  </div>
                  <div className="col-6 col-sm-12 noPaddingH">
                    <InputV2
                      name="reservedStock"
                      label={t("RESERVED_STOCK")}
                      type="text"
                      disabled
                    />
                  </div>
                </div>
                {!isProductGift() && (
                  <ProductSegments
                    productSupplier={supplierProduct as TProductSupplier}
                  />
                )}
              </div>

              {["KIT", "GROUP"].includes(selectedVariantEdit.type) && (
                <div className="col-12 noPadding mt1">
                  <KitProductsDetail product={selectedVariantEdit} />
                </div>
              )}

              {!enableEditing && (
                <>
                  {selectedVariantEdit.type !== "KIT" && (
                    <div className="col-12 noPadding">
                      <div className="spacer" />
                      <UnitTypes
                        unitTypes={selectedVariantEdit?.unitTypes ?? []}
                      />
                    </div>
                  )}
                  {user.allowSerials && (
                    <>
                      <div className="col-12 noPadding">
                        <div className="spacer" />
                      </div>
                      <div className="col-12 noPadding">
                        <Serials />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </FormProvider>
          <FormProvider {...methodsCustoms}>
            <div className="col-12 noPadding">
              <div className="col-12 noPadding mt1">
                <form
                  autoComplete="off"
                  onSubmit={methodsCustoms.handleSubmit(onSubmitCustomFields)}
                >
                  <RecordCustomAttributes
                    appliesTo="PRODUCTS"
                    record={supplierProduct}
                    forForm={enableCustomFields}
                    additionalAction={
                      !enableCustomFields && (
                        <button onClick={() => setEnableCustomFields(true)}>
                          <AiOutlineEdit />
                        </button>
                      )
                    }
                  />
                  {enableCustomFields && (
                    <div className="col-12 noPadding mt1">
                      <div className="spacer" />
                      <div className="rigthAlign">
                        <button
                          type="button"
                          onClick={onDisableCustomFields}
                          disabled={isLoading}
                        >
                          {t("CANCEL")}
                        </button>
                        <button
                          type="submit"
                          className="primary lg"
                          disabled={isLoading}
                        >
                          {t("SAVE")}
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </FormProvider>
          <FormProvider {...methods}>
            {selectedVariantEdit.type !== "KIT" &&
              supplierProduct?.warehouses?.length > 0 && (
                <>
                  <div className="col-12 noPadding">
                    <ProductStockDetail
                      suppliers={selectedVariantEdit?.suppliers || []}
                      isSupplierBrand={isSupplierBrand}
                      isEdit={enableEditing}
                    />
                  </div>
                </>
              )}
          </FormProvider>
          {!enableEditing && (
            <div>
              <div className="col-12 noPadding">
                <DeliveryTimes
                  deliveryTimes={supplierProduct?.deliveryTimes || []}
                />
              </div>
              {integrations?.length > 0 && CURRENT_COUNTRY === "co" && (
                <div className="col-12 noPadding">
                  <ProductDetailsIntegrations integrations={integrations} />
                </div>
              )}

              {selectedVariantEdit.type !== "KIT" && (
                <div className="col-12 noPadding">
                  <h6 className="bold">{t("MOVEMENTS")}</h6>
                  <VariantProcurementList />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
