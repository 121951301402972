import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/suppliers/product-options";

export const getSupplierProductOptions = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<any>>(BASE_PATH, {
      params,
    })
    .then(res => res.data);
};

export const getSupplierProductOptionById = async (id: string) => {
  return getInstance()
    .get<any>(`${BASE_PATH}/${id}`)
    .then(res => res.data);
};

export const deleteSupplierProductOption = async (id: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${id}`)
    .then(res => res.data);
};

export const createSupplierProductOption = async (data: any) => {
  return getInstance()
    .post<any>(BASE_PATH, data)
    .then(res => res.data);
};

export const updateSupplierProductOption = async (id: string, data: any) => {
  return getInstance()
    .put(`${BASE_PATH}/${id}`, data)
    .then(res => res.data);
};

export const createProductCurve = async (
  productOptionId: string,
  data: any
) => {
  return getInstance()
    .post<any>(`${BASE_PATH}/${productOptionId}/curve`, data)
    .then(res => res.data);
};

export const updateProductCurve = async (
  productOptionId: string,
  curveId: string,
  data: any
) => {
  return getInstance()
    .put<any>(`${BASE_PATH}/${productOptionId}/curve/${curveId}`, data)
    .then(res => res.data);
};

export const deleteProductCurve = async (
  productOptionId: string,
  curveId: string
) => {
  return getInstance()
    .delete(`${BASE_PATH}/${productOptionId}/curve/${curveId}`)
    .then(res => res.data);
};
