import { getInstance } from "../../core/axios-gateway";
import { TSellerBillingAddress } from "../../types/seller";

const BASE_PATH = "/api/clients";

export const getSellerBillingAddresses = async (clientId: string) => {
  return getInstance()
    .get<TSellerBillingAddress[]>(`${BASE_PATH}/${clientId}/billing-address`)
    .then(res => res.data);
};

export const getSellerBillingAddressById = async (
  clientId: string,
  id: string
) => {
  return getInstance()
    .get<TSellerBillingAddress>(
      `${BASE_PATH}/${clientId}/billing-address/${id}`
    )
    .then(res => res.data);
};

export const updateBillingAddress = async (
  sellerId: string,
  addressId: string,
  data: Partial<TSellerBillingAddress>
) => {
  return getInstance()
    .put<TSellerBillingAddress>(
      `${BASE_PATH}/${sellerId}/billing-address/${addressId}`,
      data
    )
    .then(res => res.data);
};

export const createSellerBillingAddress = async (
  clientId: string,
  data: Partial<TSellerBillingAddress>
) => {
  return getInstance()
    .post<TSellerBillingAddress>(
      `${BASE_PATH}/${clientId}/billing-address`,
      data
    )
    .then(res => res.data);
};

export const deleteSellerBillingAddress = async (
  clientId: string,
  id: string
) => {
  return getInstance().delete(`${BASE_PATH}/${clientId}/billing-address/${id}`);
};
