import { Checkbox } from "@/components/shared/Input/Checkbox";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError } from "@/hooks/useToast";
import {
  getProductById,
  getSellerById,
  getSupplierProductOptionById,
  updatePurchaseProducts,
} from "@/http";
import { usePurchase } from "@/store/purchase";
import { formatCurrency } from "@/utils/currency";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import styles from "./styles.module.scss";

export const SaleCurveEdition = ({
  curves,
  unitPrice,
  productId,
}: {
  curves: any[];
  unitPrice: number;
  productId: string;
}) => {
  const [t] = useTranslation("global");
  const { purchase, getPurchase } = usePurchase();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { data: product } = useSWR(
    [productId, null, "getProductbyId"],
    getProductById
  );
  const optionId = get(product, "optionId", "");

  const { data } = useSWR(
    optionId ? [optionId, `product-option-${optionId}`] : null,
    getSupplierProductOptionById
  );

  const { data: seller } = useSWR(
    purchase.sellerId ? [purchase.sellerId, "seller-by-id"] : null,
    getSellerById
  );

  const getDefaultValues = () => {
    return (data?.curves || [])
      .filter((c: any) =>
        !seller || !(seller?.segments || []).length
          ? !c.segmentId
          : !!(seller?.segments || []).find((s: any) =>
              !c.segmentId ? true : s?.id === c.segmentId
            )
      )
      .map((curve: any) => {
        const productCurve = curves.find(c => c.id === curve.id);

        return {
          id: curve?.id ?? crypto.randomUUID(),
          selected: !!productCurve,
          quantity: productCurve?.quantity || 0,
          segmentId: curve?.segmentId ?? "",
          name: curve?.name,
          products: (data?.options || []).map((option: any) => ({
            optionValue: option,
            quantity:
              (productCurve ? productCurve.products : curve.products).find(
                (p: any) => p.optionValue === option
              )?.quantity || 0,
          })),
        };
      });
  };

  const methods = useForm<any>({ mode: "onChange" });
  const curvesWatch = methods.watch("curves");

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);

      const productsMapped = (purchase.products || []).map(p => {
        if (p?.id !== productId) {
          return p;
        } else {
          return {
            ...p,
            curves: values?.curves?.filter?.((c: any) => !!c?.selected),
          };
        }
      });

      await updatePurchaseProducts(purchase.id, {
        products: productsMapped,
        recalculate: true,
      });

      await getPurchase(purchase.id);
      onClose();
    } catch (e) {
      showToastError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      methods.reset({ curves: getDefaultValues() });
    }
  }, [isOpen]);

  return (
    <>
      <button className="primary" onClick={() => setOpen(true)}>
        {t("EDIT_PRODUCT_CURVES")}
      </button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        title={t("EDIT_PRODUCT_CURVES")}
        size="xl"
        footer={
          <>
            <button disabled={isLoading} onClick={onClose}>
              {t("CANCEL")}
            </button>
            <button
              disabled={isLoading}
              className="primary lg"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("SAVE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("NAME")}</th>
                {(data?.options || []).map((option: string, i: number) => (
                  <th key={i}>{option}</th>
                ))}
                <th>{t("UNITS")}</th>
                <th>{t("QUANTITY")}</th>
                <th>{t("TOTAL")}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {(curvesWatch || []).map((curve: any, curveIndex: number) => {
                const totalQuantity = (curve?.products || []).reduce(
                  (sum: number, p: any) => sum + Number(p?.quantity),
                  0
                );
                const quantity = methods.watch(
                  `curves[${curveIndex}].quantity`
                );

                const selected = methods.watch(
                  `curves[${curveIndex}].selected`
                );

                return (
                  <tr key={curve.id} className={styles.row}>
                    <td>
                      <Checkbox
                        name={`curves[${curveIndex}].selected`}
                        label={
                          <span className="textLine">
                            {methods.watch(`curves[${curveIndex}].name`)}
                          </span>
                        }
                      />
                    </td>
                    {(data?.options || []).map(
                      (option: any, optionIndex: number) => (
                        <td key={option} className={styles.number}>
                          <NumericInput
                            name={`curves[${curveIndex}].products[${optionIndex}].quantity`}
                            hideControls
                            min={0}
                            label=""
                            disabled
                            required={selected}
                          />
                        </td>
                      )
                    )}
                    <td>{totalQuantity}</td>
                    <td className={styles.number}>
                      <NumericInput
                        name={`curves[${curveIndex}].quantity`}
                        hideControls
                        min={selected ? 1 : undefined}
                        label=""
                        required={selected}
                      />
                    </td>
                    <td className={styles.name}>
                      {formatCurrency(totalQuantity * unitPrice * quantity)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FormProvider>
      </Modal>
    </>
  );
};
