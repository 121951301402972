import { OrderDetailsPanel } from "@/components/order-details/InfoPanel/OrderDetailsPanel";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useOrderDetails } from "@/store/order-detail";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import OrderDetailActions from "@/components/order-details/OrderDetailActions/OrderDetailActions";
import { CommentsSubPurchase } from "@/components/order-details/CommentsSubPurchase/CommentsSubPurchase";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import ProductsDetailsV2 from "@/components/order-details/ProductsDetailsV2/ProductsDetailsV2";
import { FormProvider, useForm } from "react-hook-form";
import { usePurchase } from "@/store/purchase";

const PageOrderDetail = () => {
  const methods = useForm({});
  const { id = "" } = useParams();
  const { setTitle } = useHeaderTitle();
  const { user } = useAuth();
  const [t] = useTranslation("orders-page");
  const { getOrderDetail, orderDetail, isLoading } = useOrderDetails();
  const isOwnOrder = user.id === orderDetail?.supplierId;
  const { getPurchase, purchase } = usePurchase();

  if (orderDetail?.purchaseId) {
    getPurchase(orderDetail?.purchaseId);
  }

  const getOrderDetailHook = async () => await getOrderDetail(id, user.id);

  useEffect(() => {
    getOrderDetailHook();
  }, [id]);
  useEffect(
    () =>
      setTitle(
        `${t("ORDERS.SINGLE_ORDER")} » ${
          orderDetail?.subPurchaseNumber || "-"
        }`,
        "/orders",
        ""
      ),
    [t, orderDetail]
  );

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      {!orderDetail ? (
        <h6>{t("NO_ORDER_DETAIL")}</h6>
      ) : (
        <>
          {isOwnOrder && <OrderDetailsPanel orderDetail={orderDetail} />}
          <OrderDetailActions orderDetail={orderDetail} userId={user.id} />
          <FormProvider {...methods}>
            {purchase && (
              <ProductsDetailsV2
                orderType={"QUOTATION"}
                isCreation={false}
                lightStyle
                products={purchase?.products
                  ?.filter(p => p.subPurchaseId === orderDetail.id)
                  .map((p, idx) => ({ ...p, idx }))}
              />
            )}
          </FormProvider>
          {isOwnOrder && <CommentsSubPurchase orderDetail={orderDetail} />}
        </>
      )}
    </>
  );
};

export default PageOrderDetail;
