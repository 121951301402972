import { getSupplierSplits } from "@/http";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Card } from "../shared/Card/Card";
import styles from "./styles.module.scss";
import format from "date-fns/format";
import { isUSA } from "@/constants/core";
import { DATE_MONT_YEAR, USA_DATE_MONT_YEAR } from "@/constants/date";
import { SplitModal } from "./SplitModal";
import { DeleteSplit } from "./DeleteSplit";

export const SplitsList = () => {
  const [t] = useTranslation("global");

  const swrData = useSWR(["supplier-splits"], getSupplierSplits);
  const { data: options, mutate } = swrData;

  return (
    <Card>
      <div className="rigthAlign">
        <SplitModal onDone={() => mutate()} />
      </div>
      <div className="spacer" />
      {(options || [])?.length <= 0 ? (
        <p className="textCenter">{t("NO_DATA")}</p>
      ) : (
        <div className="scrollTable">
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("SPLIT_ALIAS")}</th>
                <th>{t("SPLIT_DATE")}</th>
                <th>{t("SPLIT_PERCENTAGE")}</th>
                <th>{t("ACTIONS")}</th>
              </tr>
            </thead>
            <tbody>
              {options?.map((o: any) => (
                <tr key={o?.id} className={styles.row}>
                  <td className="textLine">{o?.alias ?? "-"}</td>
                  <td>
                    {o?.date
                      ? format(
                          new Date(o.date),
                          isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
                        )
                      : "-"}
                  </td>
                  <td>
                    {o?.percentage ?? "0"}
                    {" %"}
                  </td>
                  <td>
                    <div className="flexGap centerAlign">
                      <SplitModal onDone={() => mutate()} split={o} />
                      <DeleteSplit onDone={() => mutate()} split={o} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Card>
  );
};
