import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { quotesRenderFilter } from "@/constants/purchases";
import { PurchaseListTable } from "./PurchaseListTable/PurchaseListTable";
import { PurchaseListFilters } from "./PurchaseListTable/PurchaseListFilters";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { get } from "lodash-es";
import { usePurchases } from "@/store/purchases";
import useSWR from "swr";
import { getPurchaseListFilters } from "@/http";

type props = {
  type: "SELL_IN" | "SELL_OUT" | "QUOTATION";
};

export const PurchaseList = ({ type }: props) => {
  const [t] = useTranslation("orders-page");
  const [search, setSearch] = useSearchParams();
  const statusFilters = JSON.parse(search.get("filterStatus") || "{}");
  const { clearSelectedPurchases } = usePurchases();
  const { data: filters = {} } = useSWR(
    [type, "purchaseListFilters"],
    getPurchaseListFilters
  );
  const statusFilter = filters["filterStatus"] ?? [];

  const setStatusFilters = (key: string, value: string) => {
    const finalValue = get(statusFilters, key, "") === value ? "" : value;
    const payload = {
      [key]: finalValue,
    };

    const newSearch = new URLSearchParams(search);

    newSearch.set("filterStatus", JSON.stringify(payload));
    newSearch.set("page", "0");
    setSearch(newSearch);

    clearSelectedPurchases();
  };

  return (
    <>
      <FilterTabs
        options={
          type === "QUOTATION"
            ? quotesRenderFilter
            : Object.keys(statusFilter || {})
                .filter((s: string) => s !== "QUOTED")
                .map((status: string) => ({
                  text: statusFilter[status],
                  value: status,
                }))
        }
        currentFilter={get(statusFilters, "filterStatus", "")}
        action={(value: string) => setStatusFilters("filterStatus", value)}
      />
      <PurchaseListFilters type={type} />
      <div className="spacer" />
      <PurchaseListTable type={type} />
    </>
  );
};
