import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { TextArea } from "@/components/shared/Input/TextArea";
import { isUSA } from "@/constants/core";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BrandInput } from "../ProductsForm/BrandInput";
import { CategoryTree } from "../ProductsForm/CategoryTree";
import { BrandCreation } from "./BrandCreation";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { hasPermission } from "@/utils/validations/permissions";
import { useAuth } from "@/store/auth";
import { DisabledZone } from "@/components/shared/DisabledZone/DisabledZone";
import { SingleSelectionProductOption } from "@/components/shared/Input/SingleSelectionProductOption";

export const NameInputs = ({ isEdit = false }: { isEdit?: boolean }) => {
  const [t] = useTranslation("products-page");
  const [tGlobal] = useTranslation("global");
  const { setValue, watch } = useFormContext();
  const { isCommercial, role, user } = useAuth();
  const isGroup = watch("type") === "GROUP";
  const showCurves = !!user?.showCurves;

  return (
    <>
      <p>{t("BASIC_INFO_DESCRIPTION")}</p>
      <div className="spacer" />
      <DisabledZone when={isEdit}>
        <RadioGroupV2
          color="purple"
          required
          inline
          name="type"
          label={t("PRODUCT.TYPE")}
          labelBold
          options={[
            { label: t("PRODUCT.PRODUCT"), value: "PRODUCT" },
            { label: t("PRODUCT.KIT"), value: "KIT" },
            { label: tGlobal("GROUP"), value: "GROUP" },
          ].filter(l => l.value !== "GROUP" || showCurves)}
          disabled={isEdit}
        />
      </DisabledZone>
      <div className="spacer" />

      <InputV2
        name="name"
        label={<span className="bold">{t("PRODUTC_NAME_LABEL")}</span>}
        required
        min={10}
      />

      <BrandInput
        label={
          <div className="contentInline">
            <span className="bold">{t("PRODUCT_BRAND")}</span>
            <span className="purpleText">
              <BrandCreation setValue={setValue} />
            </span>
          </div>
        }
        placeholder={""}
      />

      <CategoryTree />

      {isGroup && !isEdit && (
        <SingleSelectionProductOption
          name="optionId"
          required
          label={<p className="bold">{tGlobal("PROUCT_OPTION")}</p>}
        />
      )}

      <TextArea
        name="description"
        label={<span className="bold">{t("PRODUTC_DESCRIPTION_LABEL")}</span>}
        rows={6}
      />

      <Checkbox name="isMarketplace" label={t("PRODUCT.IS_MARKETPLACE")} />

      <Checkbox name="featured" label={t("PRODUCT.FEATURED")} />

      {isUSA &&
        hasPermission(isCommercial, role, ["action.clients.credit-memo"]) && (
          <Checkbox
            name="canCreditMemo"
            label={tGlobal("PRODUCT_CAN_CREDIT_MEMO")}
          />
        )}
    </>
  );
};
