import { Modal } from "@/components/shared/Modal/Modal";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { isUSA } from "@/constants/core";
import { DATE_HOUR_NO_SPACES, USA_DATE_HOUR_NO_SPACES } from "@/constants/date";
import { showToastError } from "@/hooks/useToast";
import { getQuotationExport } from "@/http";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";

type props = {
  type: "SELL_IN" | "SELL_OUT" | "QUOTATION";
};
export const BatchExport = ({ type }: props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("purchases");
  const [search] = useSearchParams(
    "page=0&sortBy=createdAt&sortDirection=DESC"
  );
  const startDate = search.get("startDate");
  const endDate = search.get("endDate");

  const onSubmit = async () => {
    setIsOpen(true);

    const datesFilter =
      !!startDate && !!endDate
        ? `createdAt>${startDate} 00:00:00,createdAt<${endDate} 23:59:59`
        : "";
    const search = [datesFilter, `type:${type}`].filter(Boolean).join(",");

    try {
      const file = await getQuotationExport(search);
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      const name = format(
        new Date(),
        isUSA ? USA_DATE_HOUR_NO_SPACES : DATE_HOUR_NO_SPACES
      );
      link.download = "quotes_export_" + name + ".xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <>
      <div className="row rigthAlign">
        <button className="outlined bold" onClick={() => onSubmit()}>
          {t("EXPORT_QUOTES")}
        </button>
      </div>
      {createPortal(
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          size="sm"
          showOverflow
          title={t("EXPORT_QUOTES")}
        >
          <div className="centerAlign">
            <Spinner size="large" />
          </div>
        </Modal>,
        document.body
      )}
    </>
  );
};
