import {
  addProductToPurchase,
  deleteProductToPurchase,
  getProductById,
  getSupplierProductOptionById,
  QUOTED,
  TProduct,
  TPurchase,
  updateProductToPurchase,
} from "@/http";
import { useTranslation } from "react-i18next";
import { Pricing } from "../PurchaseCreateForm/Prepurchase/Pricing";
import { PurchaseStatusInfo } from "./PurchaseGeneralInfo/PurchaseStatusInfo";
import styles from "./PurchaseShow.module.scss";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { format } from "date-fns";
import { isUSA } from "@/constants/core";
import { PurchaseActions } from "./Actions/PurchaseActions";
import { LastPurchasesUser } from "@/components/order-details/LastPurchasesUser/LastPurchasesUser";
import { validateTimezoneDate } from "@/utils/date";
import {
  buildCountryLine,
  buildLineAddress,
} from "@/components/invoices/utils";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { ShowTimeline } from "./Timeline/ShowTimeline";
import { PurchaseComments } from "./Comments/PurchaseComments";
import { useAuth } from "@/store/auth";
import { PurchaseObservations } from "./Comments/PurchaseObservations";
import { PaymentsList } from "./Payments/PaymentsList";
import { Card } from "@/components/shared/Card/Card";
import { CreditMemoModal } from "@/components/credit-memo/CreditMemoModal";
import { hasPermission } from "@/utils/validations/permissions";
import { SupplierResume } from "@/components/shared/Suppliers/SupplierResume";
import { getAccountingIntegration } from "@/hooks/useIntegrations";
import ProductsDetailsV2 from "@/components/order-details/ProductsDetailsV2/ProductsDetailsV2";
import { FormProvider, useForm } from "react-hook-form";
import { SubPurchasesV2 } from "./SubPurchasesV2/SubPurchasesV2";
import { usePurchase } from "@/store/purchase";
import { mapProductToOrderProduct } from "@/utils/products";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { get } from "lodash-es";
import { ProductSearch } from "@/components/shared/ProductSearch/ProductSearch";
import { DeleteOrderProduct } from "./Actions/DeleteOrderProduct";

export type TPurchaseShow = {
  purchase: TPurchase;
};
export const PurchaseShow = ({ purchase }: TPurchaseShow) => {
  const methods = useForm({});
  const { getPurchase } = usePurchase();
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const { user, isCommercial, role } = useAuth();
  const [addProduct, setAddProduct] = useState<boolean>(false);
  const [products, setProducts] = useState<TProduct[]>([]);
  useEffect(() => {
    if (purchase?.products) {
      setProducts(
        purchase.products.map((product, idx) => ({
          ...product,
          idx,
        }))
      );
    }
  }, [purchase]);
  const accountingIntegration = getAccountingIntegration();

  const handleProductSelection = async (product: TProduct) => {
    if (
      product?.type === "GROUP" &&
      purchase.type === "QUOTATION" &&
      product?.optionId
    ) {
      const options = await getSupplierProductOptionById(product.optionId);
      product.curves = (options?.curves || []).map((c: any) => ({
        ...c,
        quantity: 0,
        selected: false,
      }));
      product.curveOptions = options?.options ?? [];
    }

    product.unitRate =
      (get(product, "unitTypes", []) as any[])?.find(
        (unit: any) => unit?.isDefault
      )?.rate ?? 1;

    product.wholesalePrice = Number(
      (product?.unitRate || 1) * product.wholesalePrice
    );
    product.quantity = 1;
    product.discountPercentage = 0;
    product.idx = products?.length || 0;
    setProducts([...(products || []), product]);
    methods.setValue("editProduct", product);
    setAddProduct(false);
  };

  const onRollbackChanges = (product: TProduct) => {
    product.orderProductId
      ? methods.setValue("editProduct", null)
      : setProducts(products.filter((p: TProduct) => p.idx !== product.idx));
  };

  const onSuccessDelete = () => {
    methods.setValue("deleteProduct", null);
    methods.setValue("editProduct", null);
    setTimeout(() => {
      getPurchase(purchase.id);
    }, 600);
  };

  const deleteOrderProduct = (product: TProduct) => {
    if (product.orderProductId) {
      methods.setValue("deleteProduct", product);
    } else {
      setProducts(products.filter((p: TProduct) => p.idx !== product.idx));
    }
  };

  const saveOrderProduct = (product: TProduct) => {
    const callback = product.orderProductId
      ? updateProductToPurchase(
          purchase.id,
          product.orderProductId,
          mapProductToOrderProduct(product)
        )
      : addProductToPurchase(purchase.id, [mapProductToOrderProduct(product)]);

    callback.then(() => {
      setTimeout(() => {
        getPurchase(purchase.id);
      }, 600);
      methods.setValue("editProduct", null);
    });
  };

  const editOrderProduct = async (purchaseProduct: TProduct) => {
    await getProductById(purchaseProduct.id).then(product => {
      if (purchaseProduct.subTotalWithDiscount !== purchaseProduct.subtotal) {
        purchaseProduct.discountPercentage =
          Math.round(
            10000 *
              (1 -
                purchaseProduct.subTotalWithDiscount / purchaseProduct.subtotal)
          ) / 100;
      }

      methods.setValue("editProduct", {
        ...purchaseProduct,
        warehouses: product.availableWarehouses,
        availableWarehouses: product.availableWarehouses,
        unitTypes: product.unitTypes,
      });
    });
  };

  const isSale =
    !(purchase?.sellerId === user.id) && purchase.status !== QUOTED;

  const isProcurement = purchase?.sellerId === user.id;
  const isPending = purchase.status === "PENDING";

  const invoiceAllowed =
    accountingIntegration?.supplier == "SIIGO" ? !purchase.hasInvoice : true;

  const pendingPermission = hasPermission(isCommercial, role, [
    "action.purchase.products-update",
  ]);

  const anyStatePermission = hasPermission(isCommercial, role, [
    "action.purchase.update-any-state",
  ]);

  const isOrderPurchaseDelivered =
    purchase.type === "SELL_IN" && purchase.deliveryStatus === "DELIVERED";
  const allowEdit =
    !isOrderPurchaseDelivered &&
    invoiceAllowed &&
    (isPending ? pendingPermission || anyStatePermission : anyStatePermission);
  return (
    <>
      <Card className="noPadding">
        <div className={`${styles.titleWrapper} row`}>
          <div className="col-5 col-sm-12 noPadding">
            <h6 className="bold">{t("GENERAL_INFO_TITLE")}</h6>
          </div>
          <div
            className={`${styles.infoTitle} flexSmColumn col-7 col-sm-12 noPadding`}
          >
            <div>
              <div className="flexGap gapSm center">
                <ShowTimeline purchaseId={purchase.id} />
                <div>
                  {purchase?.quotedAt && (
                    <div>
                      <span className="bold textLine">
                        {t("QUOTED_AT")}
                        {": "}
                      </span>
                      <span className="textLine">
                        {format(
                          new Date(validateTimezoneDate(purchase?.quotedAt)),
                          isUSA ? USA_DATE_HOUR : DATE_HOUR
                        )}
                      </span>
                    </div>
                  )}
                  {purchase?.quotedAt !== purchase?.createdAt && (
                    <div>
                      <span className="bold textLine">
                        {t("CREATED_AT")}
                        {": "}
                      </span>
                      <span className="textLine">
                        {purchase?.createdAt
                          ? format(
                              new Date(
                                validateTimezoneDate(purchase?.createdAt)
                              ),
                              isUSA ? USA_DATE_HOUR : DATE_HOUR
                            )
                          : t("NO_AVAILABLE")}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {purchase.status === QUOTED && (
                <div className="flexGap">
                  <span className="bold textLine">{t("EXPIRES_AT")}:</span>
                  {purchase?.expiredAt
                    ? format(
                        new Date(validateTimezoneDate(purchase?.expiredAt)),
                        isUSA ? USA_DATE_HOUR : DATE_HOUR
                      )
                    : t("NO_AVAILABLE")}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row pw1">
          <PurchaseActions purchase={purchase} />
        </div>

        <div className="spacer" />

        <div className="row">
          <div
            className={`${styles.generalContainer} col-4 col-sm-12 noPadding`}
          >
            {isProcurement ? (
              <>
                <span className={styles.title}>{t("SUPPLIER")}</span>
                <div className="spacer" />
                <SupplierResume supplierId={purchase.supplierId} />
              </>
            ) : (
              <>
                <span className={styles.title}>{t("CLIENT")}</span>
                <div className="spacer" />

                <p className="bold">
                  {t(isUSA ? "LEGAL_NAME" : "CLIENT_NAME")}
                </p>
                <div className="pointer">
                  <SellerResume seller={purchase?.client} hideEdit />
                  <div>
                    {purchase.client.phonePrefix + " " + purchase.client.phone}
                  </div>
                  {isUSA &&
                    isSale &&
                    hasPermission(isCommercial, role, [
                      "action.clients.credit-memo",
                    ]) && (
                      <CreditMemoModal
                        actionElement={
                          <span className="purpleText bold textUnderline">
                            {tGlobal("CREATE_CREDIT_MEMO")}
                          </span>
                        }
                        seller={purchase.client}
                      />
                    )}
                </div>
                <div className="spacer" />
                <p className="bold">{t("ADDRESS_DELIVERY")}</p>
                <p>{buildLineAddress(purchase?.client?.location)}</p>
                <p>{buildCountryLine(purchase?.client?.location)}</p>
              </>
            )}
            <div className="spacer" />
          </div>

          <div
            className={`${styles.generalContainer} col-4 col-sm-6 noPadding`}
          >
            <span className={styles.title}>{t("STATES")}</span>
            <div className="spacer" />
            <PurchaseStatusInfo purchase={purchase} />
            {purchase.commercialName && (
              <>
                <span className="bold">{t("SELLER")}: </span>
                <span>{purchase.commercialName}</span>
              </>
            )}
            <div className="spacer" />
          </div>
          <div
            className={`${styles.generalContainer} col-4 col-sm-12 noPadding`}
          >
            <span className={styles.title}>{t("PAYMENTS")}</span>
            {purchase && <PaymentsList purchase={purchase} />}
          </div>
        </div>

        <div className="row col-12 flexGap flexSmColumn">
          <div className="col-4 col-sm-12 noPadding">
            <LastPurchasesUser clientId={purchase?.client?.id} font="sm" />
          </div>
          <div className="col-4 col-sm-12 noPadding">
            <PurchaseComments />
          </div>
          <div className="col-4 col-sm-12 noPadding">
            <PurchaseObservations />
          </div>
        </div>

        <div className="row col-12">
          <div className={styles.section}>
            <FormProvider {...methods}>
              {allowEdit && (
                <div>
                  {!addProduct && !methods.watch("editProduct") && (
                    <div className="row rigthAlign">
                      <button
                        type="button"
                        onClick={() => setAddProduct(true)}
                        className={clsx("primary", styles.modalTriggerButton)}
                      >
                        {t("ADD_PRODUCT")}
                      </button>
                    </div>
                  )}
                  {addProduct && !methods.watch("editProduct") && (
                    <>
                      <ProductSearch
                        filterConditions={{
                          segment: get(
                            methods.watch("seller"),
                            "segments[0].name",
                            ""
                          ),
                          supplierId: user.id,
                          hasStock:
                            purchase.type != "QUOTATION" ? true : undefined,
                          status:
                            purchase.type != "QUOTATION" ? true : undefined,
                        }}
                        onSelect={handleProductSelection}
                        infoLabel={
                          methods.watch("products")?.length >= 50 && (
                            <p className="purpleText bold mdFont pw2">
                              * {tGlobal("INFO_MAX_PRODUCTS_ADDED")}
                            </p>
                          )
                        }
                      />
                    </>
                  )}
                </div>
              )}
              <div className="divider"></div>
              <ProductsDetailsV2
                allowEdit={allowEdit}
                orderType={purchase.type}
                currentProduct={methods.watch("editProduct")}
                isCreation={false}
                lightStyle
                products={products.filter(product => !product.subPurchaseId)}
                onEditProduct={(product: TProduct) => editOrderProduct(product)}
                onDeleteProduct={(product: TProduct) =>
                  deleteOrderProduct(product)
                }
                onSaveEdition={(product: TProduct) => saveOrderProduct(product)}
                onRollbackChanges={(product: TProduct) =>
                  onRollbackChanges(product)
                }
              />
              {purchase.subPurchases.map((subpurchase: any, idx: number) => (
                <>
                  <SubPurchasesV2
                    key={subpurchase.id}
                    subpurchase={subpurchase}
                  >
                    <ProductsDetailsV2
                      allowEdit={allowEdit}
                      orderType={purchase.type}
                      currentProduct={methods.watch("editProduct")}
                      isCreation={false}
                      lightStyle
                      products={products.filter(
                        product => product.subPurchaseId === subpurchase.id
                      )}
                      onEditProduct={(product: TProduct) =>
                        editOrderProduct(product)
                      }
                      onDeleteProduct={(product: TProduct) =>
                        deleteOrderProduct(product)
                      }
                      onSaveEdition={(product: TProduct) =>
                        saveOrderProduct(product)
                      }
                      onRollbackChanges={(product: TProduct) =>
                        onRollbackChanges(product)
                      }
                    />
                  </SubPurchasesV2>
                </>
              ))}
              {methods.watch("deleteProduct") && (
                <DeleteOrderProduct
                  onSuccess={onSuccessDelete}
                  orderId={purchase.id}
                  product={methods.watch("deleteProduct")}
                />
              )}
            </FormProvider>
          </div>
        </div>
        <div className="row col-12 noPadding">
          <div className={styles.pricingContent}>
            <div className="col-7 col-sm-12 noPadding"></div>
            <div className="col-5 col-sm-12 noPadding">
              <div className={styles.generalContainer}>
                <span className={styles.title}>{t("PURCHASE_INFO_TITLE")}</span>
                <div className="spacer" />
                <Pricing prepurchase={purchase as any} ligthStyle />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
