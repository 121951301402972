import { useOrderDetails } from "@/store/order-detail";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { UpdateProviderDeliveryStatus } from "./UpdateProviderDeliveryStatus";

export const DeliveryStatusDetail = () => {
  const { orderDetail } = useOrderDetails();
  const { providerDeliveryStatusName = "-" } = orderDetail;

  const [t] = useTranslation("orders-page");

  return (
    <>
      <div className={styles.item}>
        <span className={styles.title}>{t("DELIVERY_STATUS")}</span>
        <span
          className={clsx(styles.description, "flexGap", {
            [styles.red]: [
              "supplier_pending",
              "pending",
              "rejected",
              "cancelled",
              "failed_to_send",
              "supplier_rejected",
            ].includes(orderDetail?.deliveryStatus?.toLowerCase() || ""),
            [styles.green]: ["approved", "delivered", "in_process"].includes(
              orderDetail?.deliveryStatus?.toLowerCase() || ""
            ),
            [styles.purple]: [
              "in_transit",
              "preparing",
              "preparation",
              "delivered_to_supplier",
              "pickup_from_supplier",
            ].includes(orderDetail?.deliveryStatus?.toLowerCase() || ""),
          })}
        >
          <span>{providerDeliveryStatusName}</span>
          {orderDetail?.deliveryStatus !== "DELIVERED" && (
            <UpdateProviderDeliveryStatus />
          )}
        </span>
      </div>
    </>
  );
};
